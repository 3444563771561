import { Component, OnInit ,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentListingService } from './../../../services/shipment-listing.service';
@Component({
  selector: 'app-remove-favorite',
  templateUrl: './remove-favorite.component.html',
  styleUrls: ['./remove-favorite.component.css']
})
export class RemoveFavoriteComponent implements OnInit {
  @Input() public orderId;

  constructor(public activeModal: NgbActiveModal,public shipmentListingService:ShipmentListingService) { }

  ngOnInit(): void {
  }
  closeModal() { this.activeModal.close(); }

  submitModal() {
    let inputData = {   
      "orderId": this.orderId,
      "isFavourite": false
  }
    this.shipmentListingService.updateItems(inputData).subscribe(result =>{
      this.activeModal.close({success : true});
    })
  }
}
