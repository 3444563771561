<div id="DownloadPdfData" class="col-12 card" style="border: none !important;">
    <div >
        <!DOCTYPE html>
        <html>
        <head>
            <style type="text/css">
                
                .blo_connaissement_left{
                    width: 43%;
                    float: left;
                }
                .blo_connaissement_right{
                    width: 100%;
                    float: right;
                    font-size: 10px;
                    font-family: Poppins;
                    text-align: right;
                }
        
                .blo_connaissement_right .blo_left{
                    width: 45%;
                    float: left;
                    text-align: left;
                    font-size: 12px;
                }
                .blo_connaissement_right .blo_right{
                    width: 55%;
                    float: left;
                    text-align: right;
                }
                .order-details_section{
                    width: 100%;
                    float: left;
                    margin: 5px 0 0 0;
                }
        
        
                .order-details{
                    width: 23%;
                    float: left;
                    font-size: 11px;
                    padding: 0px;
                    font-family: Poppins;
                }
        
                .table_start{
                    width: 100%;
                    float: left;
                    font-family: Poppins;
                }
        
                .table-heading {
                    width: 99%;
                    float: left;
                    /* padding: 0px 1px 0px 3px; */
                    font-size: 12px;
                    border: 1px solid #ccc;
                    background: #f1f1f1;
                }
                .shipper-heading{
                    width: 49%;
                    float: left;
                    border-right: 1px solid #ccc;
                }
                .consignee-heading{
                    width: 50%;
                    float: left;
                }
                .table-data{
                    width: 99%;
                    float: left;
                    border: 1px solid #ccc;
                    /* padding: 0px 1px 0px 3px; */
                    font-size: 10px;
                }
                .shipper_box{
                    width: 50%;
                    float: left;
                }
                .shipper_box div {
                    /* padding: 5px 0 1px 0; */
                }
                .consignee_box{
                    width: 50%;
                    float: left;
                }
                .consignee_box div {
                    /* padding: 5px 0 1px 0; */
                }
        
                .bill_freight_strip{
                    width: 99%;
                    float: left;
                    border: 1px solid #ccc;
                    padding: 4px 1px 4px 3px;
                    font-size: 11px;
                    text-align: center;
                }
        
                .shipping_charges_strip{
                    width: 99%;
                    float: left;
                    border: 1px solid #ccc;
                    padding: 4px 1px 4px 3px;
                    font-size: 11px;
                    text-align: center;
                    text-transform: uppercase;
                }
                .received_text_section{
                    width: 99%;
                    float: left;
                    border: 1px solid #ccc;
                    padding: 4px 1px 4px 3px;
                    font-size: 10px;
                }
        
                .pro_details_table{
                    width: 100%;
                    float: left;
                    font-family: Poppins;
                }
                .pro_details_table table th{
                    font-weight: bold;
                    font-size: 9px;
                    font-family: Poppins;
                    text-align: left;
                    float: left;
                }
        
                .pro_details_table table td{
                    font-size: 9px;
                    font-family: Poppins;
                    float: left;
                }
                .pro_details_table tbody{
                    width: 100%;
                    float: left;
                }
                .yellow-header{
                    background: #f7c765;
                    width: 100%;
                    float: left;
                }
        
        
                .shipment_size_box {
                    width: 99.5%;
                    float: left;
                    font-size: 11px;
                    font-family: Poppins;
                    border: 1px solid #ccc;
                }
                .shipment_box_1{
                    width: 33%;
                    float: left;
                    font-size: 11px;		    
                }
                .orange_heading{
                    color: #FF8D00;
                    font-size: 11px;
                    font-weight: 600;
                }
        
                .Declared_box{
                    width: 100%;
                    float: left;
                    padding: 5px 1px 5px 3px;
                    border-top: 1px solid #ccc;
                }
                .consignor_signature_box{
                    width: 100%;
                    float: left;
                    padding: 5px 1px 5px 3px;
                    border-top: 1px solid #ccc;
                }
        
                .freight_collect_shipments_box{
                    width: 98%;
                    float: left;
                    padding: 5px 7px 5px 3px;
                    border-top: 1px solid #ccc;
                    background-color: #F3F5F7;
                }
        
                .signature_line{
                    width: 29%;
                    height: 1px;
                    float: left;
                    background: #817f7f;
                    margin: 15px 0 0 0;
                }
        
                .page_heading{
                    font-size: 25px;
                    font-family: Poppins;
                }
                .second-sec h5{
                    font-size: 11px !important;
                }
                .second-sec p{
                    font-size: 9px !important;
                }
            </style>
       
        </head>
        <body>
        
        <div style="background: #fff;margin: auto;width: 100%;
        overflow: hidden;" >
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">
                   <span class="material-icons">
                   close
                   </span>
                </span>
              </button> -->

              <!-- <div class="BOLDot dropdown-toggle dropleft mb-2"  data-toggle="dropdown">
                <img class="" style="margin: 13px auto;
                text-align: center;
                display: block;"  src="assets/img/BOLDot.svg" >
            </div>
            
            <div class="dropdown-menu drop-menu" aria-labelledby="dropdownMenuButton" multiple >
                <a class="dropdown-item" (click)="print('bol')">Print</a>
                <a class="dropdown-item" (click)="download()">Save as PDF</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="closeModal()">Close</a>
            </div> -->
            <div id="contentInvoice" #contentInvoice class="bol-page_section printable viewinvoice" style="background: #fff;padding: 3px 5px;height: auto;border: 1px solid #D4D4D4; border-radius: 6px;float: left;">
                <!-- <div  class="page_heading">Bill of Loading</div>	 -->
        
                    <div>
                        <div class="blo_connaissement_section">
                            <div class="blo_connaissement_left">
                                <img style="width: 200px;padding: 0px 0 0 0;" src="assets/img/shipcarte-logo-350x130.png" alt="" />
                            </div>
                            <div class="float-right text-right">
                                <h3 class="mb-0" style="font-size: 15px !important;font-weight: 600 !important;">INVOICE / FACTURE No. {{data && data?.invoiceNumber ? data && data?.invoiceNumber : ''}} </h3> 
                                <h5 class="mb-0" style="font-size: 14px !important;font-weight: 500 !important;">{{data && formattedDate ? formattedDate : ''}} </h5>
                                <!-- <small class="orange-text">Set Reminder</small> -->
                                
                            </div> 
                            <div class="row blo_connaissement_right">   
                                <div class=" col-6 blo_left text-start">
                                    <div class="mt-3" style="font-size: 12px;font-family: poppins,sans-serif; font-weight: 600; ">BILL TO / FACTURER A:</div>
                                    <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">{{data['consignee']['firstName']}} {{data['consignee']['lastName']}}</div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">{{data['consignee']['company'] ? data['consignee']['company'] : ''}} </div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">{{data['consignee']['addrLine1'] ? data['consignee']['addrLine1'] : ''}} {{data['consignee']['addrLine2'] ? data['consignee']['addrLine2'] : ''}} </div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">{{data['consignee']['city'] ? data['consignee']['city'] : ''}} {{data['consignee']['state'] ? data['consignee']['state'] : ''}} {{data['consignee']['country'] ? data['consignee']['country'] : ''}} {{data['consignee']['zipCode'] ? data['consignee']['zipCode'] : ''}}</div>
                                </div>
                                <div class="col-6 blo_right text-end">
                                    <h5 class="mt-3 mb-0" style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">BALANCE DUE </h5>
                                    <h3 class="mb-0 orange-text d-inline" style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3>
                                    <!-- <h5 class="mb-0 mt-0" style="font-size: 12px !important;font-weight: 600 !important;">INVOICE AMOUNT :  <h3 class="mb-0 mt-0 orange-text d-inline" style="font-size: 16px !important;font-weight: 600 !important;">${{data && data?.charged !=null ?  data?.charged : 0 }} </h3></h5> -->
                               
                                </div>
                            </div>
                        </div>			
        
                        <div class="order-details_section">
                            <div class="order-details" style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                <div><b>ORDER NO. /</b></div>
                                <div><b>NUMERO DE COMMANDE</b></div>
                                <div style="color:#FF8D00;">{{data && data?.orderNumber ? data && data?.orderNumber : ''}} </div>
                            </div>
                            <div class="order-details" style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                <div><b>CUSTOMER REF NO. /</b></div>
                                <div><b>REFERENCE CLIENT NO.:</b></div>
                                <div>{{data && data?.referenceNumber ? data && data?.referenceNumber : ''}}</div>
                            </div>
                            <div class="order-details" style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                <div><b>ACCOUNT NO.</b></div>
                                <div><b>COMPTE NO.</b></div>
                                <div>{{data && data?.accountNumber ? data && data?.accountNumber : ''}} </div>
                            </div>
                            
                            <div class="order-details" style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                <div><b>TERMS /</b></div>
                                <div><b>TERMES</b></div>
                                <div>{{data && data?.terms ? data && data?.terms : ''}}</div>
                            </div>
                        </div>
        
                        <div class="table_start" >
                            <div class="table-data">
                                <div class="shipper_box mr-1" style="border-right: 1px solid #C4CDD5; width: 49%;">
                                    <div><b>SHIPPED / EXPENDI:</b></div>
                                    <div>{{data && data?.orderDate  | date: 'dd/MM/yyyy'}}</div>
                                   
                                </div>
                                <div class="consignee_box">
                                    <div><b>RECEIVED / RECU:</b></div>
                                    <div>--</div>
                                </div>
                            </div>
            
                            <div class="table_start" >
                                <div class="table-data">
                                    <div class="shipper_box mr-1" style="border-right: 1px solid #C4CDD5; width: 49%;">
                                        <div style="border-bottom: 1px solid #C4CDD5;padding: 2px 4xp 2px 4px ; background-color: #F5F6FA;"><b>SHIPPER / EXPEDITEUR:</b></div>
                                        <!-- <div>{{data && data?.orderDate ? data && data?.orderDate : ''}}</div> -->
                                        <div>{{data['shipper']['firstName']}} {{data['shipper']['lastName']}}</div>
                                        <div>{{data['shipper']['company'] ? data['shipper']['company'] : ''}} </div>
                                        <div>{{data['shipper']['addrLine1'] ? data['shipper']['addrLine1'] : ''}} {{data['shipper']['addrLine2'] ? data['shipper']['addrLine2'] : ''}} </div>
                                            <div>{{data['shipper']['city'] ? data['shipper']['city'] : ''}} {{data['shipper']['state'] ? data['shipper']['state'] : ''}} {{data['shipper']['country'] ? data['shipper']['country'] : ''}} {{data['shipper']['zipCode'] ? data['shipper']['zipCode'] : ''}}</div>
                                    </div>
                                    <div class="consignee_box">
                                        <div style="border-bottom: 1px solid #C4CDD5;padding: 2px 4xp 2px 4px ; background-color: #F5F6FA;"><b>CONSIGNEE / DESTNATAIRE:</b></div>
                                        <div>{{data['consignee']['firstName']}} {{data['consignee']['lastName']}}</div>
                                        <div>{{data['consignee']['company'] ? data['consignee']['company'] : ''}} </div>
                                        <div>{{data['consignee']['addrLine1'] ? data['consignee']['addrLine1'] : ''}} {{data['consignee']['addrLine2'] ? data['consignee']['addrLine2'] : ''}} </div>
                                        <div>{{data['consignee']['city'] ? data['consignee']['city'] : ''}} {{data['consignee']['state'] ? data['consignee']['state'] : ''}} {{data['consignee']['country'] ? data['consignee']['country'] : ''}} {{data['consignee']['zipCode'] ? data['consignee']['zipCode'] : ''}}</div>
                                    </div>
                                </div>
                                <!-- <div class="consignee_box">
                                    <div>{{data['consignee']['company'] ? data['consignee']['company'] : ''}} </div>
                                    <div>{{data['consignee']['addrLine1'] ? data['consignee']['addrLine1'] : ''}} {{data['consignee']['addrLine2'] ? data['consignee']['addrLine2'] : ''}} </div>
                                    <div>{{data['consignee']['city'] ? data['consignee']['city'] : ''}} {{data['consignee']['state'] ? data['consignee']['state'] : ''}} {{data['consignee']['country'] ? data['consignee']['country'] : ''}} {{data['consignee']['zipCode'] ? data['consignee']['zipCode'] : ''}}</div>
                                </div> -->
                                <!-- <div class="table-data">
                                    <div class="shipper_box mr-1" style="border-right: 2px solid #c2c2c2; width: 49%;">
                                        <div>{{data['shipper']['company'] ? data['shipper']['company'] : ''}} </div>
                                        <div>{{data['shipper']['addrLine1'] ? data['shipper']['addrLine1'] : ''}} {{data['shipper']['addrLine2'] ? data['shipper']['addrLine2'] : ''}} </div>
                                        <div>{{data['shipper']['city'] ? data['shipper']['city'] : ''}} {{data['shipper']['state'] ? data['shipper']['state'] : ''}} {{data['shipper']['country'] ? data['shipper']['country'] : ''}} {{data['shipper']['zipCode'] ? data['shipper']['zipCode'] : ''}}</div>
                                       
                                    </div>
                                    <div class="consignee_box">
                                        <div>{{data['consignee']['company'] ? data['consignee']['company'] : ''}} </div>
                                        <div>{{data['consignee']['addrLine1'] ? data['consignee']['addrLine1'] : ''}} {{data['consignee']['addrLine2'] ? data['consignee']['addrLine2'] : ''}} </div>
                                        <div>{{data['consignee']['city'] ? data['consignee']['city'] : ''}} {{data['consignee']['state'] ? data['consignee']['state'] : ''}} {{data['consignee']['country'] ? data['consignee']['country'] : ''}} {{data['consignee']['zipCode'] ? data['consignee']['zipCode'] : ''}}</div>
                                       
    
                                    </div>
                                </div> -->
            
                               
                               
                                       
            
                            </div>
                        </div>
        
                        <div class="pro_details_table">
                            <table style="background-color: #F5F6FA !important;">
                                <tr class="yellow-header" style="width: 100%;float: left;">
                                    <th style="width: 14%;padding-left:3px">QTY <br>QUANTITE</th>
                                    <th style="width: 40%;">DESCRIPTION <br>LA DESCRIPTION</th>
                                    <th style="width: 12%;">WEIGHT POIDS</th>
                                    <th style="width: 12%;">CLASS CLASSES</th>
                                    <th style="width: 9%;">RATE TARIF</th>
                                    <th style="width: 10%;">CHARGES FRAILS</th>
                                </tr>
                                <tr *ngFor="let key of data['items']" style="padding: 0px 0px;border-bottom: 1px solid #C4CDD5;width: 100%;float: left;"  >
                                    <td style="width: 14%;padding-left:3px">{{key['qty'] ? key['qty'] : ''}}</td>
                                    <td style="width: 40%;">
                                        {{key['description'] ? key['description'] : ''}}
                                    </td>
                                    <td style="width: 12%;"> {{key['totalWeight'] ? key['totalWeight'] * key['qty'] : ''}}  {{key['wghtUnit'] ? key['wghtUnit'] : ''}}</td>
                                    <td style="width: 12%;"> {{key['freightClass'] ? key['freightClass'] : ''}}</td>
                                    <td style="width: 9%;"> --</td>
                                    <td style="width: 10%;">
                                        --
                                    </td>
                                </tr>
                                <tr *ngFor="let key of data['items']" style="padding: 0px 0px;width: 100%;float: left;">
                                    <!-- <td style="width:100%;padding-left:3px"><b>Dimensions :</b> {{BOLd.shipmentItemslength ? BOLd.shipmentItemslength : '0'}} L x {{BOLd.shipmentItemsbreadth ? BOLd.shipmentItemsbreadth : '0'}}W x {{BOLd.shipmentItemsheight ? BOLd.shipmentItemsheight : '0' }} H</td> -->
                                    <td style="width:100%;padding-left:3px">                              
                                        <b>Dimensions :</b>{{key['length'] ? key['length'] : ''}} L x {{key['breadth'] ? key['breadth'] : ''}} W x {{key['height'] ? key['height'] : ''}} H<br><br>
                                        <b>SERVICE / UN SERVICE :</b>{{data['shippingMode'] ? data['shippingMode'] : ''}}<br>
                                        <b>SHIP VIA / TRANSPORTE PAR :</b>{{data['carrier'] ? data['carrier'] : ''}}<br>
                                        <b>PARTNER TRACKING NO. / SIUVIE NO. :</b>{{data['partnerTrackingNumber'] ? data['partnerTrackingNumber'] : ''}}<br><br>
                                        <div style="border-bottom: 1px dashed #C4CDD5;"></div><br>
                                        <b>NOTES / REMARQUES :</b><br>
                                        {{data['notes'] ? data['notes'] : ''}}
                                    </td>
                                </tr>
                               
                                </table>
                            </div>
            
                            
            
                        </div>
                        <table>
                        <div class="pro_details_table">
                                <tr style="padding: 0px 0px;width: 100%;float: left;font-size: 9px;">
                                    <td style="width:45%;padding: 5px 0px;">
                                        <!-- <b>I DECLARED ALL THE INFORMATION CONTAINED IN THE INVOICE TO BE TRUE AND CORRECT./ JE DECLARE QUE TOUTES LES INFORMATIONS CONTENUES DANS LA FACTURE SONT VERDIQUES ET CORRECTES</b> -->
                                    <br>
                                    <br>
                                    <div style="width: 100%;">
                                        <!-- <div style="float: left;width: 50%;">
                                            <div class="signature_line"></div>
                                            <br>
                                            <small>PRINT NAME</small>
                                        </div>
                                        <div style="float: right;width: 50%;">
                                            <div class="signature_line"></div>
                                            <br>
                                            <small>TITLE</small>
                                        </div> -->
                                    </div>
                                    <br><br>
                                    <div style="width: 100%;">
                                        <!-- <div style="float: left;width: 50%;">
                                            <div class="signature_line"></div>
                                            <br>
                                            <small>SIGNATURE</small>
                                        </div>
                                        <div style="float: right;width: 50%;">
                                            <div class="signature_line"></div>
                                            <br>
                                            <small>DATE</small>
                                        </div> -->
                                    </div>
                                    
                                    </td>
                                        <td style="width:9%;"></td>
                                        <td style="width:29%;
                                         float:right ;">
                                            <div style="float: left;"><b>SUB-TOTAL</b></div>
                                            <div style="float: right;">${{data && data?.charged ? data && data?.charged : 0}}</div>
                                            <br>
                                            <div style="float: left;"><b>TAX -0% HST 908578981</b></div>
                                            <div style="float: right;">${{data && data?.totalTaxes ? data && data?.totalTaxes : 0}}</div>
                                            <br><hr>
                                            <div style="float: left;">
                                                <b>TOTAL DUE<br>TOTAL DU</b>
                                            </div>
                                            <div style="float: right;">
                                                <b>
                                                    <span style="font-size: 15px;color: #FF8D00;">${{data && data?.amountDue ? data && data?.amountDue : 0}} </span> USD
                                                </b> 
                                            </div>
                                        </td>
                                </tr>
                            
                        </div>
                        <tr style="padding: 4px 0px;width: 100%;float: left;font-size: 8px;">
                            <td style="width:45%;padding: 0px 0px;">

                            <div class="mt-1 mb-5 ">
                                <div >
                                    <b>8-120 West Beaver creek Road, Richmond Hill, ON.L4B 1L2</b><br>
                                    <b>888.424.8878 EXT. 5<span style="font-size: 8px;color: #FF8D00;">  accounting@shipcarte.com</span></b><br>
                                </div>
                                    <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                        <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                                    </a>
                                    <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                        <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                                    </a>
                                    <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                        <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                                    </a>
                                    <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                        <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                                    </a>
                                    <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                        <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                                    </a>
                                </div>
                            </td>
                            <td style="width:9%;"></td>
                              <td style="width:28%;">
                                     <div>
                                        <b>
                                        <span style="font-size: 10px;color: #FF8D00;">THANK YOU / MERCI</span>
                                        </b>
                                          <img width="50px" src="assets/img/invoice/scikkids.png" >
                                     </div>
                              </td>
                          </tr>
                </table>
                        
                    </div>
                    <!-- <br> -->
                    <!-- <div style="margin-left: 43%;">
                        <button type="button" class="btn faint-grey col-3" style="box-shadow: none;text-transform: capitalize;">Close</button>
                    </div> -->
            </div>
        </body>
        </html>     
    </div>
</div>