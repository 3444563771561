import { Component, OnInit } from '@angular/core';
import {CommonService} from 'src/app/services/common.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-view-bol',
  templateUrl: './view-bol.component.html',
  styleUrls: ['./view-bol.component.css']
})
export class ViewBolComponent implements OnInit {


  // BOLd:any ={
  //   orderNumber:'',
  //   shipDate:'',
  //   customerNumber:'',
  //   shippercompany:'',
  //   shipperaddrLine1:'',
  //   shippercity:'',
  //   shipperstate:'',
  //   shipperzip:'',
  //   consigneecompany:'',
  //   consigneeaddrLine1:'',
  //   consigneecity:'',
  //   consigneestate:'',
  //   consigneezip:'',
  //   quoteNumber:'',
  //   shipmentItemsqty:'',
  //   shipmentItemslengthType:'',
  //   shipmentItemsdescription:'',
  //   shipmentItemstotalWeight:'',
  //   shipmentItemsfreightClass:'',
  //   shipmentItemsnmfc:'',
  //   shipmentItemslength:0,
  //   shipmentItemsbreadth:0,
  //   shipmentItemsheight:0,
  //   shipperAddressfirstName:'',
  //   shipperAddresslastName:'',
  //   consigneeAddressfirstName:'',
  //   consigneeAddresslastName:'',
  //   brokerName:'',
  //   Phone:'',
  //   brokerEmail:'',
  // };

  BOLd:any;
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  constructor(public common:CommonService,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.resultData=this.common.bolResultData;
    this.BOLd=this.common.bolInvoiceDetails;
    this.dynamicDimension=this.common.dimensionBOL;
    this.declaredValue=this.common.declaredBol;
    this.totalItem=this.common.totalItemBol;
  }

  closeModal() { this.activeModal.close(); }

}
