<div style="margin: 7px;">
    <div class="row" style="margin-top: 30px; ">
        <div class="col-12 text-center">
            <!-- <h5 style="margin-top: 5px !important; font-size: 18px !important; font-weight: 600 !important; font-family: poppins,sans-serif !important;">BOOK image</h5> -->
            <img style="padding-top: 10px;" src="../../../../assets/img/shipment/sheetCancel.svg" alt="">
        </div>
        
    </div>
    <div class="row">
        <div class="col-12 text-center" style="font-size: 12px; font-weight: 600; font-family: poppins,sans-serif; opacity: 0.7;">
           Confirm you want to save it to your favorites?
        </div>
    </div>
    <div class="row mb-0 mt-5" style="background-color: white;">
        <div class="col-12 text-end">
        <button style="width: fit-content !important; border: none !important; background-color: white !important;font-weight: 600; font-family: poppins,sans-serif;opacity: 0.7; " (click)="closeModal()">Cancel</button>
        <button   style="border: 1px solid #FC9929; background-color: #FC9929; margin-left: 8px; padding: 2px 15px 2px 15px;font-weight: 600; font-family: poppins,sans-serif; color: white;" (click)="saveToFav()">Yes</button>
        </div>
    </div>
</div>  
