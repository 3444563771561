import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './../../../../services/common.service';
import { AgmMap } from '@agm/core';

@Component({
  selector: 'app-calculate-distance',
  templateUrl: './calculate-distance.component.html',
  styleUrls: ['./calculate-distance.component.css'],
})
export class CalculateDistanceComponent implements OnInit {
  origin: any;
  destination: any;
  distancecal: any;
  durationcal: any;
  fromorigin: any;
  todest: any;
  latlng = [];
  calculated: boolean;
  showerror: boolean;
  showShipperWarningAdd: boolean;
  options = {
    types: [],
    componentRestrictions: { country: 'UA' },
  };
  @ViewChild('AgmMap')
  public agmMap: AgmMap
  @ViewChild('addressInput') addressInput: ElementRef;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public common: CommonService
  ) {}
  
  ngOnInit(): void {
    this.showShipperWarningAdd=false
    // this.getDistance();
    this.calculated = false;
    this.showerror=false;
    this.distancecal = '';
    this.durationcal = '';
    this.fromorigin = '';
    this.todest = '';
  }
  originChange(e) {
    this.showerror=false
    var latitude = e.geometry.location.lat();
    var longitude = e.geometry.location.lng();
    this.origin = {lat: latitude, lng: longitude};

    if (!this.latlng.length) {
      this.latlng.push([latitude, longitude]);
    } else {
      this.latlng[0] = [latitude, longitude];
    }
    console.log(this.latlng);
  }
  destinationchange(e) {
    this.showShipperWarningAdd=false
    var latitude = e.geometry.location.lat();
    var longitude = e.geometry.location.lng();
    this.destination = { lat: latitude, lng: longitude };
    if (!this.latlng.length) {
      this.latlng[0] = [];
      this.latlng[1] = [latitude, longitude];
    } else if (this.latlng.length >= 1) {
      this.latlng[1] = [latitude, longitude];
    }

    console.log(this.latlng);
    var origin1 = new google.maps.LatLng(
      this.origin['lat'],
      this.origin['lng']
    );
    // var origin2 = 'Greenwich, England';
    // var destinationA = 'Stockholm, Sweden';
    var destinationB = new google.maps.LatLng(
      this.destination['lat'],
      this.destination['lng']
    );

    var service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin1],
        destinations: [destinationB],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      this.callback.bind(this)
    );
  }
  callback(response, status) {
    var self= this;
    if (status == 'OK') {
      var origins = response.originAddresses;
      var destinations = response.destinationAddresses;
      var element  = ''
      var distance = ''
      var duration = ''
      var from     = ''
      var to       = ''
      for (var i = 0; i < origins.length; i++) {
        var results = response.rows[i].elements;
        for (var j = 0; j < results.length; j++) {
          element  = results[j];
          distance = element['distance']['text'];
          duration = element['duration']['text'];
          from     = origins[i];
          to       = destinations[j];
        }
      }
    }
    self.distancecal = distance
    self.durationcal = duration
    self.fromorigin = from
    self.todest = to
    // localStorage.setItem('CalculatedDistance', distance);
    // localStorage.setItem('CalculatedDuration', duration);
    // localStorage.setItem('fromOrigin', from);
    // localStorage.setItem('toDestination', to);
  }
  calculateDistance() {
    if(!this.distancecal && !this.origin && !this.durationcal){
      this.showerror=true
    }
    if(!this.distancecal && !this.destination && !this.durationcal){
      this.showShipperWarningAdd=true
    }
    if(this.origin && this.destination){
      // this.distancecal = localStorage.getItem('CalculatedDistance');
      // this.durationcal = localStorage.getItem('CalculatedDuration');
      // this.fromorigin = localStorage.getItem('fromOrigin');
      // this.todest = localStorage.getItem('toDestination');
      this.calculated = true;
      this.agmMap.triggerResize();
    }
  }
  clearval(){
    this.origin=''
    this.destination=''
    console.log(this.origin,this.destination)
  }
  back(){
    this.calculated = false;
  }

  closeModal() {
    this.activeModal.close();
  }
}
