
    <div style="top: 30px;position: absolute;padding: 20px;">
        <h3 class="text-center">Add Shipcarte Insurance</h3>
        <img class="d-block m-auto" src="assets/img/Currier-Selection/Insurance_icon.svg"  width="20%">
        <p>Unless the consignor has declared a value of the goods on the face of the Bill of Loading, the amount 
            of any loss or damage for which the carrier is liable, whether or not such loss or damage 
            results from negligence, shall be the lesser of:</p>
            <ol style="padding: 10px;">
                <li>The value of the goods at the place and time of shipment, including freight and other charges if paid, and</li>
                <li>$4.41 per kilogram computed on the total weight of the shipment</li>
            </ol>
        <div>  
            <input style="pointer-events: fill;" type="checkbox" id="checkbox" />
			<label for="checkbox" style="    margin: 2px 10px 17px 20px;
            position: absolute;">Do not show me this again</label>
            <div class="total_count text-right font-weight-bold">$500.00</div>
        </div>  
        <br>
        <div class="text-right">
            <button type="button" class="btn faint-grey col-3" style="box-shadow: none;text-transform: capitalize;" (click)="closeModal()">No Thanks</button>
            &nbsp;&nbsp; <button type="button" class="btn orange text-white" style="box-shadow: none;text-transform: capitalize;">Add Insurance</button>
        </div>
    </div> 
    <hr>
  
