import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  displayErrorMessage = true;
  errorMessage = '';
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  closeModal() { this.activeModal.close(); }
}
