<div class="container" style="top: 15px;position: absolute;padding: 30px;">
    <span>
        <h4 style="font-size: 16px !important;">Dimensional Weight Calculator
            <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()">
                        close
                    </span>
                </span>
            </button>
        </h4>
    </span>
    <div class="row mt-3">
        <form [formGroup]="dimentionForm" (ngSubmit)="submit()">


            <div class="row mt-2">
                <div class="col">
                    <div class="form-group">
                        <label>Length&nbsp;
                            <img src="assets/img/quotes/Inches.svg" class="m-0 text-center" width="25%">
                        </label>
                        <input formControlName="Length" type="number" class="form-control">
                        <div *ngIf="dimentionForm.get('Length').invalid && (dimentionForm.get('Length').dirty || dimentionForm.get('Length').touched)"
                            [ngClass]="'error'">
                            <div *ngIf="dimentionForm.get('Length').errors.required">
                                Length is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Width&nbsp;
                            <img src="assets/img/quotes/Inches.svg" class="m-0 text-center" width="25%">

                        </label>
                        <input formControlName="Width" type="number" class="form-control">
                        <div *ngIf="dimentionForm.get('Width').invalid && (dimentionForm.get('Width').dirty || dimentionForm.get('Width').touched)"
                            [ngClass]="'error'">
                            <div *ngIf="dimentionForm.get('Width').errors.required">
                                Width is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Height&nbsp;
                            <img src="assets/img/quotes/Inches.svg" class="m-0 text-center" width="25%">
                        </label>
                        <input formControlName="Height" type="number" class="form-control">
                        <div *ngIf="dimentionForm.get('Height').invalid && (dimentionForm.get('Height').dirty || dimentionForm.get('Height').touched)"
                            [ngClass]="'error'">
                            <div *ngIf="dimentionForm.get('Height').errors.required">
                                Height is required.
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn orange text-white mt-3" style="text-transform: capitalize;"
                    type="submit" [disabled]="!dimentionForm.valid">Calculate</button>
                <h3 class="mt-3">Results</h3>
                <div class="row mb-0">
                    <div class="col">
                        Weight Results
                    </div>
                    <div class="col text-right">
                        {{ weightInLBS ? (weightInLBS | number : '1.2-2')  : '--' }} lbs
                    </div>
                    <hr>
                </div>
                <div class="text-right mt-3">
                    <button type="button" class="btn faint-grey col-4"
                        style="box-shadow: none;text-transform: capitalize;"  (click)="reset()">Reset</button>
                    &nbsp;&nbsp; <button type="submit" class="btn orange text-white col-3"
                        style="box-shadow: none;text-transform: capitalize;" (click)="closeModal()">Done</button>
                </div>
            </div>
        </form>
    </div>
</div>