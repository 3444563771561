<html>
   <head>
      <meta content="text/html; charset=UTF-8" http-equiv="content-type">
      
        </head>
   <body class="c6">
     
    <button type="button" class="close mt-3" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">
            <span class="material-icons">
                close
                </span>
        </span>
      </button>
      <h3>ShipCarte Terms and Conditions</h3>
      
      <p class="c4"><span class="c0">This website contains information that is supplied by third parties, as well as links to other sources of information. Such information is provided, or accessed, for the benefit of those utilizing our site and Shipcarte has tried to ensure that it is correct. However, Shipcarte is not able to guarantee the accuracy of such information and therefore does not accept any liability or responsibility that may arise in reliance upon it, including lost or damaged goods and any direct, indirect, incidental or other damages.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Shipcarte reserves its right to make improvements and/or changes to the information contained in the website at any time without prior notice.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Information such as your contact information, e-mail addresses, and payment information are only collected when &nbsp;voluntarily provided (online purchase of shipping, addition to an e-mail list, etc.) NONE OF THIS INFORMATION IS SHARED OUTSIDE OF THE SHIPCARTE ORGANIZATION</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">IP addresses are vigorously collected while using the Shipcarte Platform in order to reduce and prevent tampering, fraud and illicit transactions.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">USING STOLEN PERSONAL INFORMATION OR CREDIT CARDS MAY CONSTITUTE A CRIME. WE COOPERATE WITH LAW ENFORCEMENT AND ANY BANK TO ASSIST IN THE CONVICTION OF PEOPLE ATTEMPTING TO COMMIT CREDIT CARD FRAUD OR IDENTITY THEFT.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">By using this website, customer agrees to abide by all the terms and conditions relating to the specific carriers below:</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c7 c2">Federal Express Terms and Conditions</span></p>
      <p class="c1"><span class="c2 c7">Purolator Terms and Conditions</span></p>
      <p class="c1"><span class="c2">DHL Terms and Conditions</span></p>
      <p class="c4"><span class="c0">UPS Terms and Conditions</span></p>
      <p class="c4"><span class="c0">Loomis Express Terms and Conditions</span></p>
      <p class="c4"><span class="c0">Canpar Terms and Conditions</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">As per National Motor Freight Classification 100-AA, Uniform Bill of Lading Terms and Conditions, Sec 7 (c), &ldquo;If the description of articles or other information on the Bill of Lading is found to be incorrect or incomplete, the freight charges must be paid based upon the articles actually shipped.&rdquo; As a result, the accuracy of the Shipcarte quote will depend on the information provided by the customer at the time of creating the Bill of Lading. This quote will be the basis for initial invoice. All quotes are subject to change based on the actual differences to weight, piece/ pallet count, reclassification, shipping dimensions, commodity descriptions, and address changes and all other charges that occur during transportation (re-delivery, appointment requests, limited access, storage, etc.) Further invoices will follow after the initial invoice to reflect any additional charges. These charges are due in full within the 30 day term from the date of invoice.</span></p>
     
      <p class="c4"><span class="c0">Actual weights of the individual shipments will be based on the applicable carrier&#39;s formula for dimensional weights. If the dimensional weight exceeds the actual true weight, the carrier&#39;s calculation of the dimensional weight will always apply. In such cases, the client acknowledges that their credit card will be charged the difference between the actual weight and the dimensional weight once that data has been provided by the carrier in question. Shipcarte investigates all claims for refunds and a claims agent will be able to provide an update once the investigation has taken place. Refunds are processed based on the results of the claim investigation, carried out by the Carrier and not Shipcarte.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">The client agrees to only use the services provided for their own internal, personal or business use and not on behalf or benefit of any third party. &nbsp;The Client also acknowledges that they may not utilize Shipcarte individual carrier pricing if they already maintain an active account with that same carrier directly. </span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">The user represents and warrants that they are of legal age to form a binding contract. If the user is agreeing to these terms on behalf of an organization or entity, the user represents and warrants that they are authorized to agree to these terms and conditions on that organization or entity&rsquo;s behalf and bind them entirely to these terms.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">Violation of any of the above-mentioned conditions will result in an immediate termination of use and/or access to the services provided.</span></p>
      <p class="c3"><span class="c0"></span></p>
      <p class="c4"><span class="c0">By using this website and its contents, you automatically agree to its terms and conditions.</span></p>
      <button type="button" class="btn orange text-white m-auto col-3 d-block mt-3" #closeBtn data-dismiss="modal" (click)="closeModal()">Close</button>
    </body>
</html>