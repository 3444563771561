import { Component, OnInit,Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DocumentService } from './../../../services/document.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-document',
  templateUrl: './email-document.component.html',
  styleUrls: ['./email-document.component.css']
})
export class EmailDocumentComponent implements OnInit {
  submitted = false;
  sendEmail: FormGroup;
  @Input() public documentId;
  @Input() public action;
  constructor(public activeModal: NgbActiveModal,public fb:FormBuilder,public DocumentService:DocumentService, private toastr: ToastrService) { 
    this.sendEmail = this.sendEmailForm();
  }

  ngOnInit(): void {
  }

  sendEmailForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null,
        [Validators.required,
         Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]
        ],
        bccEmail:[null]
      }
    );
  }

  get f() { return this.sendEmail.controls; }

  closeModal() { this.activeModal.close(); }

  onSubmit(formData){
    this.submitted = false;
    if (this.sendEmail.invalid) {
      return;
    }

    if(this.action == 'bol'){

      let inputData ={
        "documentId": this.documentId,
        "emailId": formData.email,
        "bccEmailId": formData.bccEmail
      }    
      this.DocumentService.sendEmail(inputData).subscribe(result =>{
          this.activeModal.close({"success": true});          
      },error=>{
        this.activeModal.close({"success": false});
      })

    }else{

      let inputData ={
        "documentId": this.documentId,
        "emailId": formData.email,
        "bccEmailId": formData.bccEmail
      }
      
      this.DocumentService.sendCommercialEmail(inputData).subscribe(result =>{
          this.activeModal.close({"success": true});          
      },error=>{
        this.activeModal.close({"success": false});
      })

    }
  
  }
}
