import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommercialInvoiceService {
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })     
     ,withCredentials: true
  };
  constructor(public http:HttpClient) { }
  saveData(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/api/commercialInvoice/saveData",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));    
  }

  createShipment(carrierQuoteReq,quoteId){
    return this.http.put(environment.apiPath+ "/api/commercialInvoice/savePDF",{params: {}},this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));  
  }

  sendEmail(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/api/commercialInvoice/sendEmail",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));    
  }
}
