import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {

  @Input() data;
  printBOL: boolean;
  formattedDate:any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.printBOL = true;
    console.log('data here in view invoice',this.data)

    this.formattedDate = formatDate(this.data.orderDate, 'dd/MM/yyyy', 'en-US');
  }

  closeModal() { this.activeModal.close(); }

  print(doc){
    console.log('print here');
    if(doc=='bol'){
      this.printBOL = true;
    }
    setTimeout(() => {
      window.print();
    }, 0);
  }

  download(){
    let compData = this;
    html2canvas(document.querySelector('#content'), {useCORS: true}).then(function(canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        }    
        pdf.save("invoice_"+compData['data']['invoiceNumber']+'.pdf');
      };
    });

  }
}
