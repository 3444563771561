import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  constructor(public http:HttpClient) { }

  getInvoiceListing(page,filterParms,perPage){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/invoices/getAll/"+page+"/"+perPage+(filterParms ? '?'+filterParms:""),{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
  }

  getInvoiceDetails(orderId){  
// http://localhost:9090/shipcarte/api/invoices/getById?orderId=c76e3fcd-60f3-411c-ac7c-473f4f83ef7e 
    return this.http.get(environment.apiPath + "/api/invoices/getById?orderId="+orderId, { withCredentials: true });  
  }
}


