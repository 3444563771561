import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { getQuoteService } from '../services/get-quote/get-quote.service';
import { FormBuilder, Validators } from '@angular/forms';
import {CommonService} from '../../app/services/common.service'
import { PriceBrackupService } from '../services/PRiceBrackup/price-brackeup.service';
import { ShipmentListingService } from '../services/shipment-listing.service';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { AddFavoriteComponent } from 'src/app/_helpers/popup-dialog/add-favorite/add-favorite.component';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../environments/environment';
@Component({
  selector: 'app-shipment',
  styleUrls: ['./shipment.component.css'],
  templateUrl: './shipment.component.html',
  
})
export class ShipmentComponent implements OnInit {
  shipments: any;
  page = 0;
  paginationSize:number;
  displayCourier: boolean;
  totalPages: any;
  totalElements: any;
  filterCourier: any;
  perPage :number;
  pageNo = 0;
  currentPage: any;
  popOverDataPrice: any;
  statusData: any;
  hoveredDate: NgbDate | null = null;
  sortBy: any;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  isDesc: boolean = false;
  column: string = 'Order';
  searchKey: string;
  queryStringParam: string;
  dateFilterForm:any;
  startDate:any;
  endDate:any
  cYear:any;
  Orders:boolean;
  transit:boolean;
  completed:boolean;
  shipmentStatus='';
  selectedQuoteID: any;
  selectedOrderID: any;
  selectedStatus: any;
  shipperZip="";
  consigneeZip="";
  trackingData:any;
  constructor(public quote:getQuoteService,private modalService: NgbModal,public priceBr: PriceBrackupService, public common:CommonService,private router: Router, public config: NgbPaginationConfig,  private toast: ToastrService, private shipment: ShipmentListingService,private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private formBuilder: FormBuilder) 
  { 
    this.config.size = 'sm'; 
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }
  
  ngOnInit(): void {
    this.cYear =  environment.Cyear;
    this.sortBy = {
      "customOrderId": "down",
      "poNumber": "down",
      "shipmentModeName": "down",
      "pickupDate": "down",
      "shipperName": "down",
      "consigneeName": "down",
      "price": "down",
    }
    this.filterCourier = false;
    this.currentPage = 0;
    this.perPage=10;
    this.searchKey = '';
    this.allOrders();
    this.dateFilterForm = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', Validators.required],
    })
  }

  allOrders(){
    this.Orders=true;
    this.transit=false;
    this.completed=false;
    this.getShipmentitems(this.pageNo, this.filterCourier, this.perPage);

  }

  inTransit(){
    this.Orders=false;
    this.transit=true;
    this.completed=false;
    this.shipmentStatus="INTRANSIT";
    this.getShipmentTransititems(this.pageNo,this.filterCourier,this.perPage,this.shipmentStatus);

  }
  completedOrders(){
    this.Orders=false;
    this.transit=false;
    this.completed=true;
    this.shipmentStatus="DELIVERED";
    this.getShipmentTransititems(this.pageNo,this.filterCourier,this.perPage,this.shipmentStatus);
  }
  // public ascNumberSort = true;
  // public sortNumberColumn(event) {
  //   console.log('ifjfkbsd',event)
  //     this.ascNumberSort = !this.ascNumberSort;
  //     if(this.ascNumberSort) {
  //         this.shipments.sort((a, b) => a - b); // For ascending sort
  //     } else {
  //         this.shipments.sort((a, b) => b - a); // For descending sort
  //     }
  // }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  saveToFavorite(orderId) {

    const modalRef = this.modalService.open(AddFavoriteComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass:"my-custom-modal8"  });
    modalRef.componentInstance.orderId = orderId;
    modalRef.result.then((result) => { 
      if(result && result.success){
        this.toast.success('Order marked as Favorite Successfully.', 'Success', {
          timeOut: 10000,
        });
      }
    });
    
  }
  sort(property) {
    

    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.sortBy[property] = !this.isDesc ? "down" : "up"; 

    this.shipments.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });

  };
  getQuote(i){
    this.router.navigate(['/get-quote/'+this.shipments[i]['quoteDetails']['quoteId']])
  }


  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  getShipmentitems(page, filterPrams, items) {
    this.displayCourier = true;
    
    this.shipment.getShipmentItems(page, filterPrams, items).subscribe(ship => {
      if (ship['response'].totalPages <= 0) {
        this.shipments = [];
        this.displayCourier = false;
        // this.toast.error('NO data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      } else {
        this.displayCourier = true;
        for (let i = 0; i < ship["response"].length; i++) {

          this.priceBr.getPRicebrackup(ship['response'][i]['quoteDetails']["quoteId"]).subscribe(price => {

            ship['response'][i]["priceBr"] = price ? price : null;
            ship['response'][i]["price"] = price ? price['totalAmount'] : null;
            ship['response'][i]["poNumber"] = ship['response'][i]["quoteDetails"]['poNumber'];
            ship['response'][i]["shipmentModeName"] = ship['response'][i]["quoteDetails"]['shipmentModeName'];
            ship['response'][i]["pickupDate"] = ship['response'][i]["quoteDetails"]['pickupDate'];
            ship['response'][i]["shipperName"] = ship['response'][i]["quoteDetails"]['shipperAddress']['firstName'];
            ship['response'][i]["shipperName"] = ship['response'][i]["quoteDetails"]['shipperAddress']['firstName'];
            ship['response'][i]["consigneeName"] = ship['response'][i]["quoteDetails"]['consigneeAddress']['firstName'];
           
          });
        }
        //this.totalElements =   ship['totalPages'] * this.perPage;
        this.totalElements =   ship['totalEntries'];
        this.shipments = ship['response'];
        this.totalPages = ship['totalPages'];
        this.paginationSize=ship['totalPages'];
        this.displayCourier = false;
        console.log(this.shipments);
      }

    },error=>{
      
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayCourier = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    });
  }

  getShipmentTransititems(page, filterPrams, items , status) {
    this.displayCourier = true;
    
    this.shipment.getShipmentTransitItems(page, filterPrams, items , status).subscribe(ship => {
      if (ship['response'].totalPages <= 0) {
        this.shipments = [];
        this.displayCourier = false;
        // this.toast.error('NO data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      } else {
        this.displayCourier = true;
        for (let i = 0; i < ship["response"].length; i++) {

          this.priceBr.getPRicebrackup(ship['response'][i]['quoteDetails']["quoteId"]).subscribe(price => {

            ship['response'][i]["priceBr"] = price ? price : null;
            ship['response'][i]["price"] = price ? price['totalAmount'] : null;
            ship['response'][i]["poNumber"] = ship['response'][i]["quoteDetails"]['poNumber'];
            ship['response'][i]["shipmentModeName"] = ship['response'][i]["quoteDetails"]['shipmentModeName'];
            ship['response'][i]["pickupDate"] = ship['response'][i]["quoteDetails"]['pickupDate'];
            ship['response'][i]["shipperName"] = ship['response'][i]["quoteDetails"]['shipperAddress']['firstName'];
            ship['response'][i]["shipperName"] = ship['response'][i]["quoteDetails"]['shipperAddress']['firstName'];
            ship['response'][i]["consigneeName"] = ship['response'][i]["quoteDetails"]['consigneeAddress']['firstName'];
           
          });
        }
        //this.totalElements =   ship['totalPages'] * this.perPage;
        this.totalElements =   ship['totalEntries'];
        this.shipments = ship['response'];
        this.totalPages = ship['totalPages'];
        this.paginationSize=ship['totalPages'];
        this.displayCourier = false;
      }

    },error=>{
      
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayCourier = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    });
  }

  perPageItem(event) {
    this.displayCourier = true;

    const items = event.target.value;
    if (items != '') {
      this.perPage = items;
      this.getShipmentitems(this.page,this.queryStringParam,this.perPage);
    }
  }
  
  popOverIndex(i) {
    this.popOverDataPrice=[]
    this.popOverDataPrice=this.shipments[i]['priceBr'];
    this.popOverDataPrice['customId'] = this.shipments[i]['quoteDetails'].customQuoteId;
    this.popOverDataPrice['shpmtModeName'] = this.shipments[i]['quoteDetails'].shipmentModeName;
    this.popOverDataPrice['shipmentModeStyleCode'] = this.shipments[i]['quoteDetails'].shipmentModeStyleCode;
    //this.popOverDataPrice['carrierName'] = this.shipments[i]['quoteDetails'].carrierName;
    var charges = [];
    
    if(this.popOverDataPrice['charges']){
       
       this.popOverDataPrice.charges.forEach((item,index) => {
         for (var key of Object.keys(item)) {
         charges.push([key, item[key]])
       }
       });
       
    }
    this.popOverDataPrice['price_breakUp']=charges
    var totalWeight = 0;
    this.shipments[i]['quoteDetails']['items'].forEach((item, index)=>{
      totalWeight+=(item.wghtUnit.toLowerCase() == 'lbs' ? item.totalWeight : item.totalWeight/2.20 ).toFixed(2)*item.qty;
    });
    this.popOverDataPrice['weight'] = totalWeight;
  }
  
  showPOD(quoteid ,orderId,orderStatus){
    // console.log(quoteid, orderId,orderStatus);
    this.selectedQuoteID=quoteid;
    this.selectedOrderID=orderId;
    this.selectedStatus=orderStatus;

    this.quote.getTrackingData(orderId).subscribe( response =>{
      // console.log(response);
      this.trackingData=response;
    })
    
  }
  ViewDetailsON(quoteid ,orderId,orderStatus){
    // console.log(quoteid, orderId,orderStatus);
    this.selectedQuoteID=quoteid;
    this.selectedOrderID=orderId;
    this.selectedStatus=orderStatus;
    this.shipments.filter(element =>{
      if(element.orderId==orderId){
        this.shipperZip=element.quoteDetails.shipperAddress.zipCode;
        this.consigneeZip= element.quoteDetails.consigneeAddress.zipCode
      }
    })
    this.quote.getTrackingData(orderId).subscribe( response =>{
      // console.log(response);
      this.trackingData=response;
    })
  }
  viewPOD(){
    this.common.viewPOD=true;
    this.router.navigate(['/shipment/details/'+ this.selectedQuoteID+'/'+ this.selectedOrderID+'/' +this.selectedStatus]);

  }
  viewDetails(){
    this.common.viewDetails=true;
    this.router.navigate(['/shipment/details/'+ this.selectedQuoteID+'/'+ this.selectedOrderID+'/' +this.selectedStatus]);

  }
  status(i) {
    this.statusData = this.shipments[i];
  }

  selectPage(i) {
    this.displayCourier = true;
    this.currentPage = i.page - 1;
    this.shipment.getShipmentItems(i.page - 1, this.filterCourier ? this.filterCourier : this.queryStringParam, this.perPage).subscribe(a => {
      if (a["response"].empty) {
        this.shipments = [];
        this.displayCourier = false;
        // this.toast.error('No data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      }
      for (let i = 0; i < a["response"].length; i++) {
        this.priceBr.getPRicebrackup(a['response'][i]['quoteDetails']["quoteId"]).subscribe(price => {

          a['response'][i]["priceBr"] = price ? price : null;
         

        });
      }
      
      this.totalPages = a['response'].totalPages;
      this.totalElements = a['totalEntries'];
      this.shipments = a['response'];

      this.displayCourier = false;
    }, error => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayCourier = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    })

  }

  filterByValue(event) {
    if(event.target.value){
      this.currentPage = 0;
      this.queryStringParam = event.target.value ? 'shippingMode='+ event.target.value: '';
      if(this.shipmentStatus=='INTRANSIT ' || this.shipmentStatus=="DELIVERED"){
        this.getShipmentTransititems(this.currentPage,this.queryStringParam,this.perPage,this.shipmentStatus);
      }
      else{
        this.getShipmentitems(this.currentPage, this.queryStringParam, this.perPage);

      }
    }else{
      this.currentPage = 0;
      if(this.shipmentStatus=='INTRANSIT ' || this.shipmentStatus=="DELIVERED"){
        this.getShipmentTransititems(this.currentPage,false,this.perPage,this.shipmentStatus);
      }
      else{
        this.getShipmentitems(this.currentPage, false , this.perPage);
      }
      
    }

  }

  viewQuote(quoteid ,orderId,orderStatus){
    this.router.navigate(['/shipment/details/'+ quoteid+'/'+ orderId+'/' +orderStatus]);
  }

  updateSearchKey(searchKey){ this.searchKey = searchKey }

  apply(){
    this.startDate = this.dateFilterForm['value'].startDate;
    this.endDate = this.dateFilterForm['value'].endDate;
    let d1 = Date.parse(this.dateFilterForm['value'].startDate);
    let d2 = Date.parse(this.dateFilterForm['value'].endDate);
    // console.log(this.startDate,this.endDate)

    if ((this.startDate && this.endDate) && (d1 > d2)) {
      this.startDate = null;
      this.endDate = null;
      this.dateFilterForm.reset();
      this.toast.error('Please provide valid Dates.', '', {
        timeOut: 10000,
      });
      return;
    }

    this.currentPage = 0;
    this.queryStringParam = '';

    if(this.startDate && this.endDate){
      this.queryStringParam = 'startDate='+this.startDate+'&endDate='+this.endDate;
    }

    if(this.searchKey){
      this.queryStringParam += this.queryStringParam ? '&searchKey='+this.searchKey : 'searchKey='+this.searchKey;
    }
    if(this.shipmentStatus==''){
    this.getShipmentitems(this.currentPage, this.queryStringParam, this.perPage);
    }
    else if(this.shipmentStatus=="DELIVERED" || this.shipmentStatus=="INTRANSIT"){
      this.getShipmentTransititems(this.currentPage,this.queryStringParam,this.perPage,this.shipmentStatus);
    }
  }
 
}

