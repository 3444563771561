
    <div style="top: 20px;padding: 40px;">
        <img class="d-block m-auto" src="assets/img/emailDoc.svg"  width="13%">
        <form [formGroup]="sendEmail" name="sendEmail" (ngSubmit)="onSubmit(sendEmail.value)">
            <div class="form-group mb-0">
              <label for="exampleInputEmail1">Email*</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" formControlName="email" [ngClass]="sendEmail.controls['email'].touched || sendEmail.controls['email'].dirty ? 'is-invalid' : 'is-valid'"
              required >

              <div class="password-label">
                <label class="col-6 p-0" [ngClass]="sendEmail.controls['email'].hasError('required')  ? 'text-danger1' : 'd-none'">
                  <i style="float: left;" class="material-icons">{{ sendEmail.controls['email'].hasError('required')  ? 'clear' :
                    'done' }}</i>
                  <small class="error-align">Email is required</small>
                </label>
                <label class="col-6 p-0" [ngClass]="sendEmail.controls['email'].hasError('pattern')  ? 'text-danger1' : 'd-none'">
                  <i style="float: left;" class="material-icons">{{ sendEmail.controls['email'].hasError('pattern')  ? 'clear' :
                    'done' }}</i>
                  <small class="error-align">Valid Email is required</small>
                </label>
              </div>
             
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">BCC Email <i>(optional)</i></label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" formControlName="bccEmail">
            </div>
            <div class="text-right">
                <button type="button" class="btn faint-grey col-4" style="box-shadow: none;" (click)="closeModal()">Cancel</button>
                &nbsp;&nbsp;
                <button type="submit" class="btn orange text-white col-3"  style="box-shadow: none;" >Send</button>
            </div>
        </form>
        <br>
    </div> 
    <hr>
  

