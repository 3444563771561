<div class="container">
    <div class="row">
        <div class="col-1">
            <app-leftsidebar></app-leftsidebar>
        </div>
        <div class="col-11">
            <div class="fav_section ml-5 mr-5">
                <!-- <div class="row mt-5 pt-3 pl-4">
                    <div class="main-heading d-inline"><img src="assets/img/commercial/Mask.svg"> 
                       Shipments
                    </div>
                        <label class="d-inline float-right" style="top: 84px;left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Show
                            <select class="form-control col-1">
                                    <option  class="dropdown-item" value="10">10</option>
                                    <option  class="dropdown-item" value="25">25</option>
                                    <option  class="dropdown-item" value="50">25</option>
                                    <option  class="dropdown-item" value="100">100</option>
                                </select></label>
                   
                   

                </div> -->
                <div class="row mt-5 pt-3 pl-4">
                    <div class="col-10">
                        <div class="main-heading d-inline"><img src="assets/img/Star.svg" width="15"> 
                            <span>Favorite Orders</span>
                        </div>
                    </div>
                    <div class="col-2  float-right">
                        <label class="d-inline" style="top: 84px;left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Show
                        </label>
                        <select class="custom-select col-9 d-inline ml-2" (change)=" perPageItem($event)">
                                    <option  class="dropdown-item" value="10">10 items per page</option>
                                    <option  class="dropdown-item" value="25">25 items per page</option>
                                    <option  class="dropdown-item" value="50">50 items per page</option>
                                    <option  class="dropdown-item" value="100">100 items per page</option>
                                </select>
                    </div>
                </div>
                <div class="fav_listing pl-4">
                    <div class="fav_listing_inner pl-2 pr-4">

                        <form [formGroup]="dateFilterForm">
                        <div class="col-12 applay_section pt-3">
                            <div class="row">
                                <div class="col-2 pt-3 filter">
                                    <select class="custom-select" (change)="filterByValue($event)" >
                                            <option selected value="">Filters</option>
                                            <option value="CRS">Courier</option>
                                            <option value="LTL">LTL</option>
                                            <option value="FTL">Full Load</option>
                                            <option value="VLTL">Volume LTL</option>
                                            
                                            <option>PO No.</option>
                                            <option>In Transist</option>
                                            <option>Delivered</option>
                                          
                                        </select>
                                </div>
                                
                                 
                                <div class="col-2 pt-3 start-date-box">

                                   
                                    <!-- <input class="form-control" placeholder="Start" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <div class="input-group col-12">
                                        <input type="date" class="form-control" placeholder="yyyy-mm-dd"
                                                 name="dp"  formControlName="startDate"  >
                                        <!-- <input #dpFromDate
                                               class="form-control" placeholder="Start"
                                               name="dpFromDate"
                                               [value]="formatter.format(fromDate)"
                                               (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                        <div class="input-group-append">
                                          <button class="btn  calendar" (click)="datepicker.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                                        </div> -->
                                      </div>
                                </div>
                                <div class="col-2 pt-3 end-date-box">
                                    
                                    <!-- <input class="form-control" class="form-control" placeholder="End" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <div class="input-group col-12">
                                        <input class="form-control" type="date" placeholder="yyyy-mm-dd"
                                                 name="dp"  formControlName="endDate"  >
                                        <!-- <input #dpToDate
                                               class="form-control" placeholder="End"
                                               name="dpToDate"
                                               [value]="formatter.format(toDate)"
                                               (input)="toDate = validateInput(toDate, dpToDate.value)">
                                        <div class="input-group-append">
                                          <button class="btn calendar" (click)="datepicker.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                                        </div> -->
                                      </div>
                                </div>
                                <div class="col-4 pt-3 serachbar-box">
                                    <input class="form-control" id="myInput" [ngModelOptions]="{standalone: true}" [ngModel]="searchKey" (change)=keyWordSearch($event) type="text" placeholder="Search.." />
                                </div>
                                <div class="col-2 pt-3 text-center apply-btn-box">
                                    <button type="button" class="btn btn-outline-warning" (click)="applySearchFilter()">Apply</button>
                                </div>

                            </div>
                        </div>
                    </form>
                        <div class="row shipment_history_table mb-2">
                            <div class="col-12 mt-3 pl-3">
                                <!-- <div *ngIf="!displayErrorMessage">
                                        {{errorMessage}} <button class="btn orange text-white" (click)="redirectTo()">"I'LL CREATE ONE"!</button>
                                    </div> -->
                                <table class="table table-responsive">
                                    <thead>
                                       <tr>
                                            <th>
                                                <span class="down"> Favorite Order Name</span>

                                            </th>
                                            <th>
                                                <span class="down"> Service</span>

                                            </th>
                                            <th>
                                                <span class="down"> Shipper</span>

                                            </th>
                                            <th>
                                                <span class="down"> Origin</span>

                                            </th>
                                            <th>
                                                <span class="down"> Consignee</span>

                                            </th>
                                            <th>
                                                <span class="down"> Destination</span>

                                            </th>
                                            <th></th>
                                            <th>Actions</th>
                                       </tr>
                                    </thead>
                                    <tbody class="pt-3">
                                        <tr  style="margin-top: 20px;color: #5d4646;" *ngIf="favouriteItems?.length == 0">
                                           <td colspan="8" class=" text-center">No data found!!!</td>  
                                        </tr>
                                        <tr *ngFor="let key of favouriteItems;let i = index ">
                                           <td><img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" placement="bottom" popoverClass="popover_fav_order" [ngbPopover]="popContent" (mouseenter)="popOverIndex(i)" triggers="mouseenter:mouseleave" />
                                            &nbsp;{{key['favOrderName'] ? key['favOrderName'] :'----'}}

                                        </td >   
                                            <td><img src="assets/img/quotes/shmt/courier.svg" width="15%">{{key['quoteDetails']['shipmentModeName']}}</td>    
                                            <td>{{key['quoteDetails']['shipperAddress'].firstName ? key['quoteDetails']['shipperAddress'].firstName : ''}} {{key['quoteDetails']['shipperAddress'].lastName ? key['quoteDetails']['shipperAddress'].lastName
                                                : ''}}</td>  
                                            <td>{{key['quoteDetails']['shipperAddress']['city']}}</td>  
                                            <td>{{key['quoteDetails']['consigneeAddress'].firstName ? key['quoteDetails']['consigneeAddress'].firstName : ''}} {{key['quoteDetails']['consigneeAddress'].lastName ? key['quoteDetails']['consigneeAddress'].lastName
                                                : ''}}</td>   
                                            <td>{{key['quoteDetails']['consigneeAddress']['city']}}</td> 
                                            <td> <button type="button" class="get-quote" (click)="getQuote(key['quoteDetails']['quoteId'])">Get Quote</button></td>                         
                                            <td class="action_td">
                                                <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle" data-toggle="dropdown">
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item" (click)="editQuote(key['quoteDetails']['quoteId'])" >Ship Again</a>
                                                    <div class="dropdown-divider "></div>
                                                    <a class="dropdown-item text-danger" (click)="open_modal(key)">Remove from list</a>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- <tr><td colspan="Number of columns"></td></tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                       
                        <div class="row mb-2 pl-2 pb-0">

                            <div class="col-8" style="margin-top: 20px;color: #7b7b7b;">
                                 Showing {{currentPage==0 ? 1 : (currentPage * perPage)+1}} to {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}

                            </div>

                            <div class="col-4 pagination_right">

                                <ngb-pagination #i [collectionSize]="totalElements" [maxSize]="5" [rotate]="true" style="margin-top: 14px;  margin-left: 219px;" [pageSize]="perPage" [(page)]="page" (click)="selectPage(i)">
                                    <ng-template ngbPaginationPrevious style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Prev</ng-template>
                                    <ng-template ngbPaginationNext style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Next</ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 pt-3 pl-4 footer">
                    <p class="copyright d-inline">© {{cYear}} ShipCarte. All Rights Reserved.</p>
                    <span class="ml-1 mt-1 mb-5 d-inline">
                            <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                            </a>
                        </span>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #popContent>
    Favorite Order Name
    <div class="grey-devider"></div>
    <div class="row mb-2">
        <div class="col-6">
            <img src="{{popOverDataPrice && popOverDataPrice.carrierLogo ? popOverDataPrice.carrierLogo : 'assets/img/payment/Courier.svg'}}" width="60px" class="m-1"></div>
        <div class="col-6 text-right">
            <div>{{popOverDataPrice && popOverDataPrice?.shpmtModeName}} <img src="assets/img/quotes/shmt/{{popOverDataPrice && popOverDataPrice?.shipmentModeStyleCode}}.svg" width="30px" class="m-1"></div>
        </div>
    </div>
    <div class="row  mb-2">
        <div class="col-6">
            Quantity
        </div>
        <div class="col-6 text-right">{{popOverDataPrice && popOverDataPrice?.quantity}}</div>
    </div>
    <div class="row  mb-2">
        <div class="col-6">
            Weight 
        </div>
        <div class="col-6 text-right">{{popOverDataPrice && popOverDataPrice?.weight | number : '1.2-2'}} lbs</div>
    </div>
    <div class="row  mb-2">
        <div class="col-6">
            Class
        </div>
        <div class="col-6 text-right">{{popOverDataPrice && popOverDataPrice.class ? popOverDataPrice.class : '--' }}</div>
    </div>
    <div class="row  mb-2">
        <div class="col-6">
            Amount Charged
        </div>
        <div class="col-6 text-right">${{popOverDataPrice && popOverDataPrice.amountCharged ? popOverDataPrice.amountCharged : '0'}} {{popOverDataPrice.currency}}</div>
    </div>
    <div class="row  mb-2">
        <div class="col-6">
            Last Shipped
        </div>
        <div class="col-6 text-right">{{popOverDataPrice && popOverDataPrice?.lastShipped}}</div>
    </div>    
   
</ng-template>
<ng-template #popContentDelivered>
    <div class="badge badge-pill badge-success">Delivered</div>
    <p class="d-inline orange-text pl-2">View POD</p>
    <div class="row mb-2 mt-2">
        <div class="col-6">Received By</div>
        <div class="col-6 text-right"></div>
    </div>
    <div class="grey-devider"></div>
    <div class="row mb-2">
        <div class="col-6">Date</div>
        <div class="col-6 text-right">--/--/----</div>
    </div>
    <div class="grey-devider"></div>
    <div class="row mb-2">
        <div class="col-6">Time</div>
        <div class="col-6 text-right">--.-- --</div>
    </div>
</ng-template>
<ng-template #popContentIntransit>
    <div class="badge badge-pill badge-primary">In-Transit</div>
    <div class="mb-2 mt-3 row">
         <div class="col-7">Original Delivery Date</div>
        <div class="col-5 text-right">--/--/----</div>
    </div>
    <div class="grey-devider"></div>
    <div class="mb-2 mt-2 row">
         <div class="history-tl-container">
            <ul class="tl">
                <li class="tl-item">
                    <div class="item-title"><span class="text-dark">Origin</span>
                        <p class="d-inline h5"> Origin Zip</p>
                    </div>
                </li>
                <li class="tl-item">
                    <div class="item-title"><span class="text-dark">Destination</span>
                        <p class="d-inline h5"> Destination Zip</p>
                    </div>
                </li>
            </ul>
            <div class="mb-2 row">
                <div class="col-7">
                    <div>Current Location</div>
                    <div class="grey-text">Toronto, CA</div>
                </div>
                <div class="col-5 orange-text">&#43; View Details</div>
            </div>
        </div>
    </div>
    <div class="grey-devider"></div>
    <div class="mb-2 row mt-2">
        <div class="col-7">Expected Delivery Date</div>
        <div class="col-5 text-right">--/--/----</div>
    </div>
</ng-template>


<div class="container" *ngIf="displayLoader">
    <div class="overlay-box">
        <div>
            <img src="/assets/img/quotes/loader.svg" alt="">
            <h2 class="mt-3">Loading your options, please wait… </h2>
        </div>
    </div>
</div>