import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from "ngx-mask";
import { LeftsidebarComponent } from './../../leftsidebar/leftsidebar.component';
import { HeaderComponent } from './../../_helpers/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import {ToastModule} from 'ng2-toastr/ng2-toastr';
// import {ToastOptions} from 'ng2-toastr';

// export class CustomOption extends ToastOptions {
//   positionClass: 'toast-bottom-full-width';
//   animate = 'flyRight'; // you can override any options available
//   showCloseButton = true;
//   toastLife= 100000;
// }



@NgModule({
  declarations: [LeftsidebarComponent,HeaderComponent],
  imports: [
    CommonModule ,GooglePlaceModule,NgbModule, // required animations module
    ToastrModule.forRoot({ positionClass: 'inline' ,preventDuplicates:true}),NgxMaskModule.forRoot()
  ],
  exports:[LeftsidebarComponent,HeaderComponent/*,GooglePlaceModule*/],
  // providers:[ {provide: ToastOptions, useClass: CustomOption}]
})
export class SharedModule { }
