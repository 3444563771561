<div class="container" style="top: 15px;position: absolute;padding: 20px;">
    <span>
        <h3 style="font-size: 20px !important;">Adding Contact Information 
            <button type="button" class="close float-right " (click)='closeModal()' data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons">close</span>
                </span>
            </button>
        </h3>
    </span>
    <hr>
    <div>
        <form [formGroup]="addressForm" (ngSubmit)="submit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="firstName">First Name</label>
                    <input formControlName="firstName" type="text" class="form-control" id="firstName" placeholder="Enter first name">
                    <div *ngIf="addressForm.get('firstName').invalid && (addressForm.get('firstName').dirty || addressForm.get('firstName').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('firstName').errors.required">
                            Name is required.
                        </div>
                        <div *ngIf="addressForm.get('firstName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="addressForm.get('firstName').errors.minLength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="addressForm.get('firstName').errors.maxLength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="lastName">Last Name</label>
                    <input formControlName="lastName" type="text" class="form-control" id="lastName" placeholder="Enter last name">
                    <div *ngIf="addressForm.get('lastName').invalid && (addressForm.get('lastName').dirty || addressForm.get('lastName').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('lastName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="addressForm.get('lastName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="addressForm.get('lastName').errors.minLength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="addressForm.get('lastName').errors.maxLength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="popup_icon">Position</label>
                    <input type="text" formControlName="Position" class="form-control" id="position" placeholder="Enter position">
                </div>
                <div class="form-group col-md-4">
                    <label for="phone">Phone</label>
                    <input formControlName="phone" type="text" class="form-control" id="phone" placeholder="Enter phone">
                    <div *ngIf="addressForm.get('phone').invalid && (addressForm.get('phone').dirty || addressForm.get('phone').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('phone').errors.required">
                            Mobile is required.
                        </div>
                        <div *ngIf="addressForm.get('phone').errors.pattern">
                            Enter correct format.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label for="ext">Ext.</label>
                    <input type="text" style="width: 85% !important;" formControlName="Ext" class="form-control" id="ext" placeholder="Enter Ext">
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input formControlName="email" type="email" style="width: 98% !important;" class="form-control" id="email" placeholder="Enter email">
                <div *ngIf="addressForm.get('email').invalid && (addressForm.get('email').dirty || addressForm.get('email').touched)" [ngClass]="'error'">
                    <div *ngIf="addressForm.get('email').errors.required">
                        Email is required.
                    </div>
                    <div *ngIf="addressForm.get('email').errors.pattern">
                        Enter correct Email format.
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn faint-grey col-3" (click)='closeModal()' style="box-shadow: none;">Cancel</button> &nbsp;&nbsp; <button type="submit" [disabled]="!addressForm.valid" class="btn orange text-white col-3" style="box-shadow: none;">Save</button>
            </div>
        </form>
    </div>
</div>