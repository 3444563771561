


<div class="container">	
	<div class="row">	
		<div class="col-2">
			<app-leftsidebar></app-leftsidebar>
		</div>

        <div class="col-10">
            
            <div class="quote_history_section">
                <div class="row">
                    <div class="col-12 mt-5 pt-5 pb-3 pl-5">
                        <div class="main-heading"><img src="assets/img/quotes/doller_arrow_circul.svg" />Our Partner Carriers</div>
                        <!-- <label style="top: 84px;left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Show<select>
                            <option  class="dropdown-item" value="10">10</option>
                            <option  class="dropdown-item" value="25">25</option>
                            <option  class="dropdown-item" value="50">25</option>
                            <option  class="dropdown-item" value="100">100</option>
                          </select></label> -->
                        
                    </div>
                    <div class="history_listing pl-5">
                    <div class="history_listing_inner pl-2 pr-4">
                        <div class="row get_quote_history_table mb-2">
                            <div class="col-12 mt-3 pl-3">
                                <table class="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">Carrier.Id </th>
                                            <th scope="col">Carrier Code</th>
                                            <th scope="col" >Carrier Name</th>
                                            <th scope="col" >Def Currency </th>
                                            <th scope="col" >Commission </th>
                                            <th scope="col" >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="pt-3" >
                                        <tr *ngFor="let quote of carriers">
                                            <td>{{quote.carrierId}}&nbsp;&nbsp;</td>
                                            <td>{{quote.carrierCode}}&nbsp;&nbsp;</td>
                                            <td>{{quote.carrierName}}&nbsp;&nbsp;</td>
                                            <td>{{quote.defCurrency}}&nbsp;&nbsp;</td>
                                            <td><input type="number" min=0 max=100 style="color: #151522;
                                                font-size: 10px;
                                                padding-top: 0px;" [(ngmodel)]="commission" oninput="validity.valid||(value='');"></td>
                                            <td><input type="button" value="Ok" [disabled]="!commission"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 pt-5 pb-5 pl-5">
                        <p class="copyright">© 2020 ShipCarte. All Rights Reserved.</p>
                        <div class="social_media_box">
                            <img src="assets/img/quotes/linked-in.svg" />
                            <img src="assets/img/quotes/instagram.svg" />
                            <img src="assets/img/quotes/youtube.svg" />
                            <img src="assets/img/quotes/twitter.svg" />
                            <img src="assets/img/quotes/facebook.svg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="container"  [ngClass]="displayCourier ? 'showClass' : 'hideClass'">
	<div class="overlay-box" style="color: #000;
	position: fixed;
	width: 100%;
	background: #fff;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999999;
	opacity: 0.8;">
		<div class="payment_status_popup" style="width: 460px;margin: 0 auto;text-align: center;position: absolute;
		left: 0;right: 0;top: 30%;">
		  <img src="/assets/img/quotes/loader.svg" alt="">
		  <h2 class="mt-3" style="
		  font-size: 20px !important;
		  color: #151522;
		  margin: 0;
		  padding: 0;
		  font-weight: 600 !important;
		  text-align: center;">Loading your options, please wait… </h2>    
		</div>

	</div>
  </div>