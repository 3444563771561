<div class="container">
    <div class="row">
        <div class="col-1">
            <app-leftsidebar></app-leftsidebar>
        </div>

        <div class="col-11  bg-white user-preferences mb-0">

            <div class="mt-5 mb-5">
                <div class="row mt-5 pt-3 pl-5 ml-2 mb-0 bg-light">
                    <h3 class="mb-0 page_heading ml-4 p-2">User Preferences</h3>
                </div>
                <div class="">
                    <mat-tab-group mat-align-tabs="start" animationDuration="000ms">
                       
                        <mat-tab label="Profile">
                            <div class=" col-11 row">
                                <div class="col-3 mt-4">
                                    <div class="col-8 p-0">
                                        <div class="image-container" style="border-radius: 50%;
                                        ">
                                            <img class="p-1" src="{{image ? image : 'assets/img/shipcarte/assets/profiles/default-user-dark.png' }}" width="160" style="border-radius: 50%;
                                            height: 100%;">
                                            <div class="overlay text-center" style="border-radius: 50%;
                                            "><i class="fa fa-camera cam-icon"><input style="width: 55%;" type="file"
                                                        (change)="onFileChanged($event)"></i></div>
                                        </div>
                                        <h2 class="text-cenetr mt-2" style="font-size: 16px !important;
                                        text-align: center;">{{userprefrenceObj && userprefrenceObj?.name}} {{userprefrenceObj && userprefrenceObj?.lastName}}</h2>
                                    </div>


                                    <div class="card p-3">
                                        <h3> <img class="p-1" src="assets/img/sound.svg" width="30">Referral Code</h3>
                                        
                                        <p>The pain itself is the key to the customer's pain. He flees to the rejecting, flees from the troubles of flight!</p>
                                        <div class="input-group mb-3 mt-2">
                                            <input type="text" class="form-control col-4" value="GC5245" [(ngModel)]="content" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" readonly>
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary orange white-text" (click)="copyText()" style="border: none;box-shadow: none;" type="button"><i class="fa fa-copy p-1"
                                                        style="font-size: 12px;"></i>Copy</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <h3 class="page-heading mt-4">Profile Preferences</h3>
                                    <div class="card col-10 p-0" *ngIf="!displayEditForm">
                                        <div class="card-header mb-0 ">
                                            <h4 class="mb-0 pl-2 d-inline">Personal Details</h4>
                                            <span class="d-inline float-right p-1 blue-text cpointer" (click)="editProfile()">
                                                <img class="p-1" src="assets/img/edit.svg">
                                                <small>Edit</small>
                                            </span>

                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">First Name</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userprefrenceObj && userprefrenceObj?.name}}</p>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">Last Name</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userprefrenceObj && userprefrenceObj?.lastName}}</p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row mb-0">
                                                <div class="col">
                                                    <div>

                                                        <p class="grey-text">Mobile</p>

                                                    </div>
                                                    <div>
                                                        <p>{{userprefrenceObj && userprefrenceObj?.phoneNumber}}</p>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div>

                                                        <p class="grey-text">Extension</p>

                                                    </div>
                                                    <div>
                                                        <p>{{userprefrenceObj && userprefrenceObj?.Extension}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card col-10 p-0" *ngIf="displayEditForm">
                                        <form class="mt-3" [formGroup]="personalDetailsForm" (ngSubmit)="f.form.valid && onSavePersonalDetails()">
                                            <div class="card-header mb-0 ">
                                                <h4 class="mb-0 pl-2 d-inline">Personal Details</h4>
                                                <span class="d-inline float-right p-1 blue-text cpointer">
                                                    <small (click)="onSavePersonalDetails()">Save</small>
                                                </span>
                                                <span class="d-inline float-right p-1 blue-text cpointer">
                                                    <small (click)="onCancelPersonalDetails()">Cancel</small>
                                                </span>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">First Name<span class="red-text">*</span></p>
                                                        </div>
                                                        <div>
                                                            <input type="text" class="form-control" name="firstName" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && (personalDetailsForm.controls.firstName.errors || personalDetailsForm.controls.firstName.invalid) }" required>
                                                            <div *ngIf="submitted && (personalDetailsForm.controls.firstName.errors || personalDetailsForm.controls.firstName.invalid)" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="personalDetailsForm.controls.firstName.errors.required || personalDetailsForm.controls.firstName.invalid">First name must be entered</div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">last Name<span class="red-text">*</span></p>
                                                        </div>
                                                        <div>
                                                            <input type="text" class="form-control" name="firstName" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && (f.lastName.errors || f.lastName.invalid) }" required>
                                                            <div *ngIf="submitted && (f.lastName.errors || f.lastName.invalid)" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f.lastName.errors.required || f.lastName.invalid">Last name must be entered</div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="row mb-0">
                                                        <div class="col-md-6">
                                                            <p class="grey-text">Mobile</p>
                                                            <a *ngIf="isMobileVerified" class="email-verified">Verified</a>
                                                            <a *ngIf="!isMobileVerified" (click)="verifyEmail('mobile')" class="email-verify">Please verify</a>
                                                            <input type="text" class="form-control" placeholder="Enter Mobile" name="email" formControlName="workPhone" [ngClass]="{ 'is-invalid': submitted2 && f2.workPhone.errors }" required>
                                                            <div *ngIf="f.workPhone.errors && f.workPhone.touched" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f.workPhone.errors.required">Mobile number is required </div>
                                                                <div class="red_alert_msg" *ngIf="f.workPhone.errors.pattern">Mobile number is not 10 digit</div>
                                                            </div>
                                                            <!-- <div class="red_alert_msg" *ngIf="personalDetailsForm.get('workPhone').errors">
                                                                Enter correct format.
                                                            </div> -->
                                                            <div *ngIf="verifyMobileError && f.workPhone.errors">
                                                                <div class="red_alert_msg" style="margin-top: -15px;" *ngIf="f.workPhone.errors.invalid">Please provide valid number.</div>
                                                              </div>                                                             
                                                        </div>
                                                        <div class="form-group col-md-6" style="padding-top: 5px;">
                                                            <p class="grey-text pt-3">EXT.</p>
                                                            <input formControlName="Extension" type="text" style="width: 85% !important;" class="form-control" id="ext" placeholder="">
                                                        </div>
                                                </div>
                                            </div>
                                        </form> 
                                    </div>
                                    <div class="card col-10 p-0" *ngIf="!displayEditAddressForm">
                                        <div class="card-header mb-0 ">
                                            <h4 class="mb-0 pl-2 d-inline cpointer">Address</h4>
                                            <span class="d-inline float-right p-1 blue-text" (click)="editAddress()">
                                                <img class="p-1" src="assets/img/edit.svg">
                                                <small>Edit</small>
                                            </span>

                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">Company</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userAddressData && userAddressData?.company}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">Street Address</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userAddressData && userAddressData?.addrLine1}}</p>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">Suit Or Apartment</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userAddressData && userAddressData?.addrLine2}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-0">
                                                <div class="col">
                                                    <div class="row">
                                                        <div class="col">
                                                            <div>
                                                                <p class="grey-text">city</p>
                                                            </div>
                                                            <div>
                                                                <p>{{userAddressData && userAddressData?.city}}</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">Zip/Postal Code</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userAddressData && userAddressData?.zipCode}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row mb-0">

                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">Country</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userAddressData && userAddressData?.country}}</p>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div>
                                                        <p class="grey-text">State / Province</p>
                                                    </div>
                                                    <div>
                                                        <p>{{userAddressData && userAddressData?.state}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="card col-10 p-0" *ngIf="displayEditAddressForm">
                                        <form class="mt-3" [formGroup]="addressDetailsForm" (ngSubmit)="f1.form.valid && onSaveAddressDetails()">
                                            <div class="card-header mb-0 ">
                                                <h4 class="mb-0 pl-2 d-inline">Address</h4>
                                                <span class="d-inline float-right p-1 blue-text cpointer">
                                                    <small (click)="onSaveAddressDetails()">Save</small>
                                                </span>
                                                <span class="d-inline float-right p-1 blue-text cpointer">
                                                    <small (click)="onCancelAddressDetails()">Cancel</small>
                                                </span>

                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">Company<span class="red-text">*</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <input type="text" class="form-control" name="companyName" formControlName="companyName" [ngClass]="{ 'is-invalid': submitted1 && (f1.companyName.errors || f1.companyName.invalid) }"  required>

                                                            <div *ngIf="submitted1 && (f1.companyName.errors || f1.companyName.invalid)" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f1.companyName.errors.required || f1.companyName.invalid">Company name must be entered</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">Street Address<span class="red-text">*</span></p>
                                                        </div>
                                                        <div>
                                                            <input type="text" class="form-control" name="streetAddress" formControlName="streetAddress" [ngClass]="{ 'is-invalid': submitted1 && (f1.streetAddress.errors || f1.streetAddress.invalid) }"   required>
                                                            <div *ngIf="submitted1 && (f1.streetAddress.errors || f1.streetAddress.invalid)"class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f1.streetAddress.errors.required || f1.streetAddress.invalid">Enter Street Address </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">Suit Or Apartment</p>
                                                        </div>
                                                        <div>
                                                            <input type="text" class="form-control" name="suite" formControlName="suite">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row mb-0">
                                                    <div class="col">
                                                        <div class="row">
                                                            <div class="col">
                                                                <div>
                                                                    <p class="grey-text">City<span class="red-text">*</span></p>
                                                                </div>
                                                                <div>
                                                                    <input type="text" class="form-control" name="city" formControlName="city" [ngClass]="{ 'is-invalid': submitted1 && (f1.city.errors || f1.city.invalid) }" required>
                                                                    <div *ngIf="submitted1 && (f1.city.errors || f1.city.invalid)" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f1.city.errors.required || f1.city.invalid">Enter City
                                                                        </div>
                                                                    </div>                                    
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">Zip/Postal Code<span class="red-text">*</span></p>
                                                        </div>
                                                        <div>
                                                            <input type="text" class="form-control" style="text-transform: uppercase;" name="zip" formControlName="zip" [ngClass]="{ 'is-invalid': submitted1 && (f1.zip.errors || f1.zip.invalid) }" required>
                                                            <div *ngIf="submitted1 && (f1.zip.errors || f1.zip.invalid)" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f1.zip.errors.required || f1.zip.invalid">Enter Zip/Postal Code
                                                                </div>
                                                            </div>                                       
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="row mb-0">

                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">Country<span class="red-text">*</span>
                                                            </p>
                                                        </div>
                                                        <div>
                                                           
                                                            <select class="custom-select" formControlName="country" [ngClass]="{ 'is-invalid': submitted1 && f1.country.errors }" required (change)="updateStateList($event.target.value)">
                                                                <option *ngFor="let obj of countryListData;let i = index"  [value]="obj['countryCode']" [selected]="i == 0">{{obj['countryName']}}</option>
                                                            </select>
                                                            <div *ngIf="submitted1 && f1.country.errors" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f1.country.errors.required">Enter Country
                                                                </div>
                                                            </div>                        
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div>
                                                            <p class="grey-text">State / Province<span class="red-text">*</span></p>
                                                        </div>
                                                        <div>
                                                            <select class="custom-select" formControlName="state" [ngClass]="{ 'is-invalid': submitted1 && f1.state.errors }" required>
                                                                <option *ngFor="let obj of countryStateListObj" [value]="obj['code']">{{obj['name']}}</option>
                                                            </select>
                                                            <div *ngIf="submitted1 && f1.state.errors" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f1.state.errors.required">Enter State </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>


                            </div>
                        </mat-tab>
                        <mat-tab label="Email">
                            <div class="row mt-1 pl-2 mb-0">
                                <h3 class="mb-0 page_heading">Email Preferences</h3>
                                <p style="font-weight: 400 !important;"> Your current email address is <span style="font-weight: 600 !important;">{{activeEmailId}}</span></p>
                            </div>
                            <div class="row pl-2 mt-2">
                                <div class="col-7 p-2" style="background-color: rgb(255 241 206);">
                                    <h3 class="mb-0" style="font-weight: 400 !important;"><img class="p-1" src="assets/img/rectangle.svg" width="4%">Connected account</h3>
                                    <p class="ml-2">Your account is connected to a Google account. Changing the email address here will disconnect your account from the Google account.</p>
                                </div>
                                <div class="card m-1 col-7">
                                    <form class="mt-3" [formGroup]="changeEmailAddress" (ngSubmit)="onChangeEmailAddress()">
                                        <div class="card-header">
                                            <h3 class="mb-0">Change Email</h3>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="form-group col-6 p-1 mt-2">
                                                <label>New email address</label>
                                                <a *ngIf="isEmailVerified" class="email-verified">Verified</a>
                                                <a *ngIf="!isEmailVerified" (click)="verifyEmail('email')" class="email-verify">Please verify</a>
                                                <input type="email" class="form-control" placeholder="Enter New email address" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted2 && f2.email.errors }" required>
                                                <div *ngIf="submitted2 && f2.email.errors" class="invalid-feedback">
                                                    <div class="red_alert_msg" *ngIf="f2.email.errors.required">Enter Valid Email Address </div>
                                                </div>
                                                <div *ngIf="showError && f2.email.errors">
                                                    <div class="red_alert_msg" style="margin-top: 0px" *ngIf="f2.email.errors.invalid">Email is required</div>
                                                </div>
                                            </div>
                                            <button class="btn orange white-text save-changes" type="submit">Save
                                                Changes</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="card m-1 col-7">
                                    <div class="card-header">
                                        <h3 class="mb-0">Newsletter</h3>
                                    </div>
                                    <div class="card-body p-2">
                                        <h5>Notify me about new replies
                                            <span class="d-inline">
                                                <div class="form-check form-switch" style="display: inherit;
                                                float: right;">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="flexSwitchCheckChecked" checked>

                                                </div>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Security">
                            <div class="row mt-1 pl-2 mb-0">
                                <h3 class="mb-0 page_heading mt-2">Security</h3>
                                <div class="row">
                                    <form class="mt-3" [formGroup]="changePassword" (ngSubmit)="onChangePassword()">
                                        <div class="card col-8">

                                            <h4 class="mb-0 mt-2">Change Your Password</h4>

                                            <div class="form-group col-6 mt-2 mb-2 p-0">
                                                <label for="confirmPassword">Current
                                                    Password</label>
                                                <div class="input-group password">
                                                    <input id="currentPassword" [type]="fieldTextCurrentPass ? 'text' : 'password'" class="form-control" placeholder="Current Password" name="currentPassword" formControlName="currentPassword" 
                                                    [ngClass]="((changePassword.controls['currentPassword'].touched || changePassword.controls['currentPassword'].dirty) && changePassword.controls['currentPassword'].invalid) ? 'is-invalid' : 'is-valid'"
                                                    required />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text pass-eye">
                                                            <i class="fa" [ngClass]="{
                                                                'fa-eye-slash': !fieldTextCurrentPass,
                                                                'fa-eye': fieldTextCurrentPass
                                                            }" (click)="toggleFieldTextCurrentPass()"></i>
                                                        </span>
                                                    </div>

                                                </div>
                                               
                                                <div class="password-label" *ngIf="((changePassword.controls['currentPassword'].dirty || changePassword.controls['currentPassword'].touched) && changePassword.controls['currentPassword'].invalid) ? 'is-invalid1' : ''">
                                                    <label class="col-10 p-0" [ngClass]="changePassword.controls['currentPassword'].hasError('required')   ? 'text-danger1' : 'd-none'">
                                                      <i style="float: left;" class="material-icons">{{ changePassword.controls['currentPassword'].hasError('required')  ? 'clear' :
                                                        'done' }}</i>
                                                      <small class="error-align">Current Password is required</small>
                                                    
                                                    </label>
                                                    
                                                  </div> 

                                            </div>
                                            <div class="form-group col-6 mt-2 mb-2 p-0">
                                                <label for="newPassword">New
                                                    Password</label>
                                                <div class="input-group password">
                                                    <input id="newPassword" [type]="fieldTextNewPass ? 'text' : 'password'" class="form-control" placeholder="New Password"  formControlName="newPassword" 
                                                    [ngClass]="((changePassword.controls['newPassword'].touched || changePassword.controls['newPassword'].dirty) && changePassword.controls['newPassword'].invalid) ? 'is-invalid' : ''"
                                                     required />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text pass-eye">
                                                            <i class="fa" [ngClass]="{
                                                                'fa-eye-slash': !fieldTextNewPass,
                                                                'fa-eye': fieldTextNewPass
                                                            }" (click)="toggleFieldTextNewPass()"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="pass-ul" *ngIf="((changePassword.controls['newPassword'].dirty || changePassword.controls['newPassword'].touched) && changePassword.controls['newPassword'].invalid) ? '' : 'is-invalid'">
                                                    <ul class="text-left grey-text p-0 ml-3 select-type" style="display:block">
                                                      <li class="select-pass-category"><small>Atleast 8 characters</small></li>
                                                      
                                                      <li class="select-pass-category"><small>One uppercase alphabets</small></li>
                                                      <li class="select-pass-category"><small>Special characters</small></li>
                                                      <li class="select-pass-category"><small>Numbers</small></li>
                                                    </ul>
                                                </div>
                                                <div class="mt-2 password-label" style="position: relative;
                                                bottom: 9px;" *ngIf="((changePassword.controls['newPassword'].dirty || changePassword.controls['newPassword'].touched) && changePassword.controls['newPassword'].invalid) ? 'is-invalid' : ''">
                                                  <label class="col-6 p-0" [ngClass]="changePassword.controls['newPassword'].hasError('required') || changePassword.controls['newPassword'].hasError('minlength')  ? 'text-danger1' : 'text-success'">
                                                    <i class="material-icons">{{ changePassword.controls['newPassword'].hasError('required') ||
                                                        changePassword.controls['newPassword'].hasError('minlength') ? 'clear' :
                                                      'done' }}</i>
                                                    <small class="error-align">At least 8 characters</small>
                                                  </label>
                                                  <label class="col-6 p-0" [ngClass]="changePassword.controls['newPassword'].hasError('required') || changePassword.controls['newPassword'].hasError('hasNumber')  ? 'text-danger1' : 'text-success'">
                                                    <i class="material-icons">{{ changePassword.controls['newPassword'].hasError('required') ||
                                                        changePassword.controls['newPassword'].hasError('hasNumber') ? 'clear' :
                                                      'done' }}</i>
                                                    <small class="error-align">One number</small>
                                                  </label>
                                                  
                                                  <label class="col-6 p-0" [ngClass]="changePassword.controls['newPassword'].hasError('required') || changePassword.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger1' : 'text-success'">
                                                    <i class="material-icons">{{ changePassword.controls['newPassword'].hasError('required') ||
                                                        changePassword.controls['newPassword'].hasError('hasCapitalCase') ? 'clear' :
                                                      'done' }}</i>
                                                    <small class="error-align">One uppercase alphabets </small>
                                                  </label>
                                                  <label class="col-6 p-0" [ngClass]="changePassword.controls['newPassword'].hasError('required') || changePassword.controls['newPassword'].hasError('hasSpecialCharacters') ? 'text-danger1' : 'text-success'">
                                                    <i class="material-icons">{{ changePassword.controls['newPassword'].hasError('required') ||
                                                        changePassword.controls['newPassword'].hasError('hasSpecialCharacters') ? 'clear' :
                                                      'done' }}</i>
                                                    <small class="error-align">Special Character</small>
                                                  </label>
                                                </div>
                                                  
                                               
                                            </div>
                                            <div class="form-group col-6 mt-2 mb-2 p-0">
                                                <label for="confirmPassword">Confirm
                                                    Password</label>
                                                <div class="input-group password">
                                                    <input id="confirmPassword" [type]="fieldTextConfPass ? 'text' : 'password'" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword"
                                                    [ngClass]="((changePassword.controls['confirmPassword'].touched || changePassword.controls['confirmPassword'].dirty) && changePassword.controls['confirmPassword'].invalid) ? 'is-invalid' : ''"
                                                    required />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text pass-eye">
                                                            <i class="fa" [ngClass]="{
                                                                'fa-eye-slash': !fieldTextConfPass,
                                                                'fa-eye': fieldTextConfPass
                                                            }" (click)="toggleFieldTextConfPass()"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="password-label" *ngIf="((changePassword.controls['confirmPassword'].dirty || changePassword.controls['confirmPassword'].touched) && changePassword.controls['confirmPassword'].invalid) ? 'is-invalid' : ''">
                
                
                                                    <label class="col-6 p-0" [ngClass]="changePassword.controls['confirmPassword'].hasError('required') || changePassword.controls['confirmPassword'].hasError('NoPassswordMatch') ? 'text-danger1' : 'text-success'">
                                                      <i class="material-icons">{{ changePassword.controls['confirmPassword'].hasError('required') ||
                                                        changePassword.controls['newPassword'].hasError('NoPassswordMatch') ? 'clear' :
                                                        'done' }}</i>
                                                      <small class="error-align">Password Mismatch</small>
                                                    </label>
                                                  </div>
                                                
                                            </div>
                                            <button class="btn orange white-text save-changes col-3" type="button" (click)="onChangePassword()">Save Changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Payment Methods & Rewards">
                            <div class="row mt-1 mb-0">

                                <div class="row">
                                    <div class="col-3 m-2" *ngIf="!isExtendCreditLimit">
                                        <h3 class="mb-0 page_heading">Credits</h3>
                                        <div class="card p-3 shadow-sm">
                                            <img src="assets/img/use_credit_limit.svg" class="m-auto" width="35%">
                                            <br>
                                            <button (click)="creditLilmitCheck('user-preferences/credit-application')" class="btn orange white-text col-10 m-auto btn-class m-1">Apply for Credit</button>
                                        </div>
                                    </div>
                                    <div class="col-3 m-2" *ngIf="isExtendCreditLimit">
                                        <h3 class="mb-0 page_heading">Credits</h3>
                                        <div class="card p-3 shadow-sm">
                                            <div class="row credit">
                                                <img src="assets/img/use_credit_limit.svg" class="col-6" width="95px" style="width: 95px;">
                                                <div class="col-6 d-inline mt-3">
                                                    <img src="assets/img/user/green_doller.svg" class="m-1" width="8%"><h4 class="mb-0 d-inline">Credit limit:</h4>
                                                    <h3 class="mb-0">{{viewGlobalLimit | number : '1.2-2'}}</h3>
                                                </div>
                                            </div>
                                           
                                           
                                            <button (click)="creditLilmitCheck('user-preferences/credit-application')" class="btn orange white-text col-12 m-auto btn-class m-1">Apply for more Credit</button>
                                        </div>
                                    </div>
                                   
                                    <div class="col-3 m-2" style="opacity: 0.5;">
                                        <h3 class="mb-0 page_heading">Payment Methods</h3>
                                        <div class="card p-3 shadow-sm">
                                            <img src="assets/img/paypal.svg" class="m-auto" width="50%" style="margin: 20px auto !important;">
                                            <br>
                                            <button class="btn orange white-text col-10 m-auto btn-class m-1 disabled">Connect
                                                Now</button>
                                        </div>
                                        <p class="text-center grey-text1">We accept all the following payment methods
                                        </p>
                                        <img src="assets/img/user/amex_icon.svg" class="m-1" width="12%">
                                        <img src="assets/img/user/red_yellow_circul_icon.svg" class="m-1" width="7%">
                                        <img src="assets/img/user/discover_icon.svg" class="m-1" width="16%">
                                        <img src="assets/img/user/visa_icon.svg" class="m-1" width="10%">
                                        <img src="assets/img/user/google-pay-logo.svg" class="m-1" width="10%">
                                        <img src="assets/img/user/Apple-Pay.svg" class="m-1" width="10%">

                                    </div>
                                </div>

                            </div>
                        </mat-tab>

                    </mat-tab-group>

                </div>

            </div>
        </div>
        <div class="row mt-1 mb-2 p-0" style="margin-left: 215px;
        position: fixed;
        bottom: 0;">
            <div class="">
                <p class="dark-grey-text mt-2 pl-2 d-inline"> &copy; {{cYear}} ShipCarte. All Rights Reserved </p>

                <span class="ml-1 mt-1 mb-5 d-inline">
                    <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                        <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                    </a>
                    <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                        <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                    </a>
                    <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                        <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                    </a>
                    <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                        <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                    </a>
                    <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                        <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                    </a>
                </span>
            </div>
        </div>
    </div>
</div>
<app-loader [display]="displayLoader"></app-loader>