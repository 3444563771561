import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboardData(){
    return this.http.get(environment.apiPath + "/api/getUserDashboardData", { withCredentials: true });
  }

  setUserPreferneces(data){
   return this.http.post(environment.apiPath+"/api/setUserDashboardPreference",data,{withCredentials: true});
  }
}
