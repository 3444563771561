import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment  } from './../../../environments/environment.prod';
import { environment  } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SelectCarrierService {
    baseUrl:any;
    private jsonHTTPOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })     
       ,withCredentials: true
    };
    constructor(private http:HttpClient) { }
  
    getStartSummary(quoteID,carrierID,carrier_req_id){
      carrier_req_id = carrier_req_id.split(",")[0];
      const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/sc/quote/cost/breakup/ws/get/by/carrier?quote-id="+quoteID+"&carrier-id="+carrierID+"&carrier-req-id="+carrier_req_id ,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      }));  
    }

    createShipment(carrierQuoteReq,quoteId){
      return this.http.put(environment.apiPath+ "/sc/quote/shpmt/ws/update?quoteId="+quoteId+"&shpmtId="+carrierQuoteReq,{params: {}},this.jsonHTTPOptions).pipe(map(response => {
        return response;
      }));  
    }


    getExtraservicesData(quoteId,action){
      // /sc/quote/extra/services/get/all?quoteId=826f4959-df9f-4f24-a850-1216aa099816&locType=end
      return this.http.get(environment.apiPath+ "/sc/quote/extra/services/get/all?quoteId="+quoteId+"&locType="+action,{ withCredentials: true });
    }
    getAllFailedCarriers(quoteId){
      return this.http.get(environment.apiPath+ "/api/quote/getAllFailedCarriers?quoteId="+quoteId,{ withCredentials: true });

    }
    getcurrencyConversionRate() {
      return this.http.get(environment.apiPath + "/api/currencyConversionRate/get?from=USD&to=CAD" , { withCredentials: true });
    }
}