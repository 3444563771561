import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as data from '../../src/assets/config.json';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Shipcarte';

  closeModal: string;

  config: any;
  constructor(private modalService: NgbModal, private httpClient: HttpClient) {}
  ngOnInit() {
    this.config = data;
    console.log('current', this.config.default.version);
    // checking for update first time after app loads
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
    this.httpClient
      .get<{ version: string }>('/assets/config.json', { headers })
      .subscribe((config) => {
        console.log('available', config);
        if (config.version !== this.config.default.version) {
          location.reload();
          console.log('1reloaded new version');
        }
      });
    //checking for update in every 30min
    setInterval(() => {
      const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');
      this.httpClient
        .get<{ version: string }>('/assets/config.json', { headers })
        .subscribe((config) => {
          console.log(config);
          if (config.version !== this.config.default.version) {
            location.reload();
            console.log('2reloaded new version');
          }
        });
    }, 15 * 60 * 1000);
  }

  triggerModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (res) => {
          this.closeModal = `Closed with: ${res}`;
        },
        (res) => {
          this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
