import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute} from '@angular/router';
import { Router} from '@angular/router';
import { AddFavoriteComponent } from 'src/app/_helpers/popup-dialog/add-favorite/add-favorite.component';
import { EmailDocumentComponent } from 'src/app/_helpers/popup-dialog/email-document/email-document.component';
import { jsPDF } from 'jspdf';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { DocumentService } from './../../services/document.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../../app/services/common.service';
import { DownloadLabelComponent } from './../../_helpers/popup-dialog/download-label/download-label.component';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as html2pdf from 'html2pdf.js'

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  addFav:any;
  // isLabelUrl:SafeResourceUrl;
  emailDoc:any;
  BOLNumber:any;
  quoteId:any;
  displayLoader: boolean;
  declaredValue: any;
  printBOL: boolean;
  ShipmentLabelUrl: any;
  isViewBoL: boolean = false;
  BOLd:any ={
    orderNumber:'',
    shipDate:'',
    customerNumber:'',
    shippercompany:'',
    shipperaddrLine1:'',
    shippercity:'',
    shipperstate:'',
    shipperzip:'',
    consigneecompany:'',
    consigneeaddrLine1:'',
    consigneecity:'',
    consigneestate:'',
    consigneezip:'',
    quoteNumber:'',
    shipmentItemsqty:'',
    shipmentItemslengthType:'',
    shipmentItemsdescription:'',
    shipmentItemstotalWeight:'',
    shipmentItemsfreightClass:'',
    shipmentItemsnmfc:'',
    shipmentItemslength:0,
    shipmentItemsbreadth:0,
    shipmentItemsheight:0,
    shipperAddressfirstName:'',
    shipperAddresslastName:'',
    consigneeAddressfirstName:'',
    consigneeAddresslastName:'',
    brokerName:'',
    Phone:'',
    brokerEmail:'',
  };
  totalItem:any;
  documentId:any;
  orderId:any;
  pdfFile= new jsPDF("p", "mm", "a4");
  resultData:any;
  isCommercialInvoiceGenerated: boolean;
  dynamicDimension ={
    length:0,
    width:0,
    height:0,
    weight:0
  }
  labaelList:any;
  constructor(public commonService:CommonService,private modalService: NgbModal, private toastr: ToastrService, public router: Router, public docService:DocumentService, public route: ActivatedRoute) { }

  ngOnInit(): void {

    this.printBOL = true;
    this.displayLoader = true;
    this.declaredValue = 0;
    this.quoteId = this.route.snapshot.paramMap.get('quoteId');
    this.isCommercialInvoiceGenerated = localStorage.getItem('com_generated_' + this.quoteId) == 'true' ? true :false ;
    
    // this.getBOLNumber();
    // this.isLabelUrl=" ";
    this.getData();
    
    this.BOLd={
      "documentId": "739608cb-5ec9-4315-9e1d-ca392a8e81ed",
      "bolNumber": "BOL7882264117282071297",
      "quoteNumber": "Q-00-02870",
      'accountNum': "22",
      "orderId": "fba900d7-5fb6-440d-b22c-1edc90d3895c",
      "orderNumber": "O-00-01177",
      "shipDate": "2021-08-12",
      "customerNumber": "486",
      "shipmentMode": "Less than Truckload (LTL)",
      "carrier": "XPO",
      "carrierLogo": "https://dev-shipcarte-docs.s3.amazonaws.com/1613057326640-XPO-Logistics_(2)_(1).jpg",
      "shipperAddress": {
          "addressId": null,
          "addressName": "name of address",
          "addrLine1": "null",
          "addrLine2": null,
          "city": "missisuga",
          "state": "USA",
          "country": "canada",
          "zipCode": "L4W 5N2",
          "addrTypeId": null,
          "company": "shipper company",
          "firstName": "John",
          "lastName": "Doe",
          "dept": null,
          "email": "JohnDoe@email.com",
          "phoneNumber": "888 8888 888",
          "alternatePhoneNumber": null,
          "fax": null,
          "hasAddrBook": false,
          "latitude": null,
          "longitude": null
      },
      "consigneeAddress": {
          "addressId": null,
          "addressName": "Name of address",
          "addrLine1": null,
          "addrLine2": null,
          "city": "missisuga",
          "state": "USA",
          "country": "Canada",
          "zipCode": "L4W 5N2",
          "addrTypeId": null,
          "company": "Consignee Company",
          "firstName": "null",
          "lastName": "null",
          "dept": null,
          "email": null,
          "phoneNumber": null,
          "alternatePhoneNumber": null,
          "fax": null,
          "hasAddrBook": false,
          "latitude": null,
          "longitude": null
      },
      "shipmentItems": [
          {
              "quoteItemId": null,
              "packaging": null,
              "qty": 2,
              "totalWeight": 20.0,
              "length": 20.0,
              "breadth": 20.0,
              "height": 20.0,
              "unit": "cm",
              "wghtUnit": "lbs",
              "freightClass": "500",
              "lengthType": null,
              "shipmentItemValueCurrency": "CAD",
              "description": "test",
              "shipmentItemValue": 5.0,
              "nmfc": "test",
              "stackable": true,
              "hazMat": true
          }
      ],
      "brokerName": "brokerName",
      "brokerEmail": "brokerEmail",
      "brokerPhone": "brokerPhone",
      "shipcarteAddress": "8-120 West Beaver Creek Rd, Richmond Hill, ON L4B1L2, Canada",
      "shipcarteEmail": "partners@shipcarte.com",
      "shipcartePhone": "8884248878",
      "shipcarteLogoSmall": "assets/img/shipcarte-logo-350x130.png",
      "shipcarteLogoLarge": "assets/img/shipcarte-logo-350x130.png"
  }
  
  

}

  saveCommercialPdf(){
    let currentcomp = this;
    this.displayLoader=true;
    html2canvas(document.querySelector('#DownloadCommercialPdfData'), {useCORS: false}).then(function(canvas) {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = function () {
        var position = 0;
        let pdf = new jsPDF('p', 'mm');
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
          heightLeft -= pageHeight;
        } 
        var blobData = pdf.output("blob");
        currentcomp.sendCommercialPDFToServer(blobData);
      };
    });
  }

  sendCommercialPDFToServer(pdfContent){
    // console.log( this.displayLoader)
    const formdata: FormData = new FormData();
    var commercialDocumentId = localStorage.getItem("com_documentId_"+this.quoteId);
    formdata.append("documentId", commercialDocumentId);
    
    var date = new Date().valueOf();  
    
    const blobFile = new Blob([pdfContent] , {
      type: 'application/pdf',
    });

    

    var dummyFile = new File([pdfContent], "COM_"+commercialDocumentId+".pdf");
    formdata.append("file", dummyFile);

    this.docService.saveCommercialPdf(formdata).subscribe(result =>{
      this.displayLoader = false;
      this.toastr.success('Commercial invoice PDF Saved Successfully.', '', {
         timeOut: 5000,
      });
    }, error => {
      if(error.status==401){
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
      this.displayLoader = false;
       this.toastr.error('Something Went wrong while saving Commercial Invoice.', 'Please try again.', {
         timeOut: 5000,
       });
      this.displayLoader = false;
    });
  }

  async savePdf(){
    this.displayLoader = true;
    let currentcomp = this;
    // html2canvas(document.querySelector('#content'),{useCORS:false}).then(function(canvas) {
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   let img = new Image();
    //   img.src = canvas.toDataURL('image/png');
    //   img.onload = function () {
    //     var position = 0;
    //     let pdf = new jsPDF('p', 'mm');
    //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //     heightLeft -= pageHeight;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       pdf.addPage();
    //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
    //       heightLeft -= pageHeight;
    //     } 
    //     var blobData = pdf.output("blob");
    //     currentcomp.sendPDFToServer(blobData);
    //   };
    // });
    var element = document.querySelector('#content');
    var opt = {
    margin:       0.1,
    filename:     "BOL_"+currentcomp.quoteId+".pdf",
    image:        { type: 'jpeg', quality: 0.98 },
    html2canvas:  { dpi: 192, scale: 2, letterRendering: true},
    jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true},
    pageBreak:    { mode: 'css', after:'.break-page'}
    };

      // New Promise-based usage:
      let worker = await html2pdf().set(opt).from(element).toPdf().output('blob').then( (data: Blob) => {
        return data
      })
      console.log(worker);
      currentcomp.sendPDFToServer(worker);
  }

  sendPDFToServer(pdfContent){

    const formdata: FormData = new FormData();
    formdata.append("documentId", this.documentId);
    formdata.append("orderId", this.orderId);

    var date = new Date().valueOf();  
    const blobFile = new Blob([pdfContent] , {
      type: 'application/pdf',
    });
    
    var dummyFile = new File([pdfContent], "BOL_"+this.documentId+".pdf");
    formdata.append("file", dummyFile);

    this.docService.savePdf(formdata).subscribe(result =>{
      this.displayLoader = false;
      this.toastr.success('PDF Saved Successfully.', '', {
         timeOut: 10000,
      });
    }, error => {
      if(error.status==401){
        this.router.navigate(['/signin']);
      }
      this.displayLoader = false;
       this.toastr.error('Something Went wrong while saving PDF.', 'Please try again.', {
         timeOut: 10000,
       });
      this.displayLoader = false;
    });

  }

  // getBOLNumber(){
  //   this.docService.generateBOLNumber(this.quoteId).subscribe(result=>{
  //     this.BOLNumber = result['BOLNumber'];
  //   })
  // }

  createCommercialInvoice(){
    this.router.navigate(['/commercial-invoice/'+this.quoteId])
  }
  returnDashboard(){
    this.router.navigate(['/dashboard'])
  }
  getData(){
    this.docService.getData(this.quoteId).subscribe(result=>{
      // console.log('getDatahere',result)
      this.resultData = result;
      
      //Revers shipment items  
      //result['shipmentItems'].reverse();

      this.resultData['shipmentItems'].sort((a,b)=>{
        return a.displayOrder -b.displayOrder
      });
      // console.log(this.resultData['shipmentItems'])

      // this.isCommercialInvoiceGenerated = result['isCommercialInvoiceGenerated'];
      this.isViewBoL = result['isPdfExist'];
      this.orderId = result['orderId'];
      this.documentId=result['documentId']
      this.BOLd['carrier'] = result['carrier']
      this.BOLd['carrierQuoteId'] = result['carrierQuoteId']
      this.BOLd['reference'] = result['customerNumber']
      this.BOLd['orderNumber'] = result['orderNumber'];
      this.BOLd['accountNum'] = result['accountNum']
      this.BOLd['shipmentMode'] = result['shipmentMode']
      this.BOLd['shipperTaxId']=result['shipperTaxId']
      this.BOLd['consigneeTaxId']=result['consigneeTaxId']
      this.BOLd['purchaserAddress']=result['purchaserAddress']
      let shipDate = result['shipDate'].split('-');
      this.BOLd['shipDate'] = shipDate[2] + '/'+ shipDate[1]+'/'+shipDate[0];
      this.BOLd['customerNumber'] = result['customerNumber'];
      this.BOLd['shippercompany'] = result['shipperAddress']['company'];
      this.BOLd['shipperaddrLine1'] = result['shipperAddress']['addrLine1'];
      this.BOLd['shipperaddrLine2'] = result['shipperAddress']['addrLine2'];
      this.BOLd['shipperfullAddr'] = result['shipperAddress']['fullAddr'];

      this.BOLd['shippercity'] = result['shipperAddress']['city'];
      this.BOLd['shipperstate'] = result['shipperAddress']['state'];
      this.BOLd['shipperzip'] = result['shipperAddress']['zipCode'];
      this.BOLd['shipperCountry'] = result['shipperAddress']['country'];

      this.BOLd['consigneecompany'] = result['consigneeAddress']['company'];
      this.BOLd['consigneeaddrLine1'] = result['consigneeAddress']['addrLine1'];
      this.BOLd['consigneefullAddr'] = result['consigneeAddress']['fullAddr'];
      this.BOLd['consigneeaddrLine2'] = result['consigneeAddress']['addrLine2'];
      this.BOLd['consigneecity'] = result['consigneeAddress']['city'];
      this.BOLd['consigneeCountry'] = result['consigneeAddress']['country'];
      this.BOLd['consigneestate'] = result['consigneeAddress']['state'];
      this.BOLd['consigneezip'] = result['consigneeAddress']['zipCode'];
      this.BOLd['quoteNumber'] = result['quoteNumber'];
      // this.BOLd['shipmentItemsqty'] = result['shipmentItems'][0]['qty'];
      // this.BOLd['shipmentItemslengthType'] = result['shipmentItems'][0]['lengthType'];
      this.BOLd['shipmentItemslengthType'] = 'Pallete';
      // this.BOLd['shipmentItemsdescription'] = result['shipmentItems'][0]['description'];
      // this.BOLd['shipmentItemstotalWeight'] = result['shipmentItems'][0]['totalWeight'];
      // this.BOLd['shipmentItemsfreightClass'] = result['shipmentItems'][0]['freightClass'];
      // this.BOLd['shipmentItemsnmfc'] = result['shipmentItems'][0]['nmfc'];
      
      // console.log(this.BOLd['shipmentItemslengthType'])
        this.dynamicDimension ={
          length:0,
          width:0,
          height:0,
          weight:0
        }
        this.totalItem=0
     for(let i in result['shipmentItems']){    
       this.totalItem +=1
       if( result['shipmentItems'][i] && result['shipmentItems'][i].unit=='cm'){
        result['shipmentItems'][i].length=result['shipmentItems'][i].length*0.39;
        result['shipmentItems'][i].totalWeight=result['shipmentItems'][i].totalWeight*2.20;
        result['shipmentItems'][i].height=result['shipmentItems'][i].height*0.39;
        result['shipmentItems'][i].breadth=result['shipmentItems'][i].breadth*0.39;
        this.declaredValue += result['shipmentItems'][i]['shipmentItemValue'] * result['shipmentItems'][i]['qty'];
        this.dynamicDimension['length'] += result['shipmentItems'][i].length;
        this.dynamicDimension['width'] += result['shipmentItems'][i].breadth;
        this.dynamicDimension['height'] += result['shipmentItems'][i].height;
        this.dynamicDimension['weight'] += result['shipmentItems'][i].totalWeight * result['shipmentItems'][i]['qty'];
      }else{
        this.dynamicDimension['length'] += result['shipmentItems'][i].length;
        this.dynamicDimension['width'] += result['shipmentItems'][i].breadth;
        this.dynamicDimension['height'] += result['shipmentItems'][i].height;
        this.dynamicDimension['weight'] += result['shipmentItems'][i].totalWeight * result['shipmentItems'][i]['qty'];
        this.declaredValue += result['shipmentItems'][i]['shipmentItemValue'] ;
      }
     }      
      this.BOLd['shipperAddressfirstName'] = result['shipperAddress']['firstName'];
      this.BOLd['shipperAddresslastName'] = result['shipperAddress']['lastName'];
      this.BOLd['consigneeAddressfirstName'] = result['consigneeAddress']['firstName'];
      this.BOLd['consigneeAddresslastName'] = result['consigneeAddress']['lastName'];
      this.BOLd['brokerName'] = result['brokerName'];
      this.BOLd['Phone'] = result['brokerPhone'];
      this.BOLd['brokerEmail'] = result['brokerEmail'];
      //this.BOLd['shipcarteLogoSmall']= result['shipcarteLogoSmall'];
      this.BOLd['carrierLogo'] = result['carrierLogo'];
      // if(this.BOLd['shipmentMode']=='Courier'){
      //   this.getShipmentLabelUrl();
      // }
      if(!localStorage.getItem("fav_"+this.quoteId)){
        this.dialogOpen('addFav','');
      }
      var self = this;
      setTimeout(function(){
        if(result){
          //self.savePdf();
          if( self.isCommercialInvoiceGenerated ){
           self.saveCommercialPdf();
          }
          else{
            self.displayLoader=false;
          }
        }else{
          self.displayLoader = false;
        }
      },3000);
    
    }, error => {
      
      if(error.status==401){
        this.router.navigate(['/signin']);
      }

      this.displayLoader = false;
      
      this.toastr.error(error['error']["message"],'Please try again.', {
        timeOut: 10000,
      });
      this.displayLoader = false;

    });
  }

  dialogOpen(modelToOpn,action){

    if (modelToOpn==="addFav") {
      const modalRef = this.modalService.open(AddFavoriteComponent, {size: 'lg',centered: false,backdrop: true,animation: true, windowClass: 'my-custom-modal3'});   
        this.router.events.subscribe((val) => {
          modalRef.close();
        });   
         modalRef.componentInstance.quoteId = this.quoteId;
         modalRef.componentInstance.orderId = this.orderId;
         modalRef.result.then((result) => {
          if(result['success'] == true){
            this.toastr.success('Order saved as a favorite.', 'Success', {
              timeOut: 30000,
           });
           localStorage.setItem("fav_"+this.quoteId, "true");
          }
      });
    }
    if (modelToOpn==="emailDoc") {
      if(localStorage.getItem("com_documentId_"+this.quoteId) == null  && action!= "bol"){
        this.toastr.error('First create commercial invoice.', 'Please', {
          timeOut: 30000,
       });
       return;
      }
    const modalRef = this.modalService.open(EmailDocumentComponent, {size: 'lg',centered: false,backdrop: true,animation: true, windowClass: 'my-custom-modal3'}); 
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.componentInstance.documentId =  action=="commercial" ? localStorage.getItem("com_documentId_"+this.quoteId):  this.documentId ;
    modalRef.componentInstance.action = action;
  
    modalRef.result.then((result) => {
        if(result.success){
          this.toastr.success('Email sent successfully.', 'Success', {
             timeOut: 10000,
          });
        }else{
          this.toastr.error('Error, While sending email.', 'Oops', {
             timeOut: 10000,
          });
        }
     });
    }
  }
  
   download() {
      let compData = this;
      // html2canvas(document.querySelector('#content'),{ useCORS: false } ).then(function(canvas) {
      //   var imgWidth = 210;
      //   var pageHeight = 295;
      //   var imgHeight = canvas.height * imgWidth / canvas.width;
      //   var heightLeft = imgHeight;
      //   let img = new Image();
      //   img.src = canvas.toDataURL('image/png');
      //   img.onload = function () {
      //     var position = 0;
      //     let pdf = new jsPDF('p', 'mm');
      //     pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
      //     heightLeft -= pageHeight;
      //     while (heightLeft >= 0) {
      //       position = heightLeft - imgHeight;
      //       pdf.addPage();
      //       pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
      //       heightLeft -= pageHeight;
      //     }    
      //     pdf.save("BOL_"+compData.quoteId+".pdf");
      //   };
      // });
      var element = document.querySelector('#content');
      var opt = {
      margin:       0.1,
      filename:     "BOL_"+compData.quoteId+".pdf",
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { dpi: 192, scale: 2, letterRendering: true},
      jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait', compressPDF: true},
      pageBreak:    { mode: 'css', after:'.break-page'}
      };

        // New Promise-based usage:
      html2pdf().set(opt).from(element).save();
}

print(doc) {

  if(doc=='bol'){
    this.printBOL = true;
  }else{
    if(localStorage.getItem("com_documentId_"+this.quoteId) == null){
      this.toastr.error('First create commercial invoice.', 'Please', {
        timeOut: 30000,
     });
     return;
    }
    this.printBOL = false;
  }
  setTimeout(() => {
    window.print();
  }, 1000);
}


downloadCommercialInvoice(){
  if(localStorage.getItem("com_documentId_"+this.quoteId) == null){
    this.toastr.error('First create commercial invoice.', 'Please', {
      timeOut: 30000,
   });
   return;
  }
  let compData = this;
  var doc = document.getElementById('DownloadCommercialPdfData')
  html2canvas(doc, {useCORS: false}).then(function(canvas) {
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    let img = new Image();
    img.src = canvas.toDataURL('image/png');
    img.onload = function () {
      var position = 0;
      let pdf = new jsPDF('p', 'mm');
      pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight + 15);
        heightLeft -= pageHeight;
      }    
      pdf.save("COM_"+compData.quoteId+".pdf");
    };
  });
}

getShipmentLabelUrl(){
  this.displayLoader = true;
  this.commonService.getShipmentLabelUrls(this.orderId).subscribe(result =>{
     this.labaelList=result
     if(this.labaelList['link'].length == 0 ){
      this.toastr.error(result['error'], '', {
        timeOut: 5000,
      });
      this.displayLoader = false;
      return
     }
     if(this.labaelList['link']==null){
      this.toastr.error(result['error'], 'Please try again after sometime ', {
        timeOut: 5000,
      });
      this.displayLoader = false;
      return
     }
      if(this.labaelList['link'].length <=1 && this.labaelList['link']!=null){
        // this.isLabelUrl=this.sanitizer.bypassSecurityTrustResourceUrl(result['link'])
        window.open(result['link'],'_blank').focus(); 
      }else if(this.labaelList['link'].length >1){
          this.open_modal()
          // console.log(this.labaelList)
        }
      else {
        this.toastr.error(result['error'], 'Please try again after sometime ', {
          timeOut: 5000,
        });
      }
      this.displayLoader = false;
  }, (error) => {
    this.displayLoader = false
    if (!error || error['status'] !== 200) {
      this.toastr.error('No Data Found', 'Please try again after sometime ', {
         timeOut: 5000,
       });
    }else{
      window.open(error['error']['text'],'_blank').focus(); //This code here due to invalid json in response
    }
  });

}
open_modal() {
  const modalRef = this.modalService.open(DownloadLabelComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal4' });
  this.router.events.subscribe((val) => {
    modalRef.close();
});
  modalRef.componentInstance.data = this.labaelList;
   modalRef.result.then((result) => {
  });

}
  viewBoL(){
    this.isViewBoL = true;
    this.savePdf();
  }
}

