// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // apiPath: 'https://api.shipcarte.com/shipcarte',
  // apiPath:'http://localhost:9090/shipcarte',
  apiPath:"http://157.230.14.52:9090/shipcarte",
  GooglePlacesApiKey:'AIzaSyB6UrbHNiACEp5nHr9sRGlb8CBCN152t-0',
  // apiAuthURL:'https://api.shipcarte.com/shipcarte',
  // apiAuthURL:'http://localhost:9090/shipcarte',
  apiAuthURL:"http://157.230.14.52:9090/shipcarte",

  Uiversion: '2.24',
  Cyear:"2022"

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
