<div class="container p-0">
    <div class="mt-5 p-0 mb-0">
        <h3 class="d-inline m-2 mb-0 ">Select From All Custom Packages</h3>
        <button type="button" class="close d-inline" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">
               <span class="material-icons">
               close
               </span>
            </span>
          </button>
    </div>
    <hr class="m-0">
    <div class="row mt-3 p-2 ">
        <div class="col-1">
            <div class="custom_check_box">
                <label class="container ">
                    <input type="checkbox" >
                    <span class="checkmark"></span>
                    
                </label>
            </div>
        </div>
        <div class="col-5">
            <img class="mr-2" src="../../../assets/img/package.svg">
                    <span>Amazing Parcel Package</span>
        </div>
        <div class="col-4">
            <img class="mr-2" src="../../../assets/img/in.svg" width="20px">
            <span>L10,W10,H10</span>
        </div>
        <div class="col-2">
            <span>5</span>
            <img class="ml-2" src="../../../assets/img/lbs.svg" width="20px">
        </div>
    </div>
</div>
