<div class="container">
    <div class="row">
        <h3 class=" pt-4 m-2 d-inline">View Receipt 
            <span class="blue-text ml-3 cursor-p"  (click)="printComponent()">Print</span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">
                   <span class="material-icons">
                   close
                   </span>
                </span>
              </button>
        </h3>
        
        <hr>
        <div id="component" class=" printable printableInvoice">
        <h3 class="mb-0 text-center">INVOICE#{{data && data?.invoiceNumber}}</h3>
        <h4 class="text-center">Receipt#</h4>
        <div class="row m-auto">
            <div class="col mb-3 text-dark">
                Amount Paid
            </div> 
            <div class="col text-right mb-3">
                ${{data['amountPaid']!= null ? data['amountPaid'] : ''}} {{data['currency'] ? data['currency'] : ''}}
            </div>
        </div>
        <div class="row m-auto">
            <div class="col mb-3  text-dark">
                Date Paid
            </div> 
            <div class="col text-right mb-3">
                <!-- {{data['orderDate'] ? (data['orderDate'] | date: 'dd/mm/yyyy') : ''}}  -->
                {{data && formattedDate ? formattedDate : ''}}
            </div>
        </div>
        <div class="row m-auto text-dark">
            <div class="col mb-3">
                Payment Method
            </div> 
            <div class="col text-right mb-3">
                <img *ngIf="data['paymentMethod'] && data['paymentMethod'] != 'CREDITLIMIT'" class="" src="assets/img/invoice/visa.svg"> {{data['paymentMethod']== 'CREDITLIMIT' ? data['paymentMethod'] : ''}} 
            </div>
            
        </div>
        <h3 class="text-dark ml-3">Summery</h3>
        <div class="row bg-light m-auto p-2">
            <h4 class="mb-0 m-2">Order No.</h4>
            <hr>
            <div class="row m-auto">
                <div class="col"> {{data['orderNumber'] ? data['orderNumber'] : ''}} </div>
                <div class="col text-right">${{data['charged'] ? data['charged'] : ''}}{{data['currency'] ? data['currency'] : ''}}</div>
            </div>
            <hr>
            <div class="row m-auto">
                <div class="col">Amount paid</div>
                <div class="col text-right">${{data['amountPaid']!= null ? data['amountPaid'] : ''}} {{data['currency'] ? data['currency'] : ''}}</div>
            </div>
        </div>
        </div>

    </div>
</div>
