import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../services/common.service'
import {PriceBrackupService} from '../../../services/PRiceBrackup/price-brackeup.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-price-summary',
  templateUrl: './price-summary.component.html',
  styleUrls: ['./price-summary.component.css']
})
export class PriceSummaryComponent implements OnInit {

  priceSummary:any;
  charges=[];
  weight='';
  weightUnit='';

  constructor( public common :CommonService , public price :PriceBrackupService,public activeModal: NgbActiveModal) { 
    // this.getPriceData();
  }

  ngOnInit(): void {
    this.priceSummary=this.common.priceSummaryData;
    this.charges=this.common.Charges;
    this.weight=this.common.orderWeight;
    this.weightUnit=this.common.weightUnit;
  }

  // getPriceData(){
  //   this.price.getPRicebrackup(this.common.quoteId).subscribe(response =>{
  //     console.log(response);
  //     this.priceSummary=response;

  //     if(this.priceSummary['charges']){
       
  //       this.priceSummary.charges.forEach((item,index) => {
  //         for (var key of Object.keys(item)) {
  //         this.charges.push([key, item[key]])
  //       }
  //       });
        
  //       console.log(this.charges);
  //    }
  //   })
  // }

  closeModal() { this.activeModal.close(); }
}
