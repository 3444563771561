<div class="container" style="top: 15px;position: absolute;padding: 15px;">
    <span>
         <h4 style="font-size: 16px !important;">Calculate Distance
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()">
                    close
                    </span>
                </span>
             </button>
         </h4>
    </span>
    <div class="row" *ngIf="!calculated" style="display: block;">
        <form >
            <div class="form-group logistics_section mt-5">
                <label for="exampleInputEmail1" class="d-block">
                    <span class="float-left">
                        Origin
                        <img src="assets/img/quotes/Switch-Vertical.svg" class="m-2 text-center"
                            width="12%">
                    </span>
                    <span class="float-right logistics_text_icon_box">
                        <span>Google Maps</span>
                        <img src="assets/img/quotes/google-maps.svg"
                            class="location_icon m-1 text-center" width="7%">
                            <span class="d-inline">
                                <div class="form-check form-switch" style="display: inherit;
                                margin-top: 7px;">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                    
                                </div>
                            </span>
                            <span class="grey-text float-right" style="margin-top: 4%;">Address Book</span>
                    </span>
                </label>
                <input #addressInput class="form-control" placeholder="Search Origin Address" ngx-google-places-autocomplete   #placesRef="ngx-places"  [value]="" (onAddressChange)="originChange($event)" />
                <span class="red_alert_msg" *ngIf="showerror">
                    Please select specific Origin address type!
                </span>
            </div>
            <div class="form-group logistics_section mt-3">
                <label for="exampleInputEmail1" class="d-block">
                    <span class="float-left">
                        Destination
                        <img src="assets/img/quotes/Switch-Vertical.svg" class="m-2 text-center"
                            width="12%">
                    </span>
                    <span class="float-right logistics_text_icon_box">
                        <span>Google Maps</span>
                        <img src="assets/img/quotes/google-maps.svg"
                            class="location_icon m-1 text-center" width="7%">
                            <span class="d-inline">
                                <div class="form-check form-switch" style="display: inherit;
                                margin-top: 7px;">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                    
                                </div>
                            </span>
                            <span class="grey-text float-right" style="margin-top: 4%;">Address Book</span>
                    </span>
                </label>
                <input #addressInput class="form-control" placeholder="Search Destination Address" ngx-google-places-autocomplete   #placesRef="ngx-places"  [value]="" (onAddressChange)="destinationchange($event)" placeholder="Search Destination Address" />
                <div class="red_alert_msg" *ngIf="showShipperWarningAdd">
                    Please select specific Destination address type!
                </div>
            </div>
        </form>
        <div class="text-right mt-5">
            <button type="button" class="btn faint-grey col-4" style="box-shadow: none;text-transform: capitalize;" (click)="clearval()">Reset</button>
            &nbsp;&nbsp; <button type="submit" class="btn orange text-white col-4" style="box-shadow: none;text-transform: capitalize;" (click)="calculateDistance()"  >Get Distance</button>
        </div>
    </div>
    <div class="row" *ngIf="calculated">
        <div class="col-6 shipment-summary-right">
            <div class="history-tl-container">
                <ul class="tl">
                    <li class="tl-item" ng-repeat="item in retailer_history">
                        <div class="item-title"><span class="text-dark">Origin Address:</span>
                            
                        </div>
                        <div class="item-detail">
                            <div class="inner-sec">
                                <div class="white-bg " style="list-style-type: none;" >
                                    <div class="text-dark">{{fromorigin}}</div>
                                    <div class="text-grey mt-1">Distance</div>
                                    <div class="text-dark">{{distancecal}}</div>
                                    <div class="text-grey mt-1">Duration</div>
                                    <div class="text-dark">{{durationcal}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="tl-item" ng-repeat="item in retailer_history">
                        <div class="item-title">
                            <span class="text-dark">Destination Address</span>
                        </div>
                        <div class="item-detail">
                            <div class="inner-sec">
                                <div class="white-bg" style="list-style-type: none;">
                                    <div class="text-dark">{{todest}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="text-right mt-2">
                <button type="button" class="btn faint-grey col-4" style="box-shadow: none;text-transform: capitalize;" (click)="back()" >Back</button>
                &nbsp;&nbsp; <button type="submit" class="btn orange text-white col-4" style="box-shadow: none;text-transform: capitalize;" >Done</button>
            </div>
        </div>
        <div class="col-6 p-0">
            <agm-map [latitude]="latlng[0][0]" [longitude]="latlng[0][1]" [fitBounds]="false" [zoom]="zoom" #AgmMap>
                <!-- <agm-direction [origin]="origin" [destination]="destination"></agm-direction> -->
                    <agm-marker *ngFor="let coordinate of latlng; let i=index" [latitude]="coordinate[0]" [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
                <!-- <agm-marker  [latitude]="21.1458004" [longitude]="79.0881546" [agmFitBounds]="true"></agm-marker> -->

                <!-- <agm-polyline [visible]="false" [strokeWeight]="3" [strokeColor]="'#07b57a'">
                    <agm-polyline-point [latitude]="21.1458004" [longitude]="79.0881546">
                    </agm-polyline-point>
                </agm-polyline> -->
            </agm-map>
            
        </div>
    </div>
</div>