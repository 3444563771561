<!DOCTYPE html>
<html>

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">

    <title></title>


    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css" />
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css" />
</head>

<body>
    <div id="body-pd" class="body-pd {{siderbarToggle}}">
        <div class="l-navbar col1" id="nav-bar">
            <nav class="nav">
                <div>
                    <a (click)="redirectToDhashboard()" class="nav_logo">
                        <img class="imgExtended imgWidth" src="./../../assets/img/sidebar/ShipCarte-Logo.svg">
                        <img class="imgCollapsed" src="./../../assets/img/sidebar/BLACK.png" style="width:4%;">

                    </a>
                    <span class="version">Version: {{webVersion}}</span>
                    <div class="nav_list">
                        <div *ngFor="let nav of navLeftObject">
                            <a title="{{nav.title}}" href="{{nav.href}}" class="nav_link "
                                [ngClass]="nav.href == href ? 'active': '' ">
                                <span class="icon-box"><img
                                        src="./../../assets/img/sidebar/{{nav.styleCode}}.svg"></span>
                                <span class="nav_name">{{nav.label}}</span>
                            </a>
                        </div>


                    </div>
                </div>
                <div>
                    <a href="javascript:void(0)" class="nav_link">
                        <span class="icon-box"><img src="./../../assets/img/sidebar/setting.svg"></span>
                        <span class="nav_name">Settings</span>
                    </a>
                    <a class="nav_link" style="cursor: pointer;" (click)="logout()">
                        <span class="icon-box">
                            <img src="./../../assets/img/sidebar/log_out.svg">
                        </span>
                        <span class="nav_name">Log Out</span>
                    </a>
                </div>

            </nav>
        </div>


        <div class="top_header" style="float:none;">
            <!-- <div class="header_toggle"> <i class='bx bx-menu' id="header-toggle"></i> </div> -->
            <div class="header_toggle_desk" (click)="toggleSidebar()">
                <img id="header-toggle"
                    src="../assets/img/sidebar/toggle.svg">
            </div>
            <div class="header_toggle_mobile" placement="bottom" popoverClass="popover_sidebar" [ngbPopover]="popSidebar">
                <img id="header-toggle"
                    src="../assets/img/sidebar/toggle.svg">
            </div>
            <div class="header-btn-box"><button type="button" class="btn btn-warning" style="cursor: pointer;"
                    placement="bottom" popoverClass="popover_quick" [ngbPopover]="popQuickAction">Quick Actions</button>
            </div>
            <div class="header-btn-box plus"><button type="button" class="btn btn-warning" style="cursor: pointer;"
                placement="bottom" popoverClass="popover_quick" [ngbPopover]="popQuickAction">+</button>
            </div>
            <div class="available_credit_box">
                <img src="./../../assets/img/sidebar/green_doller.svg">
                <span class="credit_heading">Available Credit :</span>
                <span class="credit_count"> ${{creditLimit && creditLimit.availableCreditLimit ?
                    (creditLimit.availableCreditLimit| number : '1.2-2') : (0 | number : '1.2-2')}} CAD</span>
            </div>

            <div class="header_search_box"><input
                    placeholder="Search by BOL, Tracking, Order Number, or Customer Number…" type="search" id="gsearch"
                    name="gsearch">
            </div>

            <div class="support_ticket_box">
                <span class="support-tick"><img class="ticket_icon" src="./../../assets/img/sidebar/support_ticket_icon.svg"> Support
                    Ticket</span>
                <span><img src="./../../assets/img/sidebar/question_circle.svg" style="cursor: pointer;"
                        placement="bottom" popoverClass="popover_help" [ngbPopover]="popHelp"></span>
                <span><img src="./../../assets/img/sidebar/notification{{notificationObject && notificationObject?.unreadCount? '': 'nomsg'}}.svg" (click)="getNotifications()"
                        style="cursor: pointer;" placement="bottom" popoverClass="popover_notification"
                        [ngbPopover]="popNotification"></span>
                <span class="profile_img" (click)="redirect('user-preferences')">
                    <img width="35" class="img-circle"
                        src="{{userData && userData.profile_image ? userData.profile_image : './../../assets/img/sidebar/avatar5.svg'}}"
                        alt="">
                </span>



            </div>



        </div>
    </div>

</body>

</html>
<ng-template #popNotification>
    <div class="bg-light p-3 f-14">
        Notification
        <button type="button" class="close float-right ">
            <span aria-hidden="true">
                <span class="material-icons">
                    close
                </span>
            </span>
        </button>
    </div>
    <div class="p-3 ">
        <div class="card p-2" *ngIf="notificationStatus">
            <div class="row mb-0">

                <div class="col-12">

                    <div class="f-11">
                        {{notificationStatus}}

                    </div>

                </div>
            </div>

        </div>
        <div class="card p-2" *ngFor="let noti of notificationObject['notifications']">
            <div class="row mb-0">
                <div class="col-2">
                    <img src="./../../assets/img/sidebar/note1.svg">
                </div>
                <div class="col-10">
                    <div class="grey-text f-11">
                        <!-- December 12, 2019 -->
                        {{noti.createdDate}}
                    </div>
                    <div class="f-11">
                        {{noti.title}}
                        <br>
                        {{noti.message}}
                    </div>

                </div>
            </div>

        </div>

    </div>
</ng-template>
<ng-template #popHelp>
    <div class="bg-light p-3 f-14">
        Help
        <button type="button" class="close float-right ">
            <span aria-hidden="true">
                <span class="material-icons">
                    close
                </span>
            </span>
        </button>
    </div>
    <div class="p-3">
        <div class="row op-05 cursor-p">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help1.svg">
            </div>
            <div class="col-10">
                Appendix
            </div>
        </div>
        <div class="row cursor-p" (click)="redirectToResources(0)">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help2.svg">
            </div>
            <div class="col-10">
                VIDEO TUTORIALS
            </div>
        </div>
        <div class="row cursor-p" (click)="redirectToResources(1)">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help3.svg">
            </div>
            <div class="col-10">
                FAQ
            </div>
        </div>

        <div class="row cursor-p" (click)="redirectToResources(2)">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help4.svg">
            </div>
            <div class="col-10">
                O-BOOK
            </div>
        </div>
        <div class="row cursor-p op-05">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help5.svg">
            </div>
            <div class="col-10">
                Support
            </div>
        </div>
        <div class="row cursor-p op-05">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help6.svg">
            </div>
            <div class="col-10">
                Create a Ticket
            </div>
        </div>
        <div class="row cursor-p op-05">
            <div class="col-2">
                <img src="./../../assets/img/sidebar/help7.svg">
            </div>
            <div class="col-10">
                Leave a Feedback
            </div>
        </div>
        <a style="position: absolute;
        bottom: 10px;" class="blue-text">Shipcarte.com</a>
    </div>
</ng-template>
<ng-template #popQuickAction>

    <div class="p-3">
        <div class="f-14">
            Quick Actions
            <button type="button" class="close float-right ">
                <span aria-hidden="true">
                    <span class="material-icons">
                        close
                    </span>
                </span>
            </button>
        </div>
        <div class="row mt-3">
            <div (click)="redirectToGetQuote()" class="orange-text" style="cursor: pointer;">
                Get a Quote &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Generate a new quote
            </div>
        </div>
        <div class="row" style="opacity: 0.5;">
            <div class="orange-text" style="cursor: pointer;">
                Create Bill of Lading &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Save time and use a bill of lading template
            </div>
        </div>
        <div class="row" style="opacity: 0.5;">
            <div class="orange-text" style="cursor: pointer;">
                Create Commercial Invoice &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Save time and use a commercial invoice template
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;" (click)="open_modal('freightmodal')">
                Freight Class Calculator &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Calculate to find your freight class
            </div>
        </div>
        <div class="row" >
            <div class="orange-text" style="cursor: pointer;" (click)="open_modal('dimensionmodal')">
                Dimensional Weight Calculator &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Calculate dimensional weight
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;" (click)="open_modal('distancemodal')">
                Distance Calculator &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Find the distance of the location you wish to deliver too
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;" (click)="open_modal('zipmodal')">

                Find Zip/Posatal Codes &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Find zip/postal codes of locations
            </div>
        </div>
        <div class="row" style="opacity: 0.5;">
            <div class="orange-text" style="cursor: pointer;">

                Supplies Request &nbsp; <img src="./../../assets/img/sidebar/left-arrow.svg">
            </div>
            <div>
                Order supplies from our partners
            </div>
        </div>
    </div>
</ng-template>
<ng-template #popSidebar>
    
    <app-leftsidebar></app-leftsidebar>
   
</ng-template>