import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserPreferencesTermsComponent } from 'src/app/_helpers/popup-dialog/user-preferences-terms/user-preferences-terms.component';
import { UserpreferencesService } from './../services/userpreferences.service';
import { ToastrService } from 'ngx-toastr';
import { UserPreferencesEmailComponent } from '../_helpers/popup-dialog/user-preferences-email/user-preferences-email.component';
import { InfoComponent } from 'src/app/_helpers/popup-dialog/common/info/info.component';
import { CommonService } from './../services/common.service';
import { ClipboardService } from 'ngx-clipboard';
import { CustomValidators } from './../custom-validators';
import { SignupService } from './../services/sign-up/signup.service';
import { environment } from './../../environments/environment';
@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.css']
})
export class UserPreferencesComponent implements OnInit {
  matchPwds: boolean;
  fieldTextNewPass: boolean;
  fieldTextConfPass: boolean;
  fieldTextCurrentPass: boolean;
  personalDetailsForm: FormGroup;
  addressDetailsForm: FormGroup;
  changeEmailAddress: FormGroup;
  submitted: boolean;
  changePassword: FormGroup;
  submitted1: boolean;
  submitted2: boolean;
  submitted3: boolean;
  displayEditForm: boolean;
  displayEditAddressForm: boolean;
  userprefrenceObj: any;
  selectedFile: any;
  image: any;
  loggedUserId: any;
  isEmailVerified: boolean = false;
  isMobileVerified: boolean = false;
  displayLoader = false;
  displayErrorMessage = true;
  errorMessage = '';
  showError = false;
  verifyMobileError = false;
  mobileError = false;
  userAddressData: any;
  viewGlobalLimit:any;
  content: any = 'GC5245';
  isExtendCreditLimit:boolean = false;
  countryStateListObj:any;
  countryListData:any;
  activeEmailId: any;
  cYear:any;

  constructor(private fb: FormBuilder,private clipboardApi: ClipboardService, private router: Router, public formBuilder: FormBuilder, private modalService: NgbModal, public userpreferencesService: UserpreferencesService, private toastr: ToastrService, public commonService: CommonService,public signupService:SignupService) 
  { 
    this.changePassword = this.createSignupForm();
  }
  
  ngOnInit(): void {
    this.cYear =  environment.Cyear;
    //this.userAddressData['company'] = '';
    this.loggedUserId = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.activeEmailId = this.loggedUserId['email'];
    this.getUserPreferencesData(this.loggedUserId['uuid']);
    
    this.personalDetailsForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      workPhone: ['', [Validators.required,Validators.pattern("^([+]91|[+]1)?s*([0-9]{10})$")]],
      Extension: ['']
    });

    this.addressDetailsForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      streetAddress: ['', Validators.required],
      suite: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.changeEmailAddress = this.formBuilder.group({
      email: ['', Validators.required],
    });
    // this.changePassword = this.formBuilder.group({
    //   currentPassword: ['', Validators.required],
    // //  newPassword: ['', Validators.required],
    //   confirmPassword: ['', Validators.required]

    // });
    this.isExtendCreditLimit = false;
    this.getCreditApplyLimitDetails();
    this.signupService.getCountryList().subscribe(result =>{
      console.log(result);
      
      if (result['success'].length) {
      this.countryListData = result['success'];
      this.countryStateListObj = this.countryListData[0]['provinceState'];      
      this.addressDetailsForm.controls['country'].setValue(this.countryListData[0].countryName);
      this.addressDetailsForm.controls['state'].setValue(this.countryListData[0].provinceState[0]['name']);
      }
    })
  }
  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        currentPassword: [
          null,
          Validators.compose([ Validators.required])
        ],
        newPassword: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator1
      }
    );
  }
  
  getCreditApplyLimitDetails() {
    this.displayLoader = true;
    this.commonService.getCreditData().subscribe(result => {
      this.displayLoader = false;
      if (result['availableCreditLimit']) {
        this.viewGlobalLimit = result['availableCreditLimit'];
        this.isExtendCreditLimit = true;
      }else{
        this.viewGlobalLimit = 0.00;
        this.isExtendCreditLimit = false;
      }
    }, (error) => {
      this.viewGlobalLimit = 0.00;
      this.displayLoader = false;
      this.isExtendCreditLimit = false;
    });

  }

  getAddressData(addressId) {
    if (!addressId) return;

    this.displayLoader = true;
    this.userpreferencesService.getAddressData(addressId).subscribe(result => {
      this.displayLoader = false;
      if (result['data']) {
        this.userAddressData = result['data'];
        this.addressDetailsForm.controls['companyName'].setValue(this.userAddressData['company']);
        this.addressDetailsForm.controls['streetAddress'].setValue(this.userAddressData['addrLine1']);
        this.addressDetailsForm.controls['suite'].setValue(this.userAddressData['addrLine2']);
        this.addressDetailsForm.controls['city'].setValue(this.userAddressData['city']);
        
        this.addressDetailsForm.controls['zip'].setValue(this.userAddressData['zipCode']);
        this.addressDetailsForm.controls['country'].setValue(this.userAddressData['country']);
        this.updateStateList(this.userAddressData['country']);
        this.addressDetailsForm.controls['state'].setValue(this.userAddressData['state']);

        // this.personalDetailsForm
      }
    }, (error) => {
      this.displayLoader = false;
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }

    })
  }
  copyText() {

    if (this.content) {
      this.clipboardApi.copyFromContent(this.content);
      this.toastr.success('Referral code is copied!');
    } else {
      this.toastr.error('Something went wrong.');
    }

  }
  getUserPreferencesData(userId) {

    this.userpreferencesService.getData(userId).subscribe(result => {
      if (result['data']) {
        this.userprefrenceObj = result['data'];
        
        let fullName = (this.userprefrenceObj['name'] == null ) ? '' : this.userprefrenceObj['name'] + ' ' + (this.userprefrenceObj['lastName'] == null ) ? '' : this.userprefrenceObj['lastName'] ;
        this.personalDetailsForm.controls['firstName'].setValue(fullName);
        this.personalDetailsForm.controls['workPhone'].setValue(this.userprefrenceObj['phoneNumber']);
        this.image = this.userprefrenceObj['profile_image'];
        this.getAddressData(this.userprefrenceObj['defaultAddrId']);
      }
    }, (error) => {

      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }

    });

  }
  onCancelPersonalDetails() {
    this.displayEditForm = false;
  }
  onCancelAddressDetails() {
    this.displayEditAddressForm = false;
  }

  onSavePersonalDetails() {
    
    console.log('personal details form',this.personalDetailsForm.status)
    
    if (this.userprefrenceObj['phoneNumber'] != this.personalDetailsForm.value.workPhone && !this.isMobileVerified) {
      this.toastr.error('Please Verify your number first.', 'error', {
        timeOut: 5000,
      });
      return;
    }

    this.submitted = true;
    if (this.personalDetailsForm.invalid) {     
      return;
    }
    this.displayEditForm = false;
   

    let inputData = {
      email: this.loggedUserId['email'],
      firstName: this.personalDetailsForm.value.firstName,
      lastName:  this.personalDetailsForm.value.lastName,
      loggedUserId: this.loggedUserId['uuid'],
      phoneNumber: this.personalDetailsForm.value.workPhone,
      Extension:this.personalDetailsForm.value.Extension,
      timeZone: null,
      uuid: this.loggedUserId['uuid'],
    };

    this.userprefrenceObj['name'] = inputData["firstName"];
    this.userprefrenceObj['lastName'] = inputData["lasttName"];

    //this.userAddressData['company'] = inputData["companyName"];

    this.displayLoader = true;

    this.userpreferencesService.updateProfile(inputData).subscribe(result => {
      
      this.displayLoader = false;
      
      this.userprefrenceObj['name'] = inputData["firstName"];
      this.userprefrenceObj['lastName'] = inputData["lastName"];
      this.userprefrenceObj['company'] = inputData["companyName"];
      this.userprefrenceObj['phoneNumber'] = inputData["phoneNumber"];
      this.userprefrenceObj['Extension'] = inputData["Extension"];

      this.onSaveAddressDetails(true);

      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });

    }, (error) => {
      this.displayLoader = false;
      this.toastr.error("Please try again", '', {
        timeOut: 5000,
      });
    });

    // if(this.submitted == true){

    // }

    // if (this.personalDetailsForm.invalid) {
    //   return;
    // }else{
    //   this.open_modal(UserPreferencesTermsComponent);
    // }

  }

  onSaveAddressDetails(isProfileUpdate) {

    this.submitted1 = true;

    if (this.addressDetailsForm.invalid && typeof(isProfileUpdate) == 'undefined') {
      return;
    }

    this.displayEditAddressForm = false;

    let inputData = {
      addrLine1: this.addressDetailsForm.value.streetAddress,
      addrLine2: this.addressDetailsForm.value.suite,
      city: this.addressDetailsForm.value.city,
      country: this.addressDetailsForm.value.country,
      state: this.addressDetailsForm.value.state,
      zipCode: this.addressDetailsForm.value.zip,
      addressId: this.userprefrenceObj['defaultAddrId'],
      company: this.addressDetailsForm.value.companyName,
      firstName: this.userprefrenceObj.name,
      lastName: this.userprefrenceObj.lastName,
    }

    if(isProfileUpdate){
      inputData['addrLine1'] =this.userAddressData['addrLine1'];
      inputData['addrLine2'] =this.userAddressData['addrLine2'];
      inputData['city'] =this.userAddressData['city'];
      inputData['country'] =this.userAddressData['country'];
      inputData['state'] =this.userAddressData['state'];
      inputData['company'] =this.userAddressData['company'];
    }

    this.userAddressData = inputData;
    this.displayLoader = true;


    this.userpreferencesService.updateAddress(inputData).subscribe(result => {
      this.displayLoader = false;

      if(isProfileUpdate){
        this.toastr.success(result['message'], '', {
          timeOut: 5000,
        });
      }

    }, (error) => {
      this.displayLoader = false;
      this.toastr.error("Please try again", '', {
        timeOut: 5000,
      });
    });

  }

  onChangeEmailAddress() {

    if(!this.isEmailVerified) {

      this.toastr.error('Please verify email first!', '', {
        timeOut: 5000,
      });

    }else{

      var loggedInUserDetails = JSON.parse(localStorage.getItem("loggedInUserDetails"));
      loggedInUserDetails["email"] = this.activeEmailId = this.changeEmailAddress.value.email;
      localStorage.setItem("loggedInUserDetails", JSON.stringify(loggedInUserDetails));
      this.toastr.success('Email has been updated successfully!', '', {
        timeOut: 5000,
      });
    }

    /*
    // Keeping this commented as on validate email otp the main customer email id is also getting updated.
    // So below request is unnecessary  - Sagar S 18th October, 2021

    this.submitted2 = true;

    if (this.changeEmailAddress.invalid) {
      return;
    }

    let inputData = {
      email:this.changeEmailAddress.value.email,
      //lastName: "",
      loggedUserId: this.loggedUserId['uuid'],
      //phoneNumber: null,
      //timeZone: null,
      uuid: this.loggedUserId['uuid']
    }

    this.userpreferencesService.changeEmail(inputData).subscribe(result=>{
      
      var loggedInUserDetails = JSON.parse(localStorage.getItem("loggedInUserDetails"));
      loggedInUserDetails["email"] = this.loggedUserId['uuid'];
      localStorage.setItem("loggedInUserDetails", JSON.stringify(loggedInUserDetails));

      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });


    }, (error) => {
      this.toastr.error("Please try again",'', {
        timeOut: 5000,
      });
    });
    */
  } 

  onChangePassword() {
    this.submitted3 = true;
    let newPwd2 = this.changePassword.get('newPassword').value;
    let confirmPwd2 = this.changePassword.get('confirmPassword').value;

    if (newPwd2 != confirmPwd2) {
      this.matchPwds = true;
      return;
    }
    if (this.changePassword.invalid) {
      console.log(this.changePassword.invalid);
      return;
    }
    let inputData = {
      confirmPassword: this.changePassword.value.confirmPassword,
      currentPassword: this.changePassword.value.currentPassword,
      loggedUserId: this.loggedUserId['uuid'],
      password: this.changePassword.value.newPassword
    }
    this.userpreferencesService.changePassword(inputData).subscribe(result => {
      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
    }, (error) => {
      this.toastr.error("Please try again", '', {
        timeOut: 5000,
      });
    });
  }

  get f() { return this.personalDetailsForm.controls; }
  get f1() { return this.addressDetailsForm.controls; }
  get f2() { return this.changeEmailAddress.controls; }
  get f3() { return this.changePassword.controls; }

  toggleFieldTextCurrentPass() {
    this.fieldTextCurrentPass = !this.fieldTextCurrentPass;
  }

  toggleFieldTextNewPass() {
    this.fieldTextNewPass = !this.fieldTextNewPass;
  }

  toggleFieldTextConfPass() {
    this.fieldTextConfPass = !this.fieldTextConfPass;
  }

  creditLilmitCheck(path) {
    this.displayLoader = true;
    this.commonService.getCreditData().subscribe(result => {
      this.displayLoader = false;
      if (result['availableCreditLimit']) {

        const modalRef = this.modalService.open(InfoComponent, {
          size: 'lg',
          centered: false,
          backdrop: true,
          animation: true,
          windowClass: 'my-custom-modal1'
        });
        this.router.events.subscribe((val) => {
          modalRef.close();
        });
        modalRef.componentInstance.title = "Hi";
        modalRef.componentInstance.message = "You already have a credit of $" + (parseFloat(result['availableCreditLimit']).toFixed(2)) + ' CAD' + " In case of query please reach out to support.";

        modalRef.result.then((result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });

      }

    }, (error) => {

      this.displayLoader = false;
      if (error['status'] == 404 && error['error']['Message'] == 'This user is not a credit limit user') {
        this.router.navigate(['/user-preferences/credit-application']);
      } else if(error['status'] == 500) {

        const modalRef = this.modalService.open(InfoComponent, {
          size: 'lg',
          centered: false,
          backdrop: true,
          animation: true,
          windowClass: 'my-custom-modal1'
        });
        this.router.events.subscribe((val) => {
          modalRef.close();
        });
        modalRef.componentInstance.title = "Something went wrong,";
        modalRef.componentInstance.message = 'Please reach out to support!!';

        modalRef.result.then((result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
      } else if (error['status'] == 404 && error['error']['Message'] != 'This user is not a credit limit user') {

        console.log("In");
        const modalRef = this.modalService.open(InfoComponent, {
          size: 'lg',
          centered: false,
          backdrop: true,
          animation: true,
          windowClass: 'my-custom-modal1'
        });
        this.router.events.subscribe((val) => {
          modalRef.close();
        });
        modalRef.componentInstance.title = "Hi";
        modalRef.componentInstance.message = error['error']['Message'];

        modalRef.result.then((result) => {
          console.log(result);
        }, (reason) => {
          console.log(reason);
        });
      }

      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }

    });
  }

  open_modal(ComponentRef) {

    const modalRef = this.modalService.open(ComponentRef, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal1' });
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.result.then((result) => {
      //do something
    });

  }

  editProfile() {
    this.displayEditForm = true;
    this.personalDetailsForm.controls['firstName'].setValue(this.userprefrenceObj.name);
    this.personalDetailsForm.controls['lastName'].setValue(this.userprefrenceObj.lastName);
  }

  editAddress() {
    this.displayEditAddressForm = true;
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }

    if (this.selectedFile) {
      this.saveProfileImage();
    }
  }

  saveProfileImage() {
    const formdata: FormData = new FormData();
    formdata.append('file', this.selectedFile);
    this.userpreferencesService.uploadImage(formdata).subscribe(result => {
      console.log(result)
      this.image = result['data']['downloadURL'];

      var loggedInUserDetails = JSON.parse(localStorage.getItem("loggedInUserDetails"));
      loggedInUserDetails["profile_image"] = result['data']['downloadURL'];

      localStorage.setItem("loggedInUserDetails", JSON.stringify(loggedInUserDetails));

      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
    }, (error) => {
      this.toastr.error("Please try again", '', {
        timeOut: 5000,
      });
    });
  }

  verifyEmail(action) {
    if (action == 'email') {
      this.showError = true
      if (this.changeEmailAddress.value.email == '') {
        this.changeEmailAddress['controls'].email.setErrors({ 'invalid': true });
        return
      }
      this.errorMessage = '';
      this.displayLoader = true;
      let inputData = {
        "loggedInUserId": this.loggedUserId['uuid'],
        "actorType": 'customer',
        "oldEmail": this.loggedUserId['email'],
        "email": this.changeEmailAddress.value.email
      }

      this.userpreferencesService.emailVerify(inputData).subscribe(result => {
        console.log('result here', result)
        this.displayLoader = false;
        const modalRef = this.modalService.open(UserPreferencesEmailComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
        this.router.events.subscribe((val) => {
          modalRef.close();
        });
        modalRef.componentInstance.action = action;
        modalRef.componentInstance.mobile = '';
        modalRef.componentInstance.email = this.changeEmailAddress.value.email;
        modalRef.componentInstance.userId = this.loggedUserId['uuid'];
        modalRef.result.then((result) => {
          console.log(result);
          if (result['success'] == true) {
            this.isEmailVerified = true;

          }
        }, (reason) => {
        });
      },
        (error) => {
          if (error['status'] == 409) {
            const modalRef = this.modalService.open(UserPreferencesEmailComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
            this.router.events.subscribe((val) => {
              modalRef.close();
            });
            modalRef.componentInstance.action = action;
            modalRef.componentInstance.email = this.changeEmailAddress.value.email;
            modalRef.componentInstance.mobile = '';
            modalRef.result.then((result) => {
              if (result['success'] == true) {
                this.isEmailVerified = true;
                this.errorMessage == "";
              }
            }, (reason) => {
            });
          }
          this.displayErrorMessage = false;
          this.displayLoader = false;
          this.errorMessage = error['error']['error'];
          throw error;
        })
    } else {
      this.showError = true
      if (this.personalDetailsForm.value.workPhone == '' || this.personalDetailsForm['controls'].workPhone.invalid) {
        this.personalDetailsForm['controls'].workPhone.setErrors({ 'invalid': true });
        return
      }
      this.errorMessage = '';
      this.displayLoader = true;
      let inputData = {
        "loggedInUserId": this.loggedUserId['uuid'],
        "actorType": 'customer',
        "oldMobile": this.loggedUserId['phoneNumber'],
        "mobile": this.personalDetailsForm.value.workPhone
      }
      this.userpreferencesService.generateOTP(inputData).subscribe(result => {
        console.log('result here', result)
        this.displayLoader = false;
        const modalRef = this.modalService.open(UserPreferencesEmailComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
        this.router.events.subscribe((val) => {
          modalRef.close();
        });
        modalRef.componentInstance.action = action;
        modalRef.componentInstance.email = '';
        modalRef.componentInstance.mobile = this.personalDetailsForm.value.workPhone;
        modalRef.componentInstance.userId = this.loggedUserId['uuid'];
        modalRef.result.then((result) => {
          console.log(result);
          if (result['success'] == true) {
            this.isMobileVerified = true;

          }
        }, (reason) => {
        });
      },
        (error) => {
          this.displayLoader = false;

          this.toastr.error(error['error']['Message'], 'Error', {
            timeOut: 5000,
          });

          this.displayErrorMessage = false;

          this.errorMessage = error['error']['error'];
          throw error;
        })
    }


  }

  updateStateList(countryCode){
    for(let i of this.countryListData){
      if(i.countryCode == countryCode){
        this.countryStateListObj = i.provinceState;
      }
    }
  }

}
export class OldPwdValidators {
  static shouldBe1234(control: AbstractControl): Promise<ValidationErrors | null> {
    return new Promise((resolve, reject) => {
      if (control.value !== '1234')
        resolve({ shouldBe1234: true });
      else
        resolve(null);
    });
  }

  static matchPwds(control: AbstractControl) {
    let newPwd2 = control.get('newPassword');
    console.log(newPwd2);
    let confirmPwd2 = control.get('confirmPassword');
    console.log(confirmPwd2);
    if (newPwd2.value !== confirmPwd2.value) {
      return { pwdsDontMatch: true };
    }
    return null;
  }


}
