import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentListingService {

  constructor(private http:HttpClient) { }

  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };

  getShipmentItems(page,filterParms,perPage){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/order/getAll/"+page+"/"+perPage+(filterParms ? '?'+filterParms:""),{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
  }

  getShipmentTransitItems(page,filterParms,perPage,status){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/order/getAll/"+page+"/"+perPage+ "?"+(filterParms ? filterParms +"&":"") + "shipmentStatus="+status,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{ 
        return error;
      }));
  }


  getFavouriteListing(page,filterParms,perPage){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/order/getAll/"+page+"/"+perPage+(filterParms ? '?'+filterParms:""),{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
  }


  // updateItems(page,filterParms,perPage){
  //   const myheader = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.put(environment.apiPath+ "/api/order/updateFavouriteStatus",{ withCredentials: true,
  //     headers: myheader
  //       }).pipe(map(response => {
  //       return response;
  //     },error=>{
  //       return error;
  //     }));
  // }

  updateItems(dataObj){
    return this.http.put(environment.apiPath + "/api/order/updateFavouriteStatus", dataObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  
}
