<div class="container">
    <div class="row">
        <div class="col-1">
            <app-leftsidebar></app-leftsidebar>
        </div>

        <div class="col-11">

            <div class="shipment_section">
                <div class="row mt-5 pt-3 "  style="border: 1px solid #c4cdd5;opacity: 1;background-color: #f5f6fa;">
                    <div class="col-10 pl-5">
                        <div class="main-heading d-inline"><img src="assets/img/commercial/Mask.svg"> Shipments
                        </div>

                        <div class="sub-headings"> 
                           <span class="topbtn" [ngClass]="Orders ? 'activeBtn' : 'inactive'" (click)="allOrders()">All Orders</span>
                           <span class="topbtn" style="margin: 0 20px 0 20px;" [ngClass]="transit ? 'activeBtn' : 'inactive'" (click)="inTransit()">In-Transit Orders</span>
                           <span class="topbtn"[ngClass]="completed ? 'activeBtn' : 'inactive'" (click)="completedOrders()">Completed Orders</span>
                        </div>
                    </div>
                </div>
                <div class=" row ml-4 mr-3 pl-3 mt-4 shipment_listing " *ngIf="Orders && !transit && !completed">
                    <div class="col-12 shipment_listing_inner ">
                        <div class="row">
                        <form [formGroup]="dateFilterForm">
                        <div class="col-12 applay_section ">
                            <div class="row pl-0 mb-0 align-items-start">
                                <div class="col-1 pt-3 text-end filter" style="width: 3%; margin-top: 0.3px;" >
                                    <select class="custom-select" (change)="filterByValue($event)" style="width:27px; height: 26px !important; border-radius: 0; border: 1px solid #CED0DA !important; padding: 2px 0 2px 0;  background-repeat: no-repeat;
                                    background-position: 4px 4px;
                                    background-size: 15px;
                                    text-align: center;
                                    padding-top: 2px;
                                    background-image:url(../../assets/img/shipment/fi-rr-filter.svg)">
                                            <option selected value=""></option>
                                            <option value="CRS">Courier</option>
                                            <option value="LTL">LTL</option>
                                            <option value="VLTL">Volume LTL</option>
                                            <option value="FTL">Full Load</option>
                                            <!-- <option>PO No.</option>
                                            <option>In Transist</option>
                                            <option>Delivered</option> -->
                                          
                                        </select>
                                </div>

                                <div class="col-4 pt-3 mr-2 ml-0 text-start serachbar-box" style="width: 22%;">
                                    <label> Search Orders</label>
                                    <input class="form-control" (change)="updateSearchKey($event.target.value)" type="text" placeholder="Search Orders.." />
                                </div>
                                 
                                <div class="col-2 ml-0 mr-3 pt-3 start-date-box" style="width: 8%;">
                                    <!-- <input class="form-control" placeholder="Start" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <!-- <div class="input-group col-12"> -->
                                        <label>From Date</label>
                                        <input  class="form-control" style='height:10px' placeholder="--" onfocus="(this.type='date')" onblur="(this.type='text')"
                                                 name="dp"  formControlName="startDate"  >
                                  <!-- </div> -->
                                 </div>
                                  
                                    <div class="col-2 mr-4 pt-3 end-date-box" style="width: 9%;">
                                    <!-- <input class="form-control" class="form-control" placeholder="End" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <!-- <div class="input-group col-12"> -->
                                        <label>To Date</label>
                                        <input class="form-control" placeholder=" --" onfocus="(this.type='date')" onblur="(this.type='text')"
                                        name="dp"  formControlName="endDate"  >
                                      <!-- </div> -->
                                </div>
                                
                                <div class="col-1 pt-3 text-center apply-btn-box" style="width: 7%;">
                                    <button type="button" style="padding: 2px 7px;" class="btn btn-outline-warning" (click)="apply()" >Apply</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                        <div class="row shipment_history_table" >
                            <div class="col-12" style="z-index: 2;">
                                <!-- <div *ngIf="!displayErrorMessage">
                                        {{errorMessage}} <button class="btn orange text-white" (click)="redirectTo()">"I'LL CREATE ONE"!</button>
                                    </div> -->
                                <table class="table table-responsive customStyle" >
                                    <thead style="background-color: #F5F6FA; border-top: 1px solid #C4CDD5; border-bottom: 1px solid #C4CDD5;">
                                        <tr>
                                            <th scope="col-2" (click)="sort('customOrderId')">
                                                <span [class]="sortBy['customOrderId']">Order No.</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('customOrderId')">
                                                <span [class]="sortBy['customOrderId']">Carrier</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('poNumber')">
                                                <span [class]="sortBy['poNumber']">PO No.</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipmentModeName')">
                                                <span [class]="sortBy['shipmentModeName']">Service </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipmentModeName')">
                                                <span [class]="sortBy['shipmentModeName']">Source </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('pickupDate')">
                                                <span [class]="sortBy['pickupDate']">Pickup Date </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipperName')">
                                                <span [class]="sortBy['shipperName']">Shipper</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipperName')">
                                                <span [class]="sortBy['shipperName']">Origin</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('consigneeName')">
                                                <span [class]="sortBy['consigneeName']">Consignee</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('consigneeName')">
                                                <span [class]="sortBy['consigneeName']">Destination</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('price')">
                                                <span [class]="sortBy['price']">Quote</span>
                                            </th>
                                            <th scope="col-2">
                                                <span>Status</span>
                                            </th>

                                            <th scope="col-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr  style="margin-top: 20px;color: #5d4646;" *ngIf="shipments?.length == 0">
                                            <td colspan="9" class=" text-center">No data found!!!</td>  
                                         </tr>
                                        <tr *ngFor="let ship of shipments; let i = index ">
                                            <td class="order_no"><a style="cursor:pointer" (click)="viewQuote(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)">{{ship.customOrderId}}</a></td>
                                            <td>
                                                <!-- <span><img style="height: 30px; width: 30px;" src="{{ship['quoteDetails'].carrierLogo ? ship['quoteDetails'].carrierLogo : '--'}}" alt=""></span> -->
                                                <span>{{ship['quoteDetails'].carrierName ? ship['quoteDetails'].carrierName : '--'}}</span>
                                           
                                                  </td>
                                            <td>{{ship['quoteDetails'].poNumber ? ship['quoteDetails'].poNumber : '--'}}</td>
                                            <td><img src="assets/img/quotes/shmt/{{ship['quoteDetails'].shipmentModeStyleCode}}.svg" width="15%"> {{ship['quoteDetails'].shipmentModeName}}</td>
                                            <td>Manual</td>
                                            <td>{{ship['quoteDetails'].pickupDate}}</td>
                                            <td>{{ship['quoteDetails']['shipperAddress'].firstName ? ship['quoteDetails']['shipperAddress'].firstName : ''}} {{ship['quoteDetails']['shipperAddress'].lastName ? ship['quoteDetails']['shipperAddress'].lastName
                                                : ''}}</td>
                                            <td>{{ship['quoteDetails']['shipperAddress'].city ? ship['quoteDetails']['shipperAddress'].city : ''}}, {{ship['quoteDetails']['shipperAddress'].state ? ship['quoteDetails']['shipperAddress'].state
                                                : ''}} </td>
                                            <td>{{ship['quoteDetails']['consigneeAddress'].firstName ? ship['quoteDetails']['consigneeAddress'].firstName : ''}} {{ship['quoteDetails']['consigneeAddress'].lastName ? ship['quoteDetails']['consigneeAddress'].lastName
                                                : ''}}</td>
                                            <td>{{ship['quoteDetails']['consigneeAddress'].city ? ship['quoteDetails']['consigneeAddress'].city : ''}}, {{ship['quoteDetails']['consigneeAddress'].state ? ship['quoteDetails']['consigneeAddress'].state
                                                : ''}}</td>
                                            <td>
                                                ${{ship['priceBr'] ? ship['priceBr'].totalAmount : '00.00'}} <small>{{ship['priceBr'] ? ship['priceBr'].currency :''}} </small> 
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" placement="bottom" popoverClass="popover_shipment_quote" [ngbPopover]="popContent" (click)="popOverIndex(i)" />

                                            </td>
                                            <td>
                                                <div [class]="ship && ship?.status=='FAILED' ? 'badge badge-danger' : ship?.status=='DELIVERED' ? 'badge badge-success' : ship?.status=='INTRANSIT' ? 'badge badge-warning' :  ship?.status=='NEW' ? 'badge badge-primary': 'badge badge-secondary'" >{{ship?.status}}</div>
                                                <span class="ml-2" *ngIf="ship?.status =='DELIVERED' "> 
                                                    <img  src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" (click)="showPOD(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)" placement="bottom" popoverClass="popover_shipment_status" [ngbPopover]="popContentDelivered" (click)="popOverIndex(i)" />

                                                </span>
                                                <span class="ml-2" *ngIf=" ship?.status =='INTRANSIT'"> 
                                                    <img  src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" (click)="ViewDetailsON(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)" placement="bottom" popoverClass="popover_shipment_status" [ngbPopover]="popContentIntransit" (click)="popOverIndex(i)" />

                                                </span>
                                            </td>
                                            <td class="action_td">
                                                <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle " data-toggle="dropdown">
                                               
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item" (click)="viewQuote(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)">View</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item"  (click)="getQuote(i)" >Ship Again</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'" 
                                                    (click)="saveToFavorite(ship.orderId)">Save to Favorite</a>
                                                    <!-- <a class="dropdown-item" *ngIf="ship.status!='FAILED'" (click)="saveToFavorite(ship.orderId)">Save to Favorite</a> -->
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View POD</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View Invoice</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">Make Payment</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">File a Cliam</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row pt-2 pl-0 pr-0 ml-0 mr-0" style="background-color:#f5f6fa; font-family: poppins,sans-serif;z-index: 1; " >
                            <!-- <div class="col-8 " style="margin-top: 20px;color: #7b7b7b;">
                                 Showing {{currentPage==0 ? 1 : (currentPage * perPage)+1}} to {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}

                            </div> -->
                            <div class="col-12 text-end mr-0" style="float: right;">
                                <!-- <ngb-pagination #i [collectionSize]="totalElements" [maxSize]="5" [rotate]="true" style="margin-top: 14px;  margin-left: 219px;" [pageSize]="perPage" [(page)]="page" (click)="selectPage(i)">
                                    <ng-template ngbPaginationPrevious style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Prev</ng-template>
                                    <ng-template ngbPaginationNext style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Next</ng-template>
                                </ngb-pagination> -->
                            
                                    <!-- <label class="d-inline" style="left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Rows
                                    </label> -->
                                    <span>Rows</span>
                                    <span class="rowSelect">
                                <select class="custom-select d-inline ml-2" (change)=" perPageItem($event)">
                                    <option  class="dropdown-item" value="25">{{currentPage==0 ? 1 : (currentPage * perPage)+1}} - {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}
                                    </option>
                                    <option  class="dropdown-item" value="50">1 - 50 of {{totalElements}}</option>
                                    <option  class="dropdown-item" value="100">1 - 100 of {{totalElements}}</option>
                                    <option  class="dropdown-item" value="250">1 - 250 of {{totalElements}}</option>
                                </select>     
                            </span>
                            
                           
                            <!-- </div> -->
                            <!-- <div class="col-1 text-center ml-0 "> -->
                                <ngb-pagination class="customPagination" style="margin-left: 5px;">
                                    <ng-template ngbPaginationPrevious style="border: 1px solid black;"><span class="prev"></span></ng-template>
                                    <ng-template ngbPaginationNext><span class="next"></span></ng-template>
                                  </ngb-pagination>
                                </div>
                        </div>
                    </div>
                </div>



                <div class=" row ml-4 mr-3 pl-3 mt-4 shipment_listing " *ngIf=" !Orders && transit && !completed">
                    <div class="col-12 shipment_listing_inner ">
                        <div class="row">
                        <form [formGroup]="dateFilterForm">
                        <div class="col-12 applay_section ">
                            <div class="row pl-0 mb-0 align-items-start">
                                <div class="col-1 pt-3 text-end filter" style="width: 3%; margin-top: 0.3px;" >
                                    <select class="custom-select" (change)="filterByValue($event)" style="width:27px; height: 26px !important; border-radius: 0; border: 1px solid #CED0DA !important; padding: 2px 0 2px 0;  background-repeat: no-repeat;
                                    background-position: 4px 4px;
                                    background-size: 15px;
                                    text-align: center;
                                    padding-top: 2px;
                                    background-image:url(../../assets/img/shipment/fi-rr-filter.svg)">
                                            <option selected value=""></option>
                                            <option value="CRS">Courier</option>
                                            <option value="LTL">LTL</option>
                                            <option value="VLTL">Volume LTL</option>
                                            <option value="FTL">Full Load</option>
                                            <!-- <option>PO No.</option>
                                            <option>In Transist</option>
                                            <option>Delivered</option> -->
                                          
                                        </select>
                                </div>

                                <div class="col-4 pt-3 mr-2 ml-0 text-start serachbar-box" style="width: 22%;">
                                    <label> Search Orders</label>
                                    <input class="form-control" (change)="updateSearchKey($event.target.value)" type="text" placeholder="Search Orders.." />
                                </div>
                                 
                                <div class="col-2 ml-0 mr-3 pt-3 start-date-box" style="width: 8%;">
                                    <!-- <input class="form-control" placeholder="Start" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <!-- <div class="input-group col-12"> -->
                                        <label>From Date</label>
                                        <input  class="form-control" style='height:10px' placeholder="--" onfocus="(this.type='date')" onblur="(this.type='text')"
                                                 name="dp"  formControlName="startDate"  >
                                  <!-- </div> -->
                                 </div>
                                  
                                    <div class="col-2 mr-4 pt-3 end-date-box" style="width: 9%;">
                                    <!-- <input class="form-control" class="form-control" placeholder="End" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <!-- <div class="input-group col-12"> -->
                                        <label>To Date</label>
                                        <input class="form-control" placeholder=" --" onfocus="(this.type='date')" onblur="(this.type='text')"
                                        name="dp"  formControlName="endDate"  >
                                      <!-- </div> -->
                                </div>
                                
                                <div class="col-1 pt-3 text-center apply-btn-box" style="width: 7%;">
                                    <button type="button" style="padding: 2px 7px;" class="btn btn-outline-warning" (click)="apply()" >Apply</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                        <div class="row shipment_history_table" >
                            <div class="col-12" style="z-index: 2;">
                                <!-- <div *ngIf="!displayErrorMessage">
                                        {{errorMessage}} <button class="btn orange text-white" (click)="redirectTo()">"I'LL CREATE ONE"!</button>
                                    </div> -->
                                <table class="table table-responsive customStyle" >
                                    <thead style="background-color: #F5F6FA; border-top: 1px solid #C4CDD5; border-bottom: 1px solid #C4CDD5;">
                                        <tr>
                                            <th scope="col-2" (click)="sort('customOrderId')">
                                                <span [class]="sortBy['customOrderId']">Order No.</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('customOrderId')">
                                                <span [class]="sortBy['customOrderId']">Carrier</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('poNumber')">
                                                <span [class]="sortBy['poNumber']">PO No.</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipmentModeName')">
                                                <span [class]="sortBy['shipmentModeName']">Service </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipmentModeName')">
                                                <span [class]="sortBy['shipmentModeName']">Source </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('pickupDate')">
                                                <span [class]="sortBy['pickupDate']">Pickup Date </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipperName')">
                                                <span [class]="sortBy['shipperName']">Shipper</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipperName')">
                                                <span [class]="sortBy['shipperName']">Origin</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('consigneeName')">
                                                <span [class]="sortBy['consigneeName']">Consignee</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('consigneeName')">
                                                <span [class]="sortBy['consigneeName']">Destination</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('price')">
                                                <span [class]="sortBy['price']">Quote</span>
                                            </th>
                                            <th scope="col-2">
                                                <span>Status</span>
                                            </th>

                                            <th scope="col-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr  style="margin-top: 20px;color: #5d4646;" *ngIf="shipments?.length == 0">
                                            <td colspan="9" class=" text-center">No data found!!!</td>  
                                         </tr>
                                        <tr *ngFor="let ship of shipments; let i = index ">
                                            <td class="order_no"><a style="cursor:pointer" (click)="viewQuote(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)">{{ship.customOrderId}}</a></td>
                                            <td>{{ship['quoteDetails'].carrierLogo ? ship['quoteDetails'].carrierLogo : '--'}} {{ship['quoteDetails'].carrierName ? ship['quoteDetails'].carrierName : '--'}}</td>
                                            <td>{{ship['quoteDetails'].poNumber ? ship['quoteDetails'].poNumber : '--'}}</td>
                                            <td><img src="assets/img/quotes/shmt/{{ship['quoteDetails'].shipmentModeStyleCode}}.svg" width="15%"> {{ship['quoteDetails'].shipmentModeName}}</td>
                                            <td>Manual</td>
                                            <td>{{ship['quoteDetails'].pickupDate}}</td>
                                            <td>{{ship['quoteDetails']['shipperAddress'].firstName ? ship['quoteDetails']['shipperAddress'].firstName : ''}} {{ship['quoteDetails']['shipperAddress'].lastName ? ship['quoteDetails']['shipperAddress'].lastName
                                                : ''}}</td>
                                            <td>{{ship['quoteDetails']['shipperAddress'].city ? ship['quoteDetails']['shipperAddress'].city : ''}}, {{ship['quoteDetails']['shipperAddress'].state ? ship['quoteDetails']['shipperAddress'].state
                                                : ''}} </td>
                                            <td>{{ship['quoteDetails']['consigneeAddress'].firstName ? ship['quoteDetails']['consigneeAddress'].firstName : ''}} {{ship['quoteDetails']['consigneeAddress'].lastName ? ship['quoteDetails']['consigneeAddress'].lastName
                                                : ''}}</td>
                                            <td>{{ship['quoteDetails']['consigneeAddress'].city ? ship['quoteDetails']['consigneeAddress'].city : ''}}, {{ship['quoteDetails']['consigneeAddress'].state ? ship['quoteDetails']['consigneeAddress'].state
                                                : ''}}</td>
                                            <td>
                                                ${{ship['priceBr'] ? ship['priceBr'].totalAmount : '00.00'}} <small>{{ship['priceBr'] ? ship['priceBr'].currency :''}} </small> 
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" placement="bottom" popoverClass="popover_shipment_quote" [ngbPopover]="popContent" (click)="popOverIndex(i)" />

                                            </td>
                                            <td>
                                                <div [class]="ship && ship?.status=='FAILED' ? 'badge badge-danger' : ship?.status=='DELIVERED' || ship?.status=='NEW'? 'badge badge-success' : ship?.status=='INTRANSIT' ? 'badge badge-warning' : 'badge badge-secondary'" >{{ship?.status}}</div>
                                                <span class="ml-2" *ngIf=" ship?.status =='INTRANSIT'"> 
                                                    <img  src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" (click)="ViewDetailsON(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)" placement="bottom" popoverClass="popover_shipment_status" [ngbPopover]="popContentIntransit" (click)="popOverIndex(i)" />

                                                </span>
                                            </td>
                                            <td class="action_td">
                                                <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle " data-toggle="dropdown">
                                               
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item" (click)="viewQuote(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)">View</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item"  (click)="getQuote(i)" >Ship Again</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'" 
                                                    (click)="saveToFavorite(ship.orderId)">Save to Favorite</a>
                                                    <!-- <a class="dropdown-item" *ngIf="ship.status!='FAILED'" (click)="saveToFavorite(ship.orderId)">Save to Favorite</a> -->
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View POD</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View Invoice</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">Make Payment</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">File a Cliam</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row pt-2 pl-0 pr-0 ml-0 mr-0" style="background-color:#f5f6fa; font-family: poppins,sans-serif;z-index: 1; " >
                            <!-- <div class="col-8 " style="margin-top: 20px;color: #7b7b7b;">
                                 Showing {{currentPage==0 ? 1 : (currentPage * perPage)+1}} to {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}

                            </div> -->
                            <div class="col-12 text-end mr-0" style="float: right;">
                                <!-- <ngb-pagination #i [collectionSize]="totalElements" [maxSize]="5" [rotate]="true" style="margin-top: 14px;  margin-left: 219px;" [pageSize]="perPage" [(page)]="page" (click)="selectPage(i)">
                                    <ng-template ngbPaginationPrevious style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Prev</ng-template>
                                    <ng-template ngbPaginationNext style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Next</ng-template>
                                </ngb-pagination> -->
                            
                                    <!-- <label class="d-inline" style="left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Rows
                                    </label> -->
                                    <span>Rows</span>
                                    <span class="rowSelect">
                                <select class="custom-select d-inline ml-2" (change)=" perPageItem($event)">
                                    <option  class="dropdown-item" value="25">{{currentPage==0 ? 1 : (currentPage * perPage)+1}} - {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}
                                    </option>
                                    <option  class="dropdown-item" value="50">1 - 50 of {{totalElements}}</option>
                                    <option  class="dropdown-item" value="100">1 - 100 of {{totalElements}}</option>
                                    <option  class="dropdown-item" value="250">1 - 250 of {{totalElements}}</option>
                                </select>     
                            </span>
                            
                           
                            <!-- </div> -->
                            <!-- <div class="col-1 text-center ml-0 "> -->
                                <ngb-pagination class="customPagination" style="margin-left: 5px;">
                                    <ng-template ngbPaginationPrevious style="border: 1px solid black;"><span class="prev"></span></ng-template>
                                    <ng-template ngbPaginationNext><span class="next"></span></ng-template>
                                  </ngb-pagination>
                                </div>
                        </div>
                    </div>
                </div>




                <div class=" row ml-4 mr-3 pl-3 mt-4 shipment_listing " *ngIf="!Orders && !transit && completed">
                    <div class="col-12 shipment_listing_inner ">
                        <div class="row">
                        <form [formGroup]="dateFilterForm">
                        <div class="col-12 applay_section ">
                            <div class="row pl-0 mb-0 align-items-start">
                                <div class="col-1 pt-3 text-end filter" style="width: 3%; margin-top: 0.3px;" >
                                    <select class="custom-select" (change)="filterByValue($event)" style="width:27px; height: 26px !important; border-radius: 0; border: 1px solid #CED0DA !important; padding: 2px 0 2px 0;  background-repeat: no-repeat;
                                    background-position: 4px 4px;
                                    background-size: 15px;
                                    text-align: center;
                                    padding-top: 2px;
                                    background-image:url(../../assets/img/shipment/fi-rr-filter.svg)">
                                            <option selected value=""></option>
                                            <option value="CRS">Courier</option>
                                            <option value="LTL">LTL</option>
                                            <option value="VLTL">Volume LTL</option>
                                            <option value="FTL">Full Load</option>
                                            <!-- <option>PO No.</option>
                                            <option>In Transist</option>
                                            <option>Delivered</option> -->
                                          
                                        </select>
                                </div>

                                <div class="col-4 pt-3 mr-2 ml-0 text-start serachbar-box" style="width: 22%;">
                                    <label> Search Orders</label>
                                    <input class="form-control" (change)="updateSearchKey($event.target.value)" type="text" placeholder="Search Orders.." />
                                </div>
                                 
                                <div class="col-2 ml-0 mr-3 pt-3 start-date-box" style="width: 8%;">
                                    <!-- <input class="form-control" placeholder="Start" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <!-- <div class="input-group col-12"> -->
                                        <label>From Date</label>
                                        <input  class="form-control" style='height:10px' placeholder="--" onfocus="(this.type='date')" onblur="(this.type='text')"
                                                 name="dp"  formControlName="startDate"  >
                                  <!-- </div> -->
                                 </div>
                                  
                                    <div class="col-2 mr-4 pt-3 end-date-box" style="width: 9%;">
                                    <!-- <input class="form-control" class="form-control" placeholder="End" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <!-- <div class="input-group col-12"> -->
                                        <label>To Date</label>
                                        <input class="form-control" placeholder=" --" onfocus="(this.type='date')" onblur="(this.type='text')"
                                        name="dp"  formControlName="endDate"  >
                                      <!-- </div> -->
                                </div>
                                
                                <div class="col-1 pt-3 text-center apply-btn-box" style="width: 7%;">
                                    <button type="button" style="padding: 2px 7px;" class="btn btn-outline-warning" (click)="apply()" >Apply</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
                        <div class="row shipment_history_table" >
                            <div class="col-12" style="z-index: 2;">
                                <!-- <div *ngIf="!displayErrorMessage">
                                        {{errorMessage}} <button class="btn orange text-white" (click)="redirectTo()">"I'LL CREATE ONE"!</button>
                                    </div> -->
                                <table class="table table-responsive customStyle" >
                                    <thead style="background-color: #F5F6FA; border-top: 1px solid #C4CDD5; border-bottom: 1px solid #C4CDD5;">
                                        <tr>
                                            <th scope="col-2" (click)="sort('customOrderId')">
                                                <span [class]="sortBy['customOrderId']">Order No.</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('customOrderId')">
                                                <span [class]="sortBy['customOrderId']">Carrier</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('poNumber')">
                                                <span [class]="sortBy['poNumber']">PO No.</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipmentModeName')">
                                                <span [class]="sortBy['shipmentModeName']">Service </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipmentModeName')">
                                                <span [class]="sortBy['shipmentModeName']">Source </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('pickupDate')">
                                                <span [class]="sortBy['pickupDate']">Pickup Date </span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipperName')">
                                                <span [class]="sortBy['shipperName']">Shipper</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('shipperName')">
                                                <span [class]="sortBy['shipperName']">Origin</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('consigneeName')">
                                                <span [class]="sortBy['consigneeName']">Consignee</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('consigneeName')">
                                                <span [class]="sortBy['consigneeName']">Destination</span>
                                            </th>
                                            <th scope="col-2" (click)="sort('price')">
                                                <span [class]="sortBy['price']">Quote</span>
                                            </th>
                                            <th scope="col-2">
                                                <span>Status</span>
                                            </th>

                                            <th scope="col-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="">
                                        <tr  style="margin-top: 20px;color: #5d4646;" *ngIf="shipments?.length == 0">
                                            <td colspan="9" class=" text-center">No data found!!!</td>  
                                         </tr>
                                        <tr *ngFor="let ship of shipments; let i = index ">
                                            <td class="order_no"><a style="cursor:pointer" (click)="viewQuote(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)">{{ship.customOrderId}}</a></td>
                                            <td>{{ship['quoteDetails'].carrierLogo ? ship['quoteDetails'].carrierLogo : '--'}} {{ship['quoteDetails'].carrierName ? ship['quoteDetails'].carrierName : '--'}}</td>
                                            <td>{{ship['quoteDetails'].poNumber ? ship['quoteDetails'].poNumber : '--'}}</td>
                                            <td><img src="assets/img/quotes/shmt/{{ship['quoteDetails'].shipmentModeStyleCode}}.svg" width="15%"> {{ship['quoteDetails'].shipmentModeName}}</td>
                                            <td>Manual</td>
                                            <td>{{ship['quoteDetails'].pickupDate}}</td>
                                            <td>{{ship['quoteDetails']['shipperAddress'].firstName ? ship['quoteDetails']['shipperAddress'].firstName : ''}} {{ship['quoteDetails']['shipperAddress'].lastName ? ship['quoteDetails']['shipperAddress'].lastName
                                                : ''}}</td>
                                            <td>{{ship['quoteDetails']['shipperAddress'].city ? ship['quoteDetails']['shipperAddress'].city : ''}}, {{ship['quoteDetails']['shipperAddress'].state ? ship['quoteDetails']['shipperAddress'].state
                                                : ''}} </td>
                                            <td>{{ship['quoteDetails']['consigneeAddress'].firstName ? ship['quoteDetails']['consigneeAddress'].firstName : ''}} {{ship['quoteDetails']['consigneeAddress'].lastName ? ship['quoteDetails']['consigneeAddress'].lastName
                                                : ''}}</td>
                                            <td>{{ship['quoteDetails']['consigneeAddress'].city ? ship['quoteDetails']['consigneeAddress'].city : ''}}, {{ship['quoteDetails']['consigneeAddress'].state ? ship['quoteDetails']['consigneeAddress'].state
                                                : ''}}</td>
                                            <td>
                                                ${{ship['priceBr'] ? ship['priceBr'].totalAmount : '00.00'}} <small>{{ship['priceBr'] ? ship['priceBr'].currency :''}} </small> 
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" placement="bottom" popoverClass="popover_shipment_quote" [ngbPopover]="popContent" (click)="popOverIndex(i)" />

                                            </td>
                                            <td>
                                                <div [class]="ship && ship?.status=='FAILED' ? 'badge badge-danger' : ship?.status=='DELIVERED' || ship?.status=='NEW'? 'badge badge-success': ship?.status=='INTRANSIT' ? 'badge badge-warning' : 'badge badge-secondary'" >{{ship?.status}}</div>
                                                <span class="ml-2" *ngIf="ship?.status =='DELIVERED' "> 
                                                    <img  src="assets/img/quotes/down_icon.svg" style="cursor: pointer;" (click)="showPOD(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)" placement="bottom" popoverClass="popover_shipment_status" [ngbPopover]="popContentDelivered" (click)="popOverIndex(i)" />

                                                </span>
                                            </td>
                                            <td class="action_td">
                                                <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle " data-toggle="dropdown">
                                               
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item" (click)="viewQuote(ship['quoteDetails']['quoteId'],ship['orderId'],ship?.status)">View</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item"  (click)="getQuote(i)" >Ship Again</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" *ngIf="ship.status!='FAILED'" 
                                                    (click)="saveToFavorite(ship.orderId)">Save to Favorite</a>
                                                    <!-- <a class="dropdown-item" *ngIf="ship.status!='FAILED'" (click)="saveToFavorite(ship.orderId)">Save to Favorite</a> -->
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View POD</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">View Invoice</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">Make Payment</a>
                                                    <div class="dropdown-divider" *ngIf="ship.status!='FAILED'"></div>
                                                    <a class="dropdown-item text-danger op05" *ngIf="ship.status!='FAILED'">File a Cliam</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row pt-2 pl-0 pr-0 ml-0 mr-0" style="background-color:#f5f6fa; font-family: poppins,sans-serif;z-index: 1; " >
                            <!-- <div class="col-8 " style="margin-top: 20px;color: #7b7b7b;">
                                 Showing {{currentPage==0 ? 1 : (currentPage * perPage)+1}} to {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}

                            </div> -->
                            <div class="col-12 text-end mr-0" style="float: right;">
                                <!-- <ngb-pagination #i [collectionSize]="totalElements" [maxSize]="5" [rotate]="true" style="margin-top: 14px;  margin-left: 219px;" [pageSize]="perPage" [(page)]="page" (click)="selectPage(i)">
                                    <ng-template ngbPaginationPrevious style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Prev</ng-template>
                                    <ng-template ngbPaginationNext style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Next</ng-template>
                                </ngb-pagination> -->
                            
                                    <!-- <label class="d-inline" style="left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Rows
                                    </label> -->
                                    <span>Rows</span>
                                    <span class="rowSelect">
                                <select class="custom-select d-inline ml-2" (change)=" perPageItem($event)">
                                    <option  class="dropdown-item" value="25">{{currentPage==0 ? 1 : (currentPage * perPage)+1}} - {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}
                                    </option>
                                    <option  class="dropdown-item" value="50">1 - 50 of {{totalElements}}</option>
                                    <option  class="dropdown-item" value="100">1 - 100 of {{totalElements}}</option>
                                    <option  class="dropdown-item" value="250">1 - 250 of {{totalElements}}</option>
                                </select>     
                            </span>
                            
                           
                            <!-- </div> -->
                            <!-- <div class="col-1 text-center ml-0 "> -->
                                <ngb-pagination class="customPagination" style="margin-left: 5px;">
                                    <ng-template ngbPaginationPrevious style="border: 1px solid black;"><span class="prev"></span></ng-template>
                                    <ng-template ngbPaginationNext><span class="next"></span></ng-template>
                                  </ngb-pagination>
                                </div>
                        </div>
                    </div>
                </div>



                <div class="col-12 pt-3 pl-4">
                    <p class="copyright d-inline">© {{cYear}} ShipCarte. All Rights Reserved.</p>
                    <span class="ml-1 mt-1 mb-5 d-inline">
                            <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                            </a>
                        </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container" [ngClass]="displayCourier ? 'showClass' : 'hideClass'">
    <div class="overlay-box" style="color: #000;
	position: fixed;
	width: 100%;
	background: #fff;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999999;
	opacity: 0.8;">
        <div class="payment_status_popup" style="width: 460px;margin: 0 auto;text-align: center;position: absolute;
		left: 0;right: 0;top: 30%;">
            <img src="/assets/img/quotes/loader.svg" alt="">
            <h2 class="mt-3" style="
		  font-size: 20px !important;
		  color: #151522;
		  margin: 0;
		  padding: 0;
		  font-weight: 600 !important;
		  text-align: center;">Loading your options, please wait… </h2>
        </div>

    </div>
</div>
<ng-template #popContent>
    Price Summary
    <div class="grey-devider"></div>
    <div class="row">
        <div class="col-6"><img src="{{popOverDataPrice.carrierLogo ? popOverDataPrice.carrierLogo : 'assets/img/payment/Courier.svg'}}" width="60px" class="m-1"></div>
        <div class="col-6 text-right">
            <div>{{popOverDataPrice.shpmtModeName}} <img src="assets/img/quotes/shmt/{{popOverDataPrice.shipmentModeStyleCode}}.svg" width="30px" class="m-1"></div>
            <div>Weight: {{popOverDataPrice && popOverDataPrice?.weight  | number : '1.2-2'}} Lbs</div>
        </div>

    </div>
    <div class="row  mb-2">
        <div class="col-6">
            Quote No.
        </div>
        <div class="col-6 blue-text text-right">{{popOverDataPrice.customId}}</div>
    </div>
    
    <div class="row  mb-2 " *ngFor="let charge of popOverDataPrice.price_breakUp; let i = index">
        <div class="col-6">
            {{charge[0]}}
            
        </div>
        <div class="col-6 text-right"> ${{charge[1]}}</div>
    </div>
    <div class="row orange-text mb-0">
        <div class="col-6">
            Discount
        </div>
        <div class="col-6 text-right">${{popOverDataPrice.discount ? popOverDataPrice.discount : '00.00'}}</div>
    </div>
</ng-template>
<ng-template #popContentDelivered>
    <div style="font-size: 14px; font-weight: 600; font-family: poppins,sans-serif;">Status on Delivery</div>
    <br>
    <div class="badge badge-pill badge-success">DELIVERED</div>
    <p class="d-inline blue-text pl-2"><a style="text-decoration: none; cursor: pointer;" (click)="viewPOD()">View POD</a> </p>
    <div class="row mb-2 mt-2">

        <div class="col-6">Received By</div>
        <div class="col-6 text-right">{{trackingData['receivedBy'] ? trackingData['receivedBy'] :'--'}}</div>
    </div>
    <div class="grey-devider"></div>
    <div class="row mb-2">

        <div class="col-6">Date</div>
        <div class="col-6 text-right">{{trackingData['receivedDate']?trackingData['receivedDate']: '--/--/--' }}</div>
    </div>
    <div class="grey-devider"></div>
    <div class="row mb-2">

        <div class="col-6">Time</div>
        <div class="col-6 text-right">{{trackingData['receivedTime'] ? trackingData['receivedTime'] :'--/--/--'}}</div>
    </div>
</ng-template>
<ng-template #popContentIntransit>
  <div style="font-size: 14px; font-weight: 600; font-family: poppins,sans-serif;">Status on Delivery</div>
    <br>
    <div class="badge badge-pill badge-warning">In-TRANSIT</div>

    <div class="mb-2 mt-3 row">

        <div class="col-7">Original Delivery Date</div>
        <div class="col-5 text-right">{{trackingData['originalDeliveryDate'] ? trackingData['originalDeliveryDate'] : '--/--/--'}}</div>
    </div>
    <div class="grey-devider"></div>
    <div class="mb-2 mt-2 row">

        <div class="history-tl-container">
            <ul class="tl">
                <li class="tl-item">
                    <div class="item-title"><span class="text-dark">{{shipperZip}}</span>
                        <p class="d-inline h5" style="margin-left: 4px;"> Origin Zip</p>
                    </div>
                </li>
                <li class="tl-item">
                    <div class="item-title"><span class="text-dark">{{consigneeZip}}</span>
                        <p class="d-inline h5" style="margin-left: 4px;"> Destination Zip</p>
                    </div>
                </li>
            </ul>
            <div class="mb-2 row">
                <div class="col-7">
                    <div>Current Location</div>
                    <div class="grey-text">{{trackingData['currentLocation'] ? trackingData['currentLocation'] : '--'}}</div>
                </div>
                <div class="col-5 blue-text"><a (click)="viewDetails()" style="text-decoration: none; cursor: pointer;">&#43; View Details</a> </div>
            </div>
        </div>
    </div>
    <div class="grey-devider"></div>
    <div class="mb-2 row mt-2">
        <div class="col-7">Expected Delivery Date</div>
        <div class="col-5 text-right">{{trackingData['estimatedDeliveryDate'] ? trackingData['estimatedDeliveryDate'] :'--/--/--'}}</div>
    </div>
</ng-template>