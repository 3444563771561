import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
//import { environment  } from './../../../environments/environment.prod';
// import { environment  } from './../../../environments/environment.prod';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { ContactDetails } from './Models/contactDetails';


@Injectable({
  providedIn: 'root'
})
export class AddressBook {
  constructor(private http: HttpClient) { }
  getAddressBook(i, perPage, searchKey): any {

    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    if (searchKey != '') {
      return this.http.get(environment.apiPath + "/api/addressBook/getAll/" + i + "/" + perPage + "?searchKey=" + searchKey, { withCredentials: true });
    } else {
      return this.http.get(environment.apiPath + "/api/addressBook/getAll/" + i + "/" + perPage, { withCredentials: true });
    }
  }
  getAddressBookgetquote(i, perPage, searchKey , unique): any {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath + "/api/addressBook/getAll/" + i + "/" + perPage + "?searchKey=" + searchKey + "&uniqueCompany=" +unique, { withCredentials: true });
    
  }

  putTimingDetailsOfCompany(data) {
    let url = '/api/addressBook/timings/update';
    return this.http
      .put(environment.apiPath + url, data, { withCredentials: true })
      .map((res: Response) => {
        if (res) {
          if (res.status === 200) {
            return [{ status: res.status, json: res }]
          }
        }
      });
  }

  deleteContact(data){
    let url='/api/addressBook/contact/delete'
    return this.http
      .put(environment.apiPath + url, data, { withCredentials: true })
      .map((res: Response) => {
        if (res) {
          if (res.status === 200) {
            return [{ status: res.status, json: res }]
          }
        }
      });
  }

  addContact(contact: ContactDetails): Observable<ContactDetails> {
    let error;
    return this.http.post(environment.apiPath + '/api/addressBook/contact/add', contact, { withCredentials: true }).pipe(
      map(res => res),
      catchError(error)
    );
  }

  getContactDetailsById(compId) {
    return this.http.get(environment.apiPath + "/api/addressBook/getById?companyId=" + compId, { withCredentials: true });
  }

  deleteAddress(data) {
    return this.http
      .put(environment.apiPath + "/api/addressBook/contact/delete", data, { withCredentials: true })
      .map(res => res);

  }
  UpdateCompanyDetails(data, type) {
    if (type === 'company') {
      return this.http
        .put(environment.apiPath + "/api/addressBook/company/update", data, { withCredentials: true })
        .map(res => res);
    } else if (type === 'services') {
      return this.http
        .put(environment.apiPath + "/api/addressBook/accessorial/update", data, { withCredentials: true })
        .map(res => res);
    } else {
      return this.http
        .put(environment.apiPath + "/api/addressBook/pickupDelivery/update", data, { withCredentials: true })
        .map(res => res);
    }


  }


  getAddressDetails(id) {
    return this.http.get(environment.apiPath + "/sc/addr/detail/ws/get?id=" + id, { withCredentials: true });
  }
  addCompany(data) {
    return this.http.post(environment.apiPath + "/api/addressBook/company/add", data, { withCredentials: true });
  }

  setPrimaryContact(data) {
    return this.http
      .put(environment.apiPath + "/api/addressBook/contact/setAsPrimary", data, { withCredentials: true })
      .map(res => res);
  }

  setNotificationStatus(data) {
    return this.http
      .put(environment.apiPath + "/api/addressBook/contact/notificationStatus/update", data, { withCredentials: true })
      .map(res => res);
  }

  uploadImage(formData){
    const options = {responseType: 'text' as 'json'};
    return this.http.post(environment.apiPath+ "/api/addressBook/uploadImage",formData,{withCredentials:true,responseType: 'text' as 'json' }).pipe(map(response => {
      return response;
    })); 
  }
  updateImage(formData){
    return this.http.put(environment.apiPath+ "/api/addressBook/profileImage/update",formData,{withCredentials:true
    }).map(res => res);
  }
}