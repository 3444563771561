import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dimensional-weight-calculator',
  templateUrl: './dimensional-weight-calculator.component.html',
  styleUrls: ['./dimensional-weight-calculator.component.css']
})
export class DimensionalWeightCalculatorComponent implements OnInit {
  dimentionForm: FormGroup;
  weightInLBS: any;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.dimentionForm = this.formBuilder.group({
      'Length': new FormControl('', [
        Validators.required
      ]),
      'Width': new FormControl('', [
        Validators.required
      ]),
      'Height': new FormControl('', [
        Validators.required
      ])
    })
  }
  closeModal() { this.activeModal.close(); }

  submit() {

    let Length = parseInt(this.dimentionForm.controls['Length'].value);
    let Width = parseInt(this.dimentionForm.controls['Width'].value);
    let Height = parseInt(this.dimentionForm.controls['Height'].value);

    var volume
    volume = Length * Width * Height;

    this.weightInLBS = volume / 139;
  }
  reset(){
    this.weightInLBS='';
    this.dimentionForm.reset();
  }


}
