<div class="container" style="top: 15px;position: absolute;padding: 30px;">
    <span>
         <h4 style="font-size: 16px !important;">Freight Class Calculator
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()">
                    close
                    </span>
                </span>
             </button>
         </h4>
    </span>
    <div class="row mt-3">
        <form [formGroup]="frightClassForm" (ngSubmit)="getDensity()">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Length&nbsp;
                            <img src="assets/img/quotes/Inches.svg" class="m-0 text-center" width="25%">
                        </label>
                        <input formControlName="Length" type="number" min="1" class="form-control">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Width&nbsp;
                            <img src="assets/img/quotes/Inches.svg" class="m-0 text-center" width="25%">

                        </label>
                        <input formControlName="Width" type="number" min="1" class="form-control">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Height&nbsp;
                            <img src="assets/img/quotes/Inches.svg" class="m-0 text-center" width="25%">
                        </label>
                        <input formControlName="Height" type="number" min="1" class="form-control">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Weight&nbsp;
                            <img src="assets/img/quotes/Lbs.svg" class="m-0 text-center" width="25%">
                        </label>
                        <input formControlName="Weight" type="number" min="1" class="form-control">
                    </div>
                </div>

                <button class="btn orange text-white mt-3" style="text-transform: capitalize;" type="submit" [disabled]="!frightClassForm.valid">Calculate</button>
                <h4 class="mt-3">Results</h4>
                <div class="row mb-0">
                    
                    <div class="col">
                        Volume
                    </div>
                    <div class="col text-right">
                        {{ volume ? volume : '--' }}  Cu. Ft.
                    </div>
                    <hr>
                </div>
                <div class="row mb-0">
                    
                    <div class="col">
                        Density
                    </div>
                    <div class="col text-right">
                        {{ density ? density : '--' }}  lb/cu. Ft.
                    </div>
                    <hr>
                </div>
                <div class="row mb-0">
                    <div class="col">
                        Freight Class
                    </div>
                    <div class="col text-right">
                        {{ frightClass ? frightClass : '--' }} 
                    </div>
                </div>
                <div class="text-right mt-5">
                    <button type="button" class="btn faint-grey col-4" style="box-shadow: none;text-transform: capitalize;" (click)="reset()" >Reset</button>
                    &nbsp;&nbsp; <button  class="btn orange text-white col-3" style="box-shadow: none;text-transform: capitalize;" (click)="closeModal()" >Done</button>
                </div>
            </div>
        </form>
    </div>
</div>
