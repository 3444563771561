<div  style="top: 45px;left: 62px; position: absolute;padding: 20px; align-items: center; ">
    <img class="d-block m-auto" src="assets/img/payment/popup_icon.svg"  width="10%">
    <h3 class="text-center">EasyPost Shipment Labels. <small>(per Item)</small></h3>
      <div *ngFor=" let link of data['link'] ; let i = index " class="d-flex justify-content-center">
         <button class="btn m-1 p-2 commercial_invoice_btn  " (click)="getShipmentLabelUrl(i)">Download Shipment Label Item-{{i+1 }} </button>
   </div>
    <br>
    <div class="text-right">
        <button type="button" class="btn faint-grey " style="box-shadow: none;" (click)="close()">Cancel</button>
        <!-- &nbsp;&nbsp; <button type="button" class="btn orange text-white col-3" style="box-shadow: none;" (click)="navigate()">Yes</button> -->
    </div>
    
</div> 
<hr>
