import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from './../../app/services/common.service';
import { FreightClassCalculatorComponent } from 'src/app/_helpers/popup-dialog/quick-actions/freight-class-calculator/freight-class-calculator.component';
import { DimensionalWeightCalculatorComponent } from 'src/app/_helpers/popup-dialog/quick-actions/dimensional-weight-calculator/dimensional-weight-calculator.component';
import { CalculateDistanceComponent } from 'src/app/_helpers/popup-dialog/quick-actions/calculate-distance/calculate-distance.component';
import { ZipPostalCodesComponent } from 'src/app/_helpers/popup-dialog/quick-actions/zip-postal-codes/zip-postal-codes.component';
@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {
  webVersion;
userData:any;
navLeftObject:any;
notificationObject:any;
href = window.location.hash;
creditLimit:any;
siderbarToggle:any;
activeTabIndex:any;
notificationStatus:any;
  constructor(private router:Router,public commonService:CommonService,private modalService: NgbModal,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.navLeftObject = [{
      "label": "Favorites",
      "href": "#/favorite",
      "styleCode": "star",
      "title": "Favorites"
    },
    {
      "label": "Dashboard",
      "href": "#/dashboard",
      "styleCode": "dashboard",
      "title": "Dashboard"
    },
    {
      "label": "Get quote",
      "href": "#/get-quote",
      "styleCode": "get_quote",
      "title": "Get quote"
    },
    {
      "label": "Quote history",
      "href": "#/get-quote/quote-history",
      "styleCode": "quote_history",
      "title": "Quote history"
    },
    {
      "label": "Shipments",
      "href": "#/shipment",
      "styleCode": "shipments",
      "title": "Shipments"
    },
    {
      "label": "Address book",
      "href": "#/address-book",
      "styleCode": "address-book",
      "title": "Address book"
    },
    {
      "label": "Invoices",
      "href": "#/invoices",
      "styleCode": "invoices",
      "title": "Invoices"
    },
    {
      "label": "Shipcarte Pro ",
      "href": "javascript:void(0)",
      "styleCode": "shipcartepro",
      "title": "Shipcarte Pro"
    },
    {
      "label": "Integrations",
      "href": "javascript:void(0)",
      "styleCode": "integrations",
      "title": "Integrations"
    },
    {
      "label": "Reports",
      "href": "#/help/resources",
      "styleCode": "reports",
      "title": "Reports"
    },
    {
      "label": "Claims",
      "href": "javascript:void(0)",
      "styleCode": "claims",
      "title": "Claims"
    },
    {
      "label": "Resources",
      "href": "#/help/resources/0",
      "styleCode": "resources",
      "title": "Resources"
    }];
    this.notificationObject = [];
    this.getNotifications();
    //this.creditLimit['availableCreditLimit'] = 0; 
    this.siderbarToggle='';
    this.notificationStatus = false;

    
      this.webVersion =  environment.Uiversion;
      if(!localStorage.getItem('loggedInUserDetails')){
        localStorage.clear();
        this.router.navigate(['/signin']) 
      }

      this.userData = JSON.parse(localStorage.getItem('loggedInUserDetails'));
      this.getCreditData(); // first call

      // setInterval(() => {
      //   this.getCreditData();
      // }, 500000);
      
  }
  public setActiveTab(num) {
    this.activeTabIndex = num;
   console.log(this.activeTabIndex);
  }
  toggleSidebar()  {
  if(this.siderbarToggle == ''){
    this.siderbarToggle = 'collapsed';
  }else{
    this.siderbarToggle = '';
  }
  }  
  toggleSidebarMobile()  {
    if(this.siderbarToggle == ''){
      this.siderbarToggle = 'collapsedMob';
    }else{
      this.siderbarToggle = '';
    }
    }  
  getCreditData(){

    this.commonService.getCreditData().subscribe(result =>{
      if(result['availableCreditLimit']){
        this.creditLimit = result;
      }
    }, (error) => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    });

  }
  getNotifications(){
    this.notificationStatus= "Fetching Notifications... ";
    this.commonService.getNotificationData().subscribe(result =>{
      this.notificationObject = result;
      this.notificationStatus = false;
      if(this.notificationObject['unreadCount']){
         for (let index = 0; index <= this.notificationObject.notifications.length; index++) {
           var inputdata={ }
           inputdata["notificationId"]=this.notificationObject.notifications[index]['notificationId']
        this.commonService.markedRead(inputdata).subscribe(result=>{
        })
         }
         this.notificationObject['unreadCount']=0
      }
    }, (error) => {
      if (error['status'] === 404) {
        this.notificationStatus= "No Data Found ";
      }
    });

  }
  logout(){
    localStorage.clear();
    this.router.navigate(['/signin'])
  }

  redirect(path){
    if(path){
      this.router.navigate(['/'+path]) 
    }
  }
  
  open_modal(modal) {
    if(modal == 'freightmodal'){
      const modalRef = this.modalService.open(FreightClassCalculatorComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal2' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      }); 
      modalRef.result.then((result) => {
      });
    }else if(modal == 'dimensionmodal'){
      const modalRef = this.modalService.open(DimensionalWeightCalculatorComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal4' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.result.then((result) => {
     });
    }else if(modal == 'distancemodal'){
      const modalRef = this.modalService.open(CalculateDistanceComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.result.then((result) => {
     });
    }else if(modal == 'zipmodal'){
      const modalRef = this.modalService.open(ZipPostalCodesComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal5' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.result.then((result) => {
     });
    }
    

  }
  redirectToDhashboard(){
    this.router.navigate(['dashboard']);
  }
  redirectToGetQuote(){
    this.router.navigate(['get-quote']);
  }
  redirectToResources(tabIndex){
   
    this.router.navigate(['/help/resources', tabIndex]);
   
  }
}