import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from '@angular/router';
import { DocumentService } from './../services/document.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignupService } from './../services/sign-up/signup.service';
import { CommercialInvoiceService } from './../services/commercial-invoice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommercialInvoiceSignatureComponent } from 'src/app/_helpers/popup-dialog/commercial-invoice-signature/commercial-invoice-signature.component';
import { CommercialInvoiceConfirmationComponent } from 'src/app/_helpers/popup-dialog/commercial-invoice-confirmation/commercial-invoice-confirmation.component';
@Component({
  selector: 'app-commercial-invoice',
  templateUrl: './commercial-invoice.component.html',
  styleUrls: ['./commercial-invoice.component.css']
})
export class CommercialInvoiceComponent implements OnInit {
  quoteId:any;
  resultData:any;
  commercialInvoiceForm:FormGroup;
  displayLoader: boolean;
  signPopObject ={ signByName:"",signByRole:[""] }
  shipmentMode:any;
  shipmentModeId: any;
  countryStateListObj: any;
  countryListAry: any;
  shipmentValueTotal: any;
  todaysDate: any;
  selectedShipmentModeId: any;
  submitted = false;
  unitprice:any
  addrtoset:any;
  constructor(private toastr: ToastrService, public route: ActivatedRoute, public documentService:DocumentService, public fb:FormBuilder, public commercialInvoiceService:CommercialInvoiceService, 
    private modalService: NgbModal,
    public router:Router, private signupService: SignupService) { }

  ngOnInit(): void {

    this.displayLoader = true;
    this.quoteId = this.route.snapshot.paramMap.get('quoteId');
    this.getData(this.quoteId);
    this.shipmentValueTotal = 0;
    this.shipmentMode = [{
      "shipmentModeId": 1,
      "label": "LAND",
      "styleCode": "Frieght-Truck",
    },
    {
      "shipmentModeId": 2,
      "label": "AIR",
      "styleCode": "Plane",
    },
    {
      "shipmentModeId": 3,
      "label": "SEA",
      "styleCode": "Ship",
    }];

    this.shipmentModeId = 1;
    
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.todaysDate = yyyy + '-' + mm + '-' + dd;

    this.commercialInvoiceForm = this.fb.group({
      taxId:[null],
      recepientsTaxId:[null],
      strLine1: [null, [Validators.required]],
      companyName:['',[Validators.required]],
      city:[null,[Validators.required]],
      zip:[null,[Validators.required]],
      selectCountry:[null,[Validators.required]],
      selectState:[null,[Validators.required]],
      purchaserTaxId:['',[Validators.required]],
      purchaserRecepientsTaxId:['',[Validators.required]],
      dutiable:['yes',[Validators.required]],
      termsofsale:['',[Validators.required]],
      termsofpayment:['',[Validators.required]],
      hsCode:[''],
      unitPrice:[''],
      brokerCompanyName:[null,[Validators.required]],
      brokerContactName:[null],
      brokerPoneNumber:[null,[Validators.required]],
      brokerEmail:[null,[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      countryoforigin:[null,[Validators.required]],
      signDate: [this.todaysDate, Validators.required],
      accountNum :[''],
      sedNum:[''],
      isSameAsConsignee :[false]
    });


    this.signupService.getCountryList().subscribe(data => {
        if (data['success'].length) {
          this.countryListAry = data['success'];
          console.log(this.countryListAry)
          this.countryStateListObj = this.countryListAry[0]['provinceState'];
          //this.commercialInvoiceForm.controls['selectCountry'].setValue(this.countryListAry[0]['countryName']);
          //this.commercialInvoiceForm.controls['selectState'].setValue(this.countryListAry[0]['provinceState'][0]['code']);
        }
      
    });

  }

  get f() { return this.commercialInvoiceForm.controls; }


  handleAddressChange(event){
    this.addrtoset=
    console.log('handleAddressChange here',event.address_components)
    this.commercialInvoiceForm.controls['strLine1'].setValue(event.formatted_address)
    for (let key in event.address_components) {
        //country
      if (event.address_components[key]["types"][0] == "country") {
          this.commercialInvoiceForm.controls['selectCountry'].setValue(event.address_components[key]['long_name'])
          console.log("country",event.address_components[key]['long_name'])
          this.onCountrySelect(event.address_components[key]['long_name'])
        } 
        
        //select city
        if (event.address_components[key]["types"][0] == "locality" || event.address_components[key]["types"][0] == "sublocality_level_1") {
          this.commercialInvoiceForm.controls['city'].setValue(event.address_components[key]['long_name'])
        } 

      //select state
      if (event.address_components[key]["types"][0] == "administrative_area_level_1") {
          this.commercialInvoiceForm.controls['selectState'].setValue(event.address_components[key]['short_name'])
          console.log("state",event.address_components[key]['short_name'])
        } 

      //select postal_code
      if (event.address_components[key]["types"][0] == "postal_code") {
          this.commercialInvoiceForm.controls['zip'].setValue(event.address_components[key]['long_name'])
        }
  }
}

  onSubmit(formData){

    this.submitted = true;
    if(this.commercialInvoiceForm['status'] == "INVALID"){

      this.toastr.error("Please fill up all mandatory fields.", '', {
        timeOut: 5000,
      });
      return;
    }

    if(!this.signPopObject['signByName']){
      this.toastr.error("Please sign the form before submitting it.", '', {
        timeOut: 5000,
      });
      return;
    }

    

    const modalRef = this.modalService.open(CommercialInvoiceConfirmationComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
    modalRef.result.then((result) => {

      if(result['result'] == true){
        this.displayLoader = true;
        let productAry =[];
        for(let key in this.resultData['shipmentItems']){
          if('undefined'== typeof productAry[key]){
            productAry[key] ={}
          }
          productAry[key]['packaging'] = "Pallets";
          productAry[key]['itemId'] = this.resultData['shipmentItems'][key]['quoteItemId']
          productAry[key]['quantity'] = this.resultData['shipmentItems'][key]['qty'];
          productAry[key]['weight'] = this.resultData['shipmentItems'][key]['totalWeight'];
          productAry[key]['weightUnit'] = this.resultData['shipmentItems'][key]['wghtUnit'];
          productAry[key]['description'] = this.resultData['shipmentItems'][key]['description'];
          productAry[key]['hsCode'] = this.resultData['shipmentItems'][key]['hsTariffNumber'];
          productAry[key]['unitPrice'] = this.resultData['shipmentItems'][key]['unitprice'];
          productAry[key]['amount'] =this.resultData['shipmentItems'][key]['shipmentItemValue'];
          productAry[key]['currency'] = this.resultData['shipmentItems'][key]['shipmentItemValueCurrency'];
          productAry[key]['amount'].toString();
          productAry[key]['quantity'].toString();
          productAry[key]['weight'].toString();
        }
        
        let inputData = {
          "quoteId": this.quoteId,
          "orderId": (this.resultData['orderId'] == null )? "621356456132346512" :this.resultData['orderId'] ,
          "shipmentMode": "Land",
          "shipperAddress": {
              "company": this.resultData['shipperAddress']['company'],
              "addrLine1": this.resultData['shipperAddress']['addrLine1'],
              "city": this.resultData['shipperAddress']['city'],
              "state": this.resultData['shipperAddress']['state'],
              "country": this.resultData['shipperAddress']['country'],
              "zipcode": this.resultData['shipperAddress']['zipCode']
          },
          "consigneeAddress": {
              "company": this.resultData['consigneeAddress']['company'],
              "addrLine1": this.resultData['consigneeAddress']['addrLine1'],
              "city": this.resultData['consigneeAddress']['city'],
              "state": this.resultData['consigneeAddress']['state'],
              "country": this.resultData['consigneeAddress']['country'],
              "zipcode": this.resultData['consigneeAddress']['zipCode']
          },
          "taxId": formData['taxId'],
          "recipientsTaxId": formData['recepientsTaxId'],
          "dutiesAndTaxes": {
              "isDutiable": formData['dutiable'] == "yes" ? true : false ,
              "payee": ["Reciever", "Shipper"],
              "accountNum": this.commercialInvoiceForm.controls['accountNum'].value,
              "sedNum": this.commercialInvoiceForm.controls['sedNum'].value,
              "termsOfSale": formData['termsofsale'],
              "termsOfPayment": formData['termsofpayment']
          },
          "purchaser": {
              "isSameAsConsignee":this.commercialInvoiceForm.controls['isSameAsConsignee'].value,
              "companyName": formData['companyName'],
              "addressLine1": this.commercialInvoiceForm.controls['strLine1'].value,
              "city": formData['city'],
              "state": formData['selectState'],
              "zipCode": formData['zip'],
              "country": formData['selectCountry'],
              "taxId": formData['purchaserTaxId'],
              "recipientsTaxId": formData['purchaserRecepientsTaxId']
          },
          "freightCharges": "Prepaid(included)",
          "brokerId": "68451635416835461",
          "brokerName": this.commercialInvoiceForm.controls['brokerCompanyName'].value,
          "brokerEmail": this.commercialInvoiceForm.controls['brokerEmail'].value,
          "brokerPhone": this.commercialInvoiceForm.controls['brokerPoneNumber'].value,
          "countryOfOrigin": this.commercialInvoiceForm.controls['countryoforigin'].value,
          "products": productAry,
          "totalAmount": this.shipmentValueTotal,
          "signing": {
              "date": this.commercialInvoiceForm.controls['signDate'].value,
              "signByName": this.signPopObject['signByName'],
              "signByRole":  this.signPopObject['signByRole']
          }
        }

        
        this.commercialInvoiceService.saveData(inputData).subscribe((result) =>{

          localStorage.setItem('com_generated_' + this.quoteId,'true');

          
          if(result['documentId']!==null){
            localStorage.setItem('com_documentId_' + this.quoteId, result['documentId']);
          }

          this.displayLoader = false;
          this.toastr.success("Your commercial invoice created successfully.", 'Succes', {
            timeOut: 5000,
          });
    
          this.router.navigate(['/shipment/document/'+this.quoteId]);
    
        }, (error) => {
    
          this.displayLoader = false;
          this.toastr.error(error['error']['Message'], 'Error while creating invoice', {
            timeOut: 5000,
          });
    
        });
      }else{
        this.displayLoader = false;
       return;
      }
    });
    // if(this.commercialInvoiceForm['status'] == "INVALID"){

    //   this.toastr.error("Please fill up all mandatory fields.", '', {
    //     timeOut: 5000,
    //   });
    //   return;
    // }

    // if(!this.signPopObject['signByName']){
    //   this.toastr.error("Please sign the form before submitting it.", '', {
    //     timeOut: 5000,
    //   });
    //   return;
    // }

    // this.displayLoader = true;

    // let productAry =[];
    // for(let key in this.resultData['shipmentItems']){
    //   if('undefined'== typeof productAry[key]){
    //     productAry[key] ={}
    //   }
    //   productAry[key]['packaging'] = "Pallets";
    //   productAry[key]['quantity'] = this.resultData['shipmentItems'][key]['qty'];
    //   productAry[key]['weight'] = this.resultData['shipmentItems'][key]['totalWeight'];
    //   productAry[key]['weightUnit'] = this.resultData['shipmentItems'][key]['wghtUnit'];
    //   productAry[key]['description'] = this.resultData['shipmentItems'][key]['description'];
    //   productAry[key]['hsCode'] = "5555555";
    //   productAry[key]['unitPrice'] = "20";
    //   productAry[key]['amount'] =this.resultData['shipmentItems'][key]['shipmentItemValue'];
    //   productAry[key]['currency'] = this.resultData['shipmentItems'][key]['shipmentItemValueCurrency'];
    //   productAry[key]['amount'].toString();
    //   productAry[key]['quantity'].toString();
    //   productAry[key]['weight'].toString();
    // }
    
    // let inputData = {
    //   "quoteId": this.quoteId,
    //   "orderId": (this.resultData['orderId'] == null )? "621356456132346512" :this.resultData['orderId'] ,
    //   "shipmentMode": "Land",
    //   "shipperAddress": {
    //       "company": this.resultData['shipperAddress']['company'],
    //       "addrLine1": this.resultData['shipperAddress']['addrLine1'],
    //       "city": this.resultData['shipperAddress']['city'],
    //       "state": this.resultData['shipperAddress']['state'],
    //       "country": this.resultData['shipperAddress']['country'],
    //       "zipcode": this.resultData['shipperAddress']['zipCode']
    //   },
    //   "consigneeAddress": {
    //       "company": this.resultData['consigneeAddress']['company'],
    //       "addrLine1": this.resultData['consigneeAddress']['addrLine1'],
    //       "city": this.resultData['consigneeAddress']['city'],
    //       "state": this.resultData['consigneeAddress']['state'],
    //       "country": this.resultData['consigneeAddress']['country'],
    //       "zipcode": this.resultData['consigneeAddress']['zipCode']
    //   },
    //   "taxId": this.resultData['taxId'],
    //   "recipientsTaxId": this.resultData['recepientsTaxId'],
    //   "dutiesAndTaxes": {
    //       "isDutiable": formData['dutiable'] == "yes" ? true : false ,
    //       "payee": ["Reciever", "Shipper"],
    //       "accountNum": "56468845464",
    //       "sedNum": "7468745168354",
    //       "termsOfSale": formData['termsofsale'],
    //       "termsOfPayment": formData['termsofpayment']
    //   },
    //   "purchaser": {
    //       "companyName": formData['purchaserCompanyName'],
    //       "addressLine1": "dfvsdfv,sdfdvsd",
    //       "city": formData['city'],
    //       "state": formData['selectState'],
    //       "zipCode": formData['zip'],
    //       "country": formData['countryoforigin'],
    //       "taxId": formData['purchaserTaxId'],
    //       "recipientsTaxId": formData['purchaserRecepientsTaxId']
    //   },
    //   "freightCharges": "Prepaid(included)",
    //   "brokerId": "68451635416835461",
    //   "brokerName": this.commercialInvoiceForm.controls['brokerContactName'].value,
    //   "brokerEmail": this.commercialInvoiceForm.controls['brokerEmail'].value,
    //   "brokerPhone": this.commercialInvoiceForm.controls['brokerPoneNumber'].value,
    //   "countryOfOrigin": this.commercialInvoiceForm.controls['countryoforigin'].value,
    //   "products": productAry,
    //   "totalAmount": this.shipmentValueTotal,
    //   "signing": {
    //       "date": this.commercialInvoiceForm.controls['signDate'].value,
    //       "signByName": this.signPopObject['signByName'],
    //       "signByRole":  this.signPopObject['signByRole']
    //   }
    // }
 
    // this.commercialInvoiceService.saveData(inputData).subscribe(result =>{
    //   localStorage.setItem('com_generated_' + this.quoteId,'true')
    //   this.displayLoader = false;
    //   this.toastr.success("Your commercial invoice created successfully.", 'Succes', {
    //     timeOut: 5000,
    //   });

    //   this.router.navigate(['/shipment/document/'+this.quoteId]);

    // }, (error) => {

    //   this.displayLoader = false;
    //   this.toastr.error("Please try again", 'Error while creating invoice', {
    //     timeOut: 5000,
    //   });

    // });

  }

  getData(quoteId){

    this.documentService.getData(quoteId).subscribe(result=>{
      this.displayLoader = false;
      this.resultData = result;
      this.shipmentValueTotal = 0;
      if(result['shipmentItems'].length){
        for(let key in result['shipmentItems']){
          this.shipmentValueTotal += result['shipmentItems'][key].shipmentItemValue;
          this.resultData['shipmentItems'][key]['unitprice']=result['shipmentItems'][key].shipmentItemValue/result['shipmentItems'][key].qty
          // console.log(this.resultData['shipmentItems'][key]['shipmentItemValue'])
          // this.commercialInvoiceForm['controls']['unitPrice'].setValue( this.resultData['shipmentItems'][key]['shipmentItemValue'] ? ((this.resultData['shipmentItems'][key]['shipmentItemValue']) / (this.resultData['shipmentItems'][key]['qty'])) : '--')
        //  console.log(this.resultData['shipmentItems'][key]['unitprice'] )
        }
      }
      this.commercialInvoiceForm['controls']['countryoforigin'].setValue(this.resultData['shipperAddress']['country'])
      if (this.resultData['brokerName']) {
        this.commercialInvoiceForm['controls']['brokerCompanyName'].setValue(this.resultData['brokerName'])
      }
      if (this.resultData['brokerPhone']) {
        this.commercialInvoiceForm['controls']['brokerPoneNumber'].setValue(this.resultData['brokerPhone'])
      }
      if (this.resultData['brokerEmail']) {
        this.commercialInvoiceForm['controls']['brokerEmail'].setValue(this.resultData['brokerEmail'])
      }
    }, (error) => {

      this.displayLoader = false;
      if(error.status==401){
        localStorage.clear();
        this.router.navigate(['/signin']);
      }

    });
  }

  open_modal(action) {

    const modalRef = this.modalService.open(CommercialInvoiceSignatureComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal1' });
    if(action == 'add'){ modalRef.componentInstance.data = {}; modalRef.componentInstance.action = 'add' }else{modalRef.componentInstance.data = this.signPopObject ;  modalRef.componentInstance.action = 'edit'}
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.result.then((result) => {
     this.signPopObject['signByName'] = result['formData']['signByName'];
     if(result['formData']['commercial']){
      this.signPopObject['signByRole'] = ["Owner"];
     }else{
      this.signPopObject['signByRole'] = ["Agent"];
     }
    });

  }

  open_modal_confirm() {

    const modalRef = this.modalService.open(CommercialInvoiceConfirmationComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.result.then((result) => {
    
    });

  }
  purchaserSameAsConsigneeUpdate(event){
    console.log('state here',this.resultData['consigneeAddress']['state'],this.countryListAry[0]['countryName'])
    if(event == true){
      if (this.resultData['consigneeAddress']['country'] === 'CA') {
        this.commercialInvoiceForm.get("selectCountry").setValue(this.countryListAry[0]['countryName']);
      }
      if (this.resultData['consigneeAddress']['country'] ==='US') {
        this.commercialInvoiceForm.get("selectCountry").setValue(this.countryListAry[1]['countryName']);
      }
      if (this.resultData['consigneeAddress']['country'] ==='IN') {
        this.commercialInvoiceForm.get("selectCountry").setValue(this.countryListAry[2]['countryName']);
      }
      let streetLine = this.resultData['consigneeAddress']['fullAddr'] ;
      this.commercialInvoiceForm.controls['companyName'].setValue(this.resultData['consigneeAddress']['company']);
      this.commercialInvoiceForm.controls['strLine1'].setValue(streetLine);
      this.commercialInvoiceForm.controls['city'].setValue(this.resultData['consigneeAddress']['city']);
      this.commercialInvoiceForm.controls['zip'].setValue(this.resultData['consigneeAddress']['zipCode']);
      // this.commercialInvoiceForm.get("selectState").setValue(this.resultData['consigneeAddress']['state']);
      this.commercialInvoiceForm.controls['selectState'].setValue(this.resultData['consigneeAddress']['state']);
      this.commercialInvoiceForm.controls['purchaserTaxId'].setValue(this.commercialInvoiceForm.value.taxId);
      this.commercialInvoiceForm.controls['purchaserRecepientsTaxId'].setValue(this.commercialInvoiceForm.value.recepientsTaxId);

    }else{
      this.commercialInvoiceForm.controls['city'].setValue('');
      this.commercialInvoiceForm.controls['zip'].setValue('');
      this.commercialInvoiceForm.controls['selectCountry'].setValue('');
      this.commercialInvoiceForm.controls['selectState'].setValue('');
      this.commercialInvoiceForm.controls['companyName'].setValue('');
      this.commercialInvoiceForm.controls['strLine1'].setValue('');
      this.commercialInvoiceForm.controls['purchaserTaxId'].setValue('');
      this.commercialInvoiceForm.controls['purchaserRecepientsTaxId'].setValue('');
    }
  }

  onCountrySelect(selectedCountry){
    for(let key in this.countryListAry){
      if(this.countryListAry[key].countryName == selectedCountry){
        this.countryStateListObj = this.countryListAry[key].provinceState;
        // this.commercialInvoiceForm.controls['selectState'].setValue(this.countryStateListObj[0].code);
      }
    }
    console.log(this.commercialInvoiceForm.controls['selectCountry'].value,this.commercialInvoiceForm.controls['selectState'].value)
  }

  back(){
    this.router.navigate(['/shipment/document/'+this.quoteId]);
  }

  selectShipmentMode(shipmentModeId){
    this.shipmentModeId = shipmentModeId;
  }

  unitPricechange(){
    this.shipmentValueTotal=0
    for(let key in this.resultData['shipmentItems']){
      this.shipmentValueTotal += this.resultData['shipmentItems'][key]['unitprice']*this.resultData['shipmentItems'][key].qty;
      this.resultData['shipmentItems'][key].shipmentItemValue=this.resultData['shipmentItems'][key]['unitprice']*this.resultData['shipmentItems'][key].qty
    }
  }
}
