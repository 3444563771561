import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  quoteId:any;
  orderID:any;
  priceSummaryData:any;
  Charges=[];
  bolInvoiceDetails:any;
  bolResultData:any;
  dimensionBOL:any;
  totalItemBol:number;
  declaredBol:number;
  viewPOD:boolean=false;
  viewDetails:boolean=false;
  orderWeight:any;
  weightUnit:any;
  constructor(public http:HttpClient) { }
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };
  getCreditData(){
    return this.http.get(environment.apiPath + "/api/creditLimit/get", { withCredentials: true });
  }
  getNotificationData(){
    return this.http.get(environment.apiPath + "/api/notifications/getAll/0/5", { withCredentials: true });
  }
  markedRead(data){
    return this.http.put(environment.apiPath+ "/api/notifications/markAsRead", data ,this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  getAddressByZip(zipcode):any{
    return this.http.get(environment.apiPath + "/sc/google/pincode/ws/"+zipcode, { withCredentials: true });
  }
  getShipmentLabelUrls(orderId):any{
    return this.http.get(environment.apiPath + "/api/order/getLabel?orderId="+orderId, { withCredentials: true });
  }

  getDistance(){
    return this.http.get('https://maps.googleapis.com/maps/api/distancematrix/json?origins=Washington%2C%20DC&destinations=New%20York%20City%2C%20NY&units=imperial&key=AIzaSyB6UrbHNiACEp5nHr9sRGlb8CBCN152t-0');
  }
}
