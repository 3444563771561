import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment  } from './../../environments/environment.prod';
import { environment  } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  baseUrl:any;
  constructor(private http:HttpClient) { }

  generateBOLNumber(quoteId){
    return this.http.get(environment.apiPath+ "/api/bol/generateBOLNumber?quoteId="+quoteId,{ withCredentials: true });
  }

  getData(quoteId){
    return this.http.get(environment.apiPath+ "/api/bol/getData?quoteId="+quoteId,{ withCredentials: true });
  }

  sendEmail(dataObj){
    //const myheader = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    //const myheader = new HttpHeaders({});

    return this.http.post(environment.apiPath+ "/api/bol/sendEmail",dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  savePdf(obj){
    //const myheader = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    //const myheader = new HttpHeaders({ "Content-Type": "multipart/form-data" });
    const myheader = new HttpHeaders({});
    //const myheader = new HttpHeaders({ "Content-Type": undefined });
    
    //var endPoint = "http://solaceinfotech.co.in/al.php"
    var endPoint = environment.apiPath+ "/api/bol/savePDF";
    

    //return this.http.post(environment.apiPath+ "/api/bol/savePDF",obj ,{ withCredentials: true,
    return this.http.post(endPoint, obj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  saveCommercialPdf(obj){

    const myheader = new HttpHeaders({});
    return this.http.put(environment.apiPath + "/api/commercialInvoice/savePDF", obj, {
    headers: myheader, withCredentials: true
  }).pipe(map(response => {
      return response;
    }));
  }

  sendCommercialEmail(dataObj){
    console.log('sendCommercialEmail here',dataObj)
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/api/commercialInvoice/sendEmail",dataObj ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));
  }

  
}
