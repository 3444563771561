import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.css']
})
export class ViewReceiptComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  @Input() data;
  formattedDate:any;
  printInvoice:boolean;
  ngOnInit(): void {
    console.log('view Recipt data',this.data)
    this.formattedDate = formatDate(this.data.orderDate, 'dd/MM/yyyy', 'en-US');

  }

  
  printComponent() {
   
    window.print();

    
}

  closeModal() { this.activeModal.close(); }
}
