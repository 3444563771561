import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: 'signup', pathMatch:'full'},
  // { path: 'signup',loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: '', redirectTo: 'signin', pathMatch:'full'},
  { path: 'signin',loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  {path:'forget-password',loadChildren:()=>import('./signup/forget-password/forget-password-routing.module').then(m=>m.ForgetPasswordRoutingModule)},
  { path: 'checkout',loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'get-quote',loadChildren: () => import('./get-quote/get-quote.module').then(m=>m.GetQuoteModule)},
  { path: 'get-quote/:quoteId',loadChildren: () => import('./get-quote/get-quote.module').then(m=>m.GetQuoteModule)},
  { path: 'reset-password/:token',loadChildren: () => import('./signup/reset-password/reset-password-routing.module').then(m=>m.ResetPasswordRoutingModule)},
  { path: 'dashboard',loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path:'manage-carrier',loadChildren:()=>import ('./Admin/manage-carrier/manage-carrier.module').then(m => m.ManageCarrierModule)},
  {path:'shipment/document/:quoteId',loadChildren:()=>import('../app/shipment/document/document.module').then(m=>m.DocumentModule)},
  {path:'address-book',loadChildren:()=>import('./address-book/address-book.module').then(m=>m.AddressBookModule)},
  {path:'commercial-invoice/:quoteId',loadChildren:()=>import('../app/commercial-invoice/commercial-invoice.module').then(m=>m.CommercialInvoiceModule)},
  {path:'shipment',loadChildren:()=>import('../app/shipment/shipment.module').then(m=>m.ShipmentModule)},
  {path:'user-preferences',loadChildren:()=>import('../app/user-preferences/user-preferences.module').then(m=>m.UserPreferencesModule)},
  {path:'help',loadChildren:()=>import('../app/help/help.module').then(m=>m.HelpModule)},
  { path: 'favorite',loadChildren: () => import('./favorite/favorite.module').then(m => m.FavoriteModule)},
  { path: 'invoices',loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule)},
  { path: 'reports',loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)}
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
