import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact-shipper',
  templateUrl: './contact-shipper.component.html',
  styleUrls: ['./contact-shipper.component.css']
})
export class ContactShipperComponent implements OnInit {
  addressForm:FormGroup;
  email:any;
  firstname:any;
  lastname:any;
  mobnmber:any;
  Ext:any;
  Position:any;
  pattern: string | "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(public activeModal: NgbActiveModal,private formBuilder:FormBuilder) { }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      'firstName': new FormControl('', [
        Validators.required, 
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
    ]),
      'lastName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
    ]),
      'email':new FormControl('',[
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')
    ]),
      'phone':new FormControl('',[
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
    ]),
      'Ext':new FormControl(''),
     'Position':new FormControl('')
})
  }

  submit(){  
      this.email=this.addressForm.value;
      this.firstname=this.addressForm.value.firstName;
      this.lastname=this.addressForm.value.lastName;
      this.mobnmber=this.addressForm.value.phone
      this.Ext=this.addressForm.value.Ext
      this.Position=this.addressForm.value.Position
      this.activeModal.close(this.addressForm.value);
  } 
  closeModal() { this.activeModal.close(); }


}
