import { Component, Input, OnInit } from '@angular/core';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{Router} from "@angular/router"
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-download-label',
  templateUrl: './download-label.component.html',
  styleUrls: ['./download-label.component.css']
})
export class DownloadLabelComponent implements OnInit {
  @Input() data;
  constructor(private ser:NgbModal, private rt:Router ,private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  getShipmentLabelUrl(i){
        // this.isLabelUrl=this.sanitizer.bypassSecurityTrustResourceUrl(result['link'])
        window.open(this.data['link'][i],'_blank').focus(); 
     
  }
  close(){
    this.ser.dismissAll();
    }

  // navigate(){
  //   this.rt.navigateByUrl("user-preferences");
  //   this.ser.dismissAll();
  // }
}



