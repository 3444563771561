<div class="container">
	<div class="row">
		<div class="col1">
			<app-leftsidebar></app-leftsidebar>
		</div>
            <form class="col11 bg-light" [formGroup]="commercialInvoiceForm" (ngSubmit)="onSubmit(commercialInvoiceForm.value)" #f="ngForm" novalidate>			
                <div class="page_heading_fixed_strip">
                    <h3 class="page_heading">Commercial Invoice</h3>
                </div>    
                <div class="row get-quote shipmode_top_box mt-5 pt-5 pl-4 ml-3 mb-0">
                    <div class=" col-12 shipmode_section bg-light ml-0 pt-4 mr-5">
                        <h4 class="m-0 pl-2 page-tital">Shipment Mode* <span class="grey-text">(Select One)</span></h4>
    
                        <div class="row menu pl-3">
                            <div class="selection-wrapper col-2 m-2 p-0 showSingle" *ngFor="let mode of shipmentMode">
                                <label for="selected-item-1" [ngClass]="mode.shipmentModeId == shipmentModeId ? 'selected-width selected-label activeOutline': 'selected-width selected-label' "  (click) ="selectShipmentMode(mode.shipmentModeId)">
                                    <span class="icon"></span>
                                    <div class="selected-item-1" >
                                        <img src="assets/img/commercial/{{mode.styleCode}}.svg" width="60px" class="m-2 text-left">
                                        <p> {{mode.label}}</p>
                                    </div>
                                </label>
                            </div>
                        </div>
    
                        <div class="row mt-2 pl-4 mb-0">
                            <div class="card col-11 p-0">
                                <div class="card-header bg-white">
                                    <h4 class="p-1 m-0">Commercial Invoice</h4>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-5">
                                            <h4>Shipper</h4>
                                            <div class="mt-2">
                                                <div class="grey-text"><p>Company</p></div>
                                                <div><p>{{resultData && resultData.shipperAddress?.company}}</p></div>
                                            </div>
                                            <div class="mt-2"> 
                                                <div class="grey-text"><p>Street line-1</p></div>
                                                <div><p>{{resultData && resultData['shipperAddress']?.addrLine1}} {{resultData && resultData['shipperAddress']?.addrLine2}}</p></div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div class="grey-text"><p>City</p></div>
                                                    <div><p>{{resultData && resultData['shipperAddress']?.city}}</p></div>
                                                </div>
                                                <div class="col">
                                                    <div class="grey-text"><p>Zip/ Postal Code</p></div>
                                                    <div><p>{{resultData && resultData['shipperAddress']?.zipCode}}</p></div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div class="grey-text"><p>Country</p></div>
                                                    <div><p>{{resultData && resultData['shipperAddress']?.country}}</p></div>
                                                </div>
                                                <div class="col">
                                                    <div class="grey-text"><p>State / Province</p></div>
                                                    <div><p>{{resultData && resultData['shipperAddress']?.state}}</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1"></div>
                                        <div class="col-5 consignee_details_box">
                                            <h4>Consignee<label class=""></label></h4>
                                            <div>
                                                <div class="mt-2">
                                                    <div class="grey-text"><p>Company</p></div>
                                                    <div><p>{{resultData && resultData['consigneeAddress']?.company}}</p></div>
                                                </div>
                                                <div class="mt-2">
                                                    <div class="grey-text"><p>Street line-1</p></div>
                                                    <div><p>{{resultData && resultData['consigneeAddress']?.addrLine1}} {{resultData && resultData['consigneeAddress']?.addrLine2}}</p></div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div class="grey-text"><p>City</p></div>
                                                        <div><p>{{resultData && resultData['consigneeAddress']?.city}}</p></div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="grey-text"><p>Zip/ Postal Code</p></div>
                                                        <div><p>{{resultData && resultData['consigneeAddress']?.zipCode}}</p></div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div class="grey-text"><p>Country</p></div>
                                                        <div><p>{{resultData && resultData['consigneeAddress']?.country}}</p></div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="grey-text"><p>State / Province</p></div>
                                                        <div><p>{{resultData && resultData['consigneeAddress']?.state}}</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div style="display: none;">
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div><p>Company Name *</p></div>
                                                        <div>
                                                            <select class="custom-select">
                                                                <option>Shipcarte</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div><p class="d-inline">Street line 1 *</p><span class="float-right d-inline">
                                                            <span>Google Maps</span>
                                                            <img src="assets/img/quotes/google-maps.svg"
                                                                class="location_icon m-2 text-center" width="10%">
                                                        </span></div>
                                                        <div>
                                                            <input class="form-control" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" />

                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div class="row mt-3">
                                                    
                                                    <div class="col">
                                                        <div><p>City *</p></div>
                                                        <div><input type="text" class="form-control" ></div>
                                                    </div>
                                                    <div class="col">
                                                        <div><p>Zip / Postal Code</p></div>
                                                        <div><input type="text" class="form-control" ></div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    
                                                    <div class="col">
                                                        <div><p>Enter Country</p></div>
                                                        <div>
                                                            <select class="custom-select">
                                                                <option>Canada</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div><p>State / Province *</p></div>
                                                        <div>
                                                            <select class="custom-select">
                                                                <option>United States</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div><p>Tax ID</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Enter Tax Id" formControlName="taxId">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['taxId'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['taxId'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align">Tax id is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div><p>Recipients Tax ID</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Enter Recipients Tax Id" formControlName="recepientsTaxId">
                                                        <div class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['recepientsTaxId'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['recepientsTaxId'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Recepients tax id is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grey-devider "></div>
                                    </div>
                                    <div class="row">
                                        
                                        <div class="col-5">
                                            <div class="row mt-2">
                                                <h4>Duties and Taxes Consignee</h4>
                                                <div><p>Dutiable</p></div>
                                                <div>
                                                    <select class="custom-select" formControlName="dutiable">
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    <!-- <input type="text" class="form-control" placeholder="Enter Tax Id" value="No" readonly> -->
                                                </div>
                                               <br>
                                                <label class="">
                                                    <input type="checkbox" checked="true" >
                                                    <span class="checkmark"></span><span class="checkmark-lable">&nbsp; Receiver</span>
                                                </label>
                                                <br>
                                                <label class="">
                                                    <input type="checkbox" >
                                                    <span class="checkmark"></span>
                                                    <span class="checkmark-lable ">&nbsp; Shipper</span>
                                                </label>
                                                <br>
                                                <label class="">
                                                    <input type="checkbox" >
                                                    <span class="checkmark"></span>
                                                    <span class="checkmark-lable ">&nbsp; Consignee</span>
                                                </label>
                                            </div>
                                                <div class="row mt-3">
                                                    <!-- <div class="col-2"></div> -->
                                                    <div class="col">
                                                        <div class="grey-text"><p>Account #</p></div>
                                                        <div><input type="text" class="form-control" formControlName="accountNum"  placeholder="Enter Account #"></div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="grey-text"><p>SED #</p></div>
                                                        <div><input type="text" class="form-control" formControlName="sedNum" placeholder="Enter SED #"></div>
                                                    </div>
                                                </div>
                                            <div class="row mt-3">
                                                
                                                <div class="col">
                                                    <div><p>Terms of Sale *</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Enter Terms of Sale" formControlName="termsofsale">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['termsofsale'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['termsofsale'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Terms of sale is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div><p>Terms of Payment *</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Enter Terms of Payment" formControlName="termsofpayment">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['termsofpayment'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['termsofpayment'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Terms of payment is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1"></div>
                                        <div class="col-5 consignee_details_box">
                                           
                                            <h4>Purchaser   <label class="">
                                                <input type="checkbox" formControlName="isSameAsConsignee"  #purchaserSameAsConsignee (change)="purchaserSameAsConsigneeUpdate(purchaserSameAsConsignee.checked)">
                                                <span class="checkmark"></span>&nbsp; Same as consignee
                                            </label></h4>
                                            <div>
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div><p>Company Name *</p></div>
                                                        <div>
                                                            <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="companyName">
                                                            <div *ngIf="f.submitted" class="password-label">
                                                                <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['companyName'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                                  <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['companyName'].hasError('required')  ? 'clear' :
                                                                    'done' }}</i>
                                                                  <small class="error-align"> Company Name is required</small>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div class="row mt-2">
                                                    
                                                    <div class="col">
                                                        <div>
                                                            <p class="d-inline">Street line 1 *</p>
                                                            <span class="float-right" style="display:none;">
                                                                <span>Google Mapsss</span>
                                                                <img src="assets/img/quotes/google-maps.svg" class="location_icon m-2 text-center" width="10%">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <input class="form-control" formControlName="strLine1" ngx-google-places-autocomplete  (onAddressChange)="handleAddressChange($event)"/>
                                                            <div *ngIf="f.submitted" class="password-label">
                                                                <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['strLine1'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                                    <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['strLine1'].hasError('required')  ? 'clear' :
                                                                      'done' }}</i>
                                                                    <small class="error-align"> Street line 1 is required</small>
                                                                  </label>
                                                            </div>
                                                           

                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div class="row mt-3">
                                                    
                                                    <div class="col">
                                                        <div><p>City *</p></div> 
                                                        <div>
                                                            <input type="text" class="form-control" formControlName="city">
                                                            <div *ngIf="f.submitted" class="password-label">
                                                                <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['city'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                                  <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['city'].hasError('required')  ? 'clear' :
                                                                    'done' }}</i>
                                                                  <small class="error-align"> City is required</small>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div><p>Zip / Postal Code *</p></div>
                                                        <div>
                                                            <input type="text" class="form-control" formControlName="zip">
                                                            <div *ngIf="f.submitted" class="password-label">
                                                                <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['zip'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                                  <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['zip'].hasError('required')  ? 'clear' :
                                                                    'done' }}</i>
                                                                  <small class="error-align"> Zipcode is required</small>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    
                                                    <div class="col">
                                                        <div><p>Enter Country *</p></div>
                                                        <div>
                                                            <select #purchaserCountry class="custom-select" formControlName="selectCountry" (change)="onCountrySelect(purchaserCountry.value)">

                                                                <option *ngFor="let obj of countryListAry" [value]="obj.countryName">{{obj.countryName}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div><p>State / Province *</p></div>
                                                        <div>
                                                            <select class="custom-select" formControlName="selectState" >

                                                                <option *ngFor="let obj of countryStateListObj" [value]="obj.code">{{obj.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display:none">
                                                <div class="mt-2">
                                                    <div class="grey-text"><p>Company</p></div>
                                                    <div><p>Amazing C/O SOURCE ONE MEDICAL SUPPLY</p></div>
                                                </div>
                                                <div class="mt-2">
                                                    <div class="grey-text"><p>Street line-1</p></div>
                                                    <div><p>8-120 West Beaver Creek Road</p></div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div class="grey-text"><p>City</p></div>
                                                        <div><p>Richmond Hill</p></div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="grey-text"><p>Zip/ Postal Code</p></div>
                                                        <div><p>L4WN2</p></div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col">
                                                        <div class="grey-text"><p>Country</p></div>
                                                        <div><p>United States</p></div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="grey-text"><p>State / Province</p></div>
                                                        <div><p>Ontorio</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div><p>Tax ID *</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Enter Tax Id" formControlName="purchaserTaxId">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['purchaserTaxId'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['purchaserTaxId'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Purchaser tax id is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div><p>Recipients Tax ID *</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Enter Recipients Tax Id" formControlName="purchaserRecepientsTaxId">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['purchaserRecepientsTaxId'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['purchaserRecepientsTaxId'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Purchaser receipients tax id is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="grey-devider "></div>
                                    </div>
                                    <div class="row">
                                        
                                        <div class="col-5">
                                            <div class="col mt-2">
                                                <div class="mb-2"><p>Freight Charges</p></div>
                                               
                                                <label class="">
                                                    <input type="checkbox" checked="true" >
                                                    <span class="checkmark"></span><span class="checkmark-lable">&nbsp; Prepaid (Included)</span>
                                                </label>
                                                <br>
                                                <label class="">
                                                    <input type="checkbox" >
                                                    <span class="checkmark"></span><span class="checkmark-lable">&nbsp; Prepaid (Not included)</span>
                                                </label>
                                                <br>
                                                <label class="">
                                                    <input type="checkbox" >
                                                    <span class="checkmark"></span><span class="checkmark-lable">&nbsp; Collect</span>
                                                </label>
                                            </div>
                                            
                                            <div class="col mt-4">
                                                <div class="mb-2"><p>Parties to this Transaction are:</p></div>
                                               
                                                <label class="">
                                                    <input type="checkbox" checked="true">
                                                    <span class="checkmark"></span><span class="checkmark-lable">&nbsp; Related</span>
                                                </label>
                                                <br>
                                                <label class="">
                                                    <input type="checkbox" >
                                                    <span class="checkmark"></span><span class="checkmark-lable">&nbsp; Not related</span>
                                                </label>
                                                <br>
                                                
                                            </div>
                                        </div>
                                        <div class="col-1"></div>
                                        <div class="col-5 consignee_details_box">
                                           
                                            <h4>Custom Broker</h4>
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div><p>Broker</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="search" value="{{resultData && resultData?.brokerName}}">
                                                    </div>
                                                </div>
                                               
                                            </div>
                                           
                                            <div class="row mt-3">
                                                
                                                <div class="col">
                                                    <div><p>Company Name *</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="Company Name" formControlName="brokerCompanyName" value="{{resultData && resultData?.brokerCompanyName}}">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['brokerCompanyName'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['brokerCompanyName'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Broker company is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div><p>Contact Name </p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="" formControlName="brokerContactName" >
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['brokerContactName'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['brokerContactName'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align">Broker contact name is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                
                                                <div class="col">
                                                    <div><p>Phone Number *</p></div>
                                                    <div>
                                                        <input type="text" class="form-control" placeholder="" formControlName="brokerPoneNumber" value="{{resultData && resultData?.brokerPhone }}">
                                                        <div *ngIf="f.submitted" class="password-label">
                                                            <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['brokerPoneNumber'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                              <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['brokerPoneNumber'].hasError('required')  ? 'clear' :
                                                                'done' }}</i>
                                                              <small class="error-align"> Broker phone is required</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div><p>Email *</p></div>
                                                    <div>
                                                        <input type="email" class="form-control" placeholder="" value="{{resultData && resultData?.brokerEmail }}" formControlName="brokerEmail" [ngClass]="commercialInvoiceForm.controls['brokerEmail'].touched || commercialInvoiceForm.controls['brokerEmail'].dirty ? 'is-invalid' : 'is-valid'"
                                                        required >
                                                    
                                                        <!-- <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" formControlName="email" [ngClass]="sendEmail.controls['email'].touched || sendEmail.controls['email'].dirty ? 'is-invalid' : 'is-valid'"
                                                        required > -->
                                                        <div *ngIf="f.submitted" class="password-label">
                                                        <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['brokerEmail'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                            <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['brokerEmail'].hasError('required')  ? 'clear' :
                                                              'done' }}</i>
                                                            <small class="error-align">Email is required</small>
                                                          </label>

                                          
                                                        <div class="password-label" *ngIf="((commercialInvoiceForm.controls['brokerEmail'].dirty || commercialInvoiceForm.controls['brokerEmail'].touched) && commercialInvoiceForm.controls['brokerEmail'].invalid) ? 'is-invalid' : ''">
                                                          
                                                          <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['brokerEmail'].hasError('pattern')  ? 'text-danger1' : 'd-none'">
                                                            <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['brokerEmail'].hasError('pattern')  ? 'clear' :
                                                              'done' }}</i>
                                                            <small class="error-align">Valid Email is required</small>
                                                          </label>
                                                         </div>
                                                         </div>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                        </div>
                                        <div class="grey-devider "></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <h3>Country Of Origin</h3>
                                            <select class="custom-select" formControlName="countryoforigin">
                                                <option *ngFor="let countryOfOrigin of countryListAry" [value]="countryOfOrigin.countryName">{{countryOfOrigin.countryName}}</option>
                                            </select>
                                            <div *ngIf="f.submitted" class="password-label">
                                                <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['countryoforigin'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                  <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['countryoforigin'].hasError('required')  ? 'clear' :
                                                    'done' }}</i>
                                                  <small class="error-align"> Country of origin is required</small>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-8">&nbsp;</div>
                                    </div>
                                    <div class="grey-devider"></div>
                                    <div class="row">
                                        
                                        <div class="shipment_items_section bg-white">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h5 style="color: #303447;
                                                    font-size: 16px !important;
                                                    font-weight: 500 !important;">Product</h5>
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Packaging</th>
                                                                <th scope="col">Piece Count</th>
                                                                <th scope="col">Total Weight <img src="../assets/img/quotes/Lbs.svg" class="m-0 text-center" width="15"></th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">HS Code</th>
                                                                <th scope="col">Unit Price</th>
                                                                <th scope="col">Amount</th>
                                                            
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                           
                                                            <tr *ngFor="let keyData of resultData?.shipmentItems;;let i=index">
                                                                <td><img src="assets/img/quotes/pallet.svg" style="margin:0px 7px 0px 0px;">
                                                                    Pallete
                                                                </td>
                                                                <td class="piece_count">{{keyData.qty}}</td>
                                                                <td class="total_weight">{{keyData.totalWeight}}</td>
                                                                <td class="description">{{keyData.description}}</td>
                                                                <td class="hs_code"><input type="text" class="form-control col-6" [(ngModel)]="keyData.hsTariffNumber" formControlName="hsCode"  value="" ></td>
                                                                <td class="unit_price"><input type="number" class="form-control col-6" [(ngModel)]="keyData.unitprice"  (change)="unitPricechange()" [ngModelOptions]="{standalone: true}"   ></td>
                                                                <td style="width: 9%;">{{keyData.shipmentItemValue ? (keyData.shipmentItemValue) : '--'}}</td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>
                                                    <div class="row">
                                                        <div class="text-right">Total: ${{shipmentValueTotal}} {{resultData && resultData['shipmentItems'][0]?.shipmentItemValueCurrency}}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-8"></div>
                                                        <div class="col-4">
                                                            <h4>Terms:</h4>
                                                            <p>I hereby certify that the information given above is true and complete in every respect</p>
                                                            <div class="row mt-2">
                                                                <div class="col">
                                                                    <div class="grey-text1">Date</div>
                                                                    <div class="input-group mt-1">

                                                                        <input type="date" id="pickupDate"
                                                                            class="form-control input-group col-10 m-0 p-0 "
                                                                            name="dp" formControlName="signDate" value="{{this.todaysDate}}"
                                                                            />

                                                                        <button class="btn btn-secondary datep" type="button"
                                                                            style="height: 32px !important; z-index: 100 !important;"
                                                                            >
                                                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                                                        </button>
                                                                        <div *ngIf="f.submitted" class="password-label">

                                                                        <label class="col-12 p-0" [ngClass]="commercialInvoiceForm.controls['signDate'].hasError('required')  ? 'text-danger1' : 'd-none'">
                                                                            <i style="float: left;" class="material-icons">{{ commercialInvoiceForm.controls['signDate'].hasError('required')  ? 'clear' :
                                                                                    'done' }}</i>
                                                                            <small class="error-align">Please select valid sign date</small>
                                                                        </label>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col" *ngIf="signPopObject['signByName']">
                                                                    <div class="grey-text1">{{signPopObject['signByRole']}}</div>
                                                                    <div style="line-height:40px">{{signPopObject['signByName']}}</div>
                                                                </div>
                                                                <div class="col" *ngIf="!signPopObject['signByName']">
                                                                    <a href="javascript:void(0);" class="btn submit_btn col-12 white black-text mt-4" (click)="open_modal('add')">Sign</a>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="signPopObject['signByName']">
                                                                <div  style="border: #ccc 1px dashed;padding: 20px;
                                                            font-size: 40px;font-family: 'WindSong', cursive; font-weight: normal; color:#080808; text-align: center;">
                                                                    {{signPopObject['signByName']}}
                                                                </div>
                                                                <div class="text-right blue-text" (click)="open_modal('edit')">
                                                                    <img src="assets/img/commercial/edit.svg"> Edit</div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
    
                        
                        </div>
                    </div>
                </div>
                <div class="row bg-light m-0 p-0">
                    <div class="" style="margin-bottom: 40px;">
                        <p class="grey-text1 mt-2 pl-5 ml-1 d-inline"> &copy; 2021 ShipCarte. All Rights Reserved</p>
                    
                        <span class="ml-1 mt-1 mb-5 d-inline">
                            <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                            </a>
                        </span>
                    </div>
                </div>
                <div class="row pl-3 m-0 BOL fixmebottom ">
                    <div class=" col-12 pl-4  pt-1 ">
                        <div class="row m-0">
                            <div class="col-6 pl-3 ">
                               
                            </div>
                            <div class="col-6 text-right">
                                <button class="cancel_btn btn warning m-1 col-3" type="button" (click)="back()">Cancel</button>
                                <button class="btn submit_btn white black-text col-3 m-1"  type="submit">Submit</button>
                            </div>
                        </div>
                    </div>
            
                
                </div>
            </form>
    </div>
</div>

<div class="container" [ngClass]="displayLoader ? 'showClass' : 'hideClass'">
    <div class="overlay-box" style="color: #000; position: fixed; width: 100%; background: #fff; height: 100%; top: 0; left: 0; bottom: 0; right: 0; z-index: 999999; opacity: 0.8;">
        <div class="payment_status_popup" style="width: 460px;margin: 0 auto;text-align: center;position: absolute; left: 0;right: 0;top: 30%;">
            <img src="/assets/img/quotes/loader.svg" alt="">
            <h2 class="mt-3" style=" font-size: 20px !important; color: #151522; margin: 0; padding: 0; font-weight: 600 !important; text-align: center;">Loading your options, please wait… </h2>
        </div>

    </div>
</div>