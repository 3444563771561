import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment  } from './../../../environments/environment.prod';
import { environment  } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ShimentSummryService {
    baseUrl:any;
    constructor(private http:HttpClient) { }
  
    // getStartSummary(){
    //   const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    //   return this.http.get(environment.apiPath+ "/sc/quote/extra/services/get/all?quoteId=a817f5ac-80aa-436d-9ca1-d20ed48d0d2c&locType=start" ,{ withCredentials: true,
    //     headers: myheader
    //     }).pipe(map(response => {
    //     return response;
    //   }));  
    // }
    // getEndSummary(){
    //     const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    //     return this.http.get(environment.apiPath+ "/sc/quote/extra/services/get/all?quoteId=a817f5ac-80aa-436d-9ca1-d20ed48d0d2c&locType=end" ,{ withCredentials: true,
    //       headers: myheader
    //       }).pipe(map(response => {
    //       return response;
    //     }));  
    //   }

      getAddressSummary(id){
        const myheader = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.apiPath+ "/sc/addr/detail/ws/get?id="+id ,{ withCredentials: true,
          headers: myheader
          }).pipe(map(response => {
          return response;
        }));  
      }

      quoteDetails(quoteID){
        const myheader = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.apiPath+ "/sc/quote/ws/get?quoteId="+quoteID ,{ withCredentials: true,
          headers: myheader
          }).pipe(map(response => {
          return response;
        }));     
      }

      summaryColabesDetails(quoteID){
        const myheader = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(environment.apiPath+ "/sc/quote/item/ws/get/all/0/20?quoteId="+quoteID ,{ withCredentials: true,
          headers: myheader
          }).pipe(map(response => {
          return response;
        }));     
      }
  }