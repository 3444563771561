import { Component, OnInit } from '@angular/core';
import {CommonService} from 'src/app/services/common.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-com-inv',
  templateUrl: './view-com-inv.component.html',
  styleUrls: ['./view-com-inv.component.css']
})
export class ViewComInvComponent implements OnInit {

  BOLd:any;
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  constructor(public common:CommonService,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.resultData=this.common.bolResultData;
    this.BOLd=this.common.bolInvoiceDetails;
    this.dynamicDimension=this.common.dimensionBOL;
    this.declaredValue=this.common.declaredBol;
    this.totalItem=this.common.totalItemBol;
  }

  closeModal() { this.activeModal.close(); }

}
