<!-- 
    <div  style="top: 15px;position: absolute;padding: 35px;">
        <img class="d-block m-auto" src="assets/img/hand.svg"  width="15%">
        <h3 class="text-center">Would you like to add this order to your favorite order list?</h3>
        
        <div class="m-auto text-center">
            <input style="pointer-events: fill;top: 3px;
            position: relative;" type="checkbox" #checkbox (click)="notshowagain(checkbox.checked)" />
			<label for="checkbox" style="text-align: center;margin-left: 15px;">Do not show this again</label>
        </div>
        <br>
        <div class="text-right">
            <button type="button" class="btn faint-grey col-4" style="box-shadow: none;" (click)="closeModal()">No Thanks</button>
            &nbsp;&nbsp; <button type="button" class="btn orange text-white col-3" style="box-shadow: none;" (click)="addModal()" >Yes</button>
        </div>
        
    </div>  -->

        <div style="margin: 7px;">
            <div class="row" style="margin-top: 30px; ">
                <div class="col-8">
                    <h5 style="margin-top: 5px !important; font-size: 18px !important; font-weight: 600 !important; font-family: poppins,sans-serif !important; opacity: 0.8;">Saving to Favorites</h5>

                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form>
                        <div class="form-group">
                            <label style="color: black !important;; font-size: 12px !important; font-weight: 600 !important; font-family: poppins, sans-serif !important; opacity: 0.7;">Favorite order name</label>
                            <input type="text" (change)="update($event.target.value)" placeholder="Enter Favorite order name" style="height: 23px !important;width: 330px; border: 1px solid #CED0DA; padding-left: 5px !important;font-weight: 400;font-family: poppins,sans-serif; ">
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" style="background-color: white;">
                <div class="col-12 text-end">
                <button style="width: fit-content !important; border: none !important; background-color: white !important; font-weight: 600;font-family: poppins,sans-serif;opacity: 0.7; " (click)="closeModal()">Cancel</button>
                <button   style="border: 1px solid #FC9929; background-color: #FC9929; margin-left: 8px; padding: 2px 15px 2px 15px; color: white; font-weight: 600;font-family: poppins,sans-serif;" (click)="saveToFavorite()">Add</button>
                </div>
            </div>
        </div>  


     <!-- <div class="modal fade" id="exampleModalCenter"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle" style="margin-top: 5px !important; font-size: 18px !important; font-weight: 600 !important; font-family: poppins,sans-serif !important;">Saving to Favorites</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label style="color: black !important;; font-size: 12px !important; font-weight: 600 !important; font-family: poppins, sans-serif !important;">Favorite order name</label>
                        <input type="text" placeholder="Enter Favorite order name" style="height: 23px !important; border: 1px solid #CED0DA; padding-left: 5px !important;">
                    </div>
                </form>
            </div>
            <div class="modal-footer" style="margin-bottom: 5px !important;">
              <button type="button" data-dismiss="modal" class="btn btn-secondary closeButton" data-dismiss="modal" style="text-transform:none !important; color: black !important; margin-right: 4px;">Cancel</button>
              <button type="button" data-dismiss="modal" data-toggle="modal" href="#myModal2" class="btn btn-primary saveButton"   >Add</button>
            </div>
          </div>
        </div>
      </div>  -->

        <!-- <div class="modal" id="myModal2" data-backdrop="static" style="height: 220px; width: 300px;">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header mt-3">
              <img class="d-block m-auto" src="assets/img/hand.svg"  width="15%">
            </div>
            <div class="container"></div>
            <div class="modal-body">
             <h5 class="text-center">
                  Confirm you want to save it to your
                            favorites?
                        </h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary closeButton" data-dismiss="modal" >Cancel</button>
                <button type="button" class="btn btn-primary saveButton">Yes</button>
            </div>
          </div>
        </div>
</div>   -->
    
<!-- <ng-template #orderId let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <label for="dateOfBirth">Date of birth</label>
          <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div>
  </ng-template> -->
    



  
<!-- 
  <div class="container" [ngClass]="displayLoader ? 'showClass' : 'hideClass'">
    <div class="overlay-box" style="color: #000; position: fixed; width: 100%; background: #fff; height: 100%; top: 0; left: 0; bottom: 0; right: 0; z-index: 999999; opacity: 0.8;">
        <div class="payment_status_popup" style="width: 460px;margin: 0 auto;text-align: center;position: absolute; left: 0;right: 0;top: 30%;">
            <img src="./../../../../assets/img/quotes/loader.svg" alt="">
            <h2 class="mt-3" style=" font-size: 20px !important; color: #151522; margin: 0; padding: 0; font-weight: 600 !important; text-align: center;">Loading your options, please wait… </h2>
        </div>

    </div>
</div>
   -->
