<div class="container" style="top: 30px;position: absolute;padding: 20px;">
    <span>
         <h4 style="font-size: 16px !important;">Zip/Postal Codes
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()">
                    close
                    </span>
                </span>
             </button>
         </h4>
        
    </span>
    <div class="row" *ngIf = "!addressObject" >
        <div>
            {{addressStatus}}
        </div>
        <form>
            <div class="form-group col-12">
                <label>Zip/Postal Code *</label>
                <input class="form-control" name="zipcode" #zipcode >
                <div class="red_alert_msg">{{submitted && (zipcode =='' || invalidCode ) ? 'Please Provide valid Zipcode' : ''}}</div>
            </div>
            <div class="text-right mt-5">
                <button type="reset" class="btn faint-grey col-4" style="box-shadow: none;text-transform: capitalize;" >Reset</button>
                &nbsp;&nbsp; <button type="submit" class="btn orange text-white col-5" style="box-shadow: none;text-transform: capitalize;" (click)="getAddresses(zipcode.value)" >Lets Find Out</button>
            </div>
        </form>
    </div>
    <div class="row" *ngIf = "addressObject" >
        
        <div class="">
            <div class="row mb-2">
                <div class="col grey-text text-right">Zip Code:</div>
                <div class="col ">{{zipcode}}</div>
            </div>
            <div class="row mb-2">
                <div class="col grey-text text-right">City:</div>
                <div class="col">{{addressObject && addressObject['data']?.city }}</div>
            </div>
            <div class="row mb-2">
                <div class="col grey-text text-right">State/Province:</div>
                <div class="col">{{addressObject && addressObject['data']?.state }}</div>
            </div>
            <div class="row mb-2">
                <div class="col grey-text text-right">Country:</div>
                <div class="col">{{addressObject && addressObject['data']?.country }}</div>
            </div>
            <div class="text-right mt-4">
                <!-- <button type="button" class="btn orange-border col-4" style="box-shadow: none;text-transform: capitalize;" >Get Distance</button> -->
                &nbsp;&nbsp; <button type="button" (click)="closeModal()" class="btn orange text-white col-5" style="box-shadow: none;text-transform: capitalize;" >Done</button>
            </div>
        </div>
    </div>
</div>
