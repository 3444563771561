import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../services/common.service';
@Component({
  selector: 'app-zip-postal-codes',
  templateUrl: './zip-postal-codes.component.html',
  styleUrls: ['./zip-postal-codes.component.css']
})
export class ZipPostalCodesComponent implements OnInit {
  addressObject:any;
  addressStatus:any;
  zipcode:any;
  submitted:boolean;
  invalidCode: boolean;
  constructor(public activeModal: NgbActiveModal,public commonService:CommonService) { }

  ngOnInit(): void {
    this.invalidCode = false;

  }

  closeModal() { 
    this.activeModal.close(); 
  }
  
  getAddresses(zipcode){
    this.submitted = true;
    if(!zipcode){
      this.invalidCode = true;
      return;
    }

    this.zipcode = zipcode;
    this.invalidCode = false;
    this.commonService.getAddressByZip(zipcode).subscribe(result =>{     
      this.addressObject = result;
      this.addressStatus = false;
      this.submitted = false;
    }, (error) => {
      this.submitted = true; //for show error
      this.zipcode = '';
      this.invalidCode = true;
    });

  }
}
