import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getQuoteService } from 'src/app/services/get-quote/get-quote.service';
@Component({
  selector: 'app-freight-class-calculator',
  templateUrl: './freight-class-calculator.component.html',
  styleUrls: ['./freight-class-calculator.component.css']
})
export class FreightClassCalculatorComponent implements OnInit {
  frightClassForm: FormGroup;
  volume: any;
  density: any;
  frightClass: any;
  res: any;
  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private getQuoteServic: getQuoteService) { }

  ngOnInit(): void {
    this.frightClassForm = this.formBuilder.group({
      "Length": new FormControl('',Validators.min(1)),
      "Width": new FormControl('',Validators.min(1)),
      "Height": new FormControl('',Validators.min(1)),
      "Weight": new FormControl('',Validators.min(1)),

    });
    this.getQuoteServic.getFreightClass().subscribe((res) => {
      this.res = res;
    });
  }
  closeModal() { this.activeModal.close(); }
  reset() {
    this.frightClassForm.reset();
    this.volume = false;
    this.density = false;
    this.frightClass = false;
  }
  getDensity() {
    let height;
    let width;
    let length;
    height = parseFloat(this.frightClassForm.controls['Height'].value).toFixed(1);
    width = parseFloat(this.frightClassForm.controls['Width'].value).toFixed(1);
    length = parseFloat(this.frightClassForm.controls['Length'].value).toFixed(1);

    if (!height && !width && !length) {
      return;
    }

    // if (this.addItemForm.value.lengthType) { //cm to inch
    //   var volume = (this.height * 0.393701) * (this.width * 0.393701) * (this.length * 0.393701);
    // } else {
    var volume = height * width * length;
    //}

    this.volume = volume * 1;

    this.density = this.frightClassForm.controls['Weight'].value / (volume / 1728);

    this.freightClassCal(this.density);

  }
  freightClassCal(density) {

    if (density >= 50) {
      this.frightClass = this.res['data'][1]['freightType'];
      return;
    }
    if (density >= 35 && density < 50) {
      this.frightClass = this.res['data'][2]['freightType'];
      return;
    }
    if (density >= 30 && density < 35) {
      this.frightClass = this.res['data'][3]['freightType'];
      return;
    }
    if (density >= 22.5 && density < 30) {
      this.frightClass = this.res['data'][4]['freightType'];
      return;
    }
    if (density >= 15 && density < 22.5) {
      this.frightClass = this.res['data'][5]['freightType'];
      return;
    }
    if (density >= 13.5 && density < 15) {
      this.frightClass = this.res['data'][6]['freightType'];
      return;
    }
    if (density >= 12 && density < 13.5) {
      this.frightClass = this.res['data'][7]['freightType'];
      return;
    }
    if (density >= 10.5 && density < 12) {
      this.frightClass = this.res['data'][8]['freightType'];
      return;
    }
    if (density >= 9 && density < 10.5) {
      this.frightClass = this.res['data'][9]['freightType'];
      return;
    }
    if (density >= 8 && density < 9) {
      this.frightClass = this.res['data'][10]['freightType'];
      return;
    }
    if (density >= 7 && density < 8) {
      this.frightClass = this.res['data'][11]['freightType'];
      return;
    }
    if (density >= 6 && density < 7) {
      this.frightClass = this.res['data'][12]['freightType'];
      return;
    }
    if (density >= 5 && density < 6) {
      this.frightClass = this.res['data'][13]['freightType'];
      return;
    }
    if (density >= 4 && density < 5) {
      this.frightClass = this.res['data'][14]['freightType'];
      return;
    };
    if (density >= 3 && density < 4) {
      this.frightClass = this.res['data'][15]['freightType'];
      return;
    }
    if (density >= 2 && density < 3) {
      this.frightClass = this.res['data'][16]['freightType'];
      return;
    }
    if (density >= 1 && density < 2) {
      this.frightClass = this.res['data'][17]['freightType'];
      return;
    }
    if (density < 1) {
      this.frightClass = this.res['data'][18]['freightType'];
      return;
    }
    parseFloat(this.density).toFixed(2);
  }
}
